import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dot } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';
import { apiEndPointt } from '../API/ApiPaths';

const Gifthistory = () => {
  const navigate = useNavigate();
  const token = getWithExpiry("myuser");
  const [withdrawalrecord, setwithdrawalrecord] = useState([]);
  const [pageloader, setpageloader] = useState(false);

  const withdrawrecord = async () => {
    setpageloader(true);
    try {
      let data = await axios.post(apiEndPointt.GIFT_HISTORY, { token }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setwithdrawalrecord(data.data.userdata || []);
    } catch (error) {
      console.error('Error fetching gift history:', error);
      setwithdrawalrecord([]);
    } finally {
      setpageloader(false);
    }
  };

  useEffect(() => {
    withdrawrecord();
  }, []);

  return (
    <div>
      {pageloader && <LoadingSpinner />}
      {withdrawalrecord.length === 0 ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
        </div>
      ) : (
        withdrawalrecord.map((item, index) => (
          <div className='rechargecards' key={index}>
            <div>
              <strong>{item.money}({item.curr})</strong>
              <p className='rechargecardtimer'>{item.time}</p>
            </div>
            <div className='d-flex align-items-center'>
              <p style={{ fontSize: "15px", color: "#32b277" }}>
                <Dot />Successful
              </p>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Gifthistory;
