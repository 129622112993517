import React, { useEffect, useState } from 'react';
import axios from 'axios';
import LoadingSpinner from './Loader/LoadingSpinner';
import Carouselbanner from './Carouselbanner';
import Carousel from 'react-bootstrap/Carousel';
import { color } from 'chart.js/helpers';


const Home = () => {
    const [cryptoData, setCryptoData] = useState([]);

    const [pageloader, setpageloader] = useState(false);


    useEffect(() => {
        const fetchCryptoData = async () => {
            // setpageloader(true)
            const symbols = ["BTCUSDT", "SOLUSDT", "DOGEUSDT", "DOTUSDT", "LTCUSDT", "ATOMUSDT","TONUSDT","ADAUSDT","AVAXUSDT","SHIBUSDT","UNIUSDT","DAIUSDT","PEPEUSDT"];
            try {
                const promises = symbols.map(async symbol => {
                    const response = await axios.post(
                        'https://tronapi.tronvid.site/chart/cryptoprice.php',
                        { symbol: symbol },
                        { headers: { 'Content-Type': 'application/json' } }
                    );
                    return {
                        symbol: symbol.replace("USDT", ""),
                        price:parseFloat(response.data.price).toFixed(2)
                    };
                });
                const data = await Promise.all(promises);
                setCryptoData(data);
                setpageloader(false)
            } catch (error) {
                console.error('Error fetching cryptocurrency data:', error);
            }
        };

        fetchCryptoData();
    }, []);

    return (
        <div className='pb-3' style={{background:"#f0ffff"}}>
            {pageloader && <LoadingSpinner />}
            <Carouselbanner />




            <Carousel className='newcarousel ' fade style={{ margin: "0px auto", borderRadius: "10px", overflow: "hidden", height: "100px", width: "100%", background: "#f0ffff" }}>
                <Carousel.Item>
                    <div className='d-flex justify-content-between text-center'>
                        <div className='pt-2' style={{ width: "33%" }}>
                            <p className='liveprice_updated_head'>{cryptoData[0]?.symbol} <span>0.70%</span></p>
                            <p className='liveprice_updated_head_price mb-0'>{cryptoData[0]?.price || 'Loading...'}</p>
                            <p className='liveprice_updated_head_dollar'>$ 66,553.9</p>
                        </div>
                        <div className='pt-2' style={{ width: "33%" }}>
                            <p className='liveprice_updated_head'>{cryptoData[1]?.symbol}</p>
                            <p className='liveprice_updated_head_price mb-0'>{cryptoData[1]?.price || 'Loading...'}</p>
                            <p className='liveprice_updated_head_dollar'>rs 5555</p>
                        </div>
                        <div className='pt-2' style={{ width: "33%" }}>
                            <p className='liveprice_updated_head'>{cryptoData[2]?.symbol}</p>
                            <p className='liveprice_updated_head_price mb-0'>{cryptoData[2]?.price || 'Loading...'}</p>
                            <p className='liveprice_updated_head_dollar'>rs 5555</p>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div className='d-flex justify-content-between text-center'>
                        <div className='pt-2' style={{ width: "33%" }}>
                            <p className='liveprice_updated_head'>{cryptoData[3]?.symbol} <span>0.70%</span></p>
                            <p className='liveprice_updated_head_price mb-0'>{cryptoData[3]?.price || 'Loading...'}.30</p>
                            <p className='liveprice_updated_head_dollar'>$ 66,553.95</p>
                        </div>
                        <div className='pt-2' style={{ width: "33%" }}>
                            <p className='liveprice_updated_head'>{cryptoData[4]?.symbol}</p>
                            <p className='liveprice_updated_head_price mb-0'>{cryptoData[4]?.price || 'Loading...'}</p>
                            <p className='liveprice_updated_head_dollar'>rs 5555</p>
                        </div>
                        <div className='pt-2' style={{ width: "33%" }}>
                            <p className='liveprice_updated_head'>{cryptoData[5]?.symbol}</p>
                            <p className='liveprice_updated_head_price mb-0'>{cryptoData[5]?.price || 'Loading...'}</p>
                            <p className='liveprice_updated_head_dollar'>rs 5555</p>
                        </div>
                    </div>
                </Carousel.Item>

            </Carousel>

            <div className='hometableicons container-fluid my-3'>
                <div className="row">
                    <div className="col-6 header">Name</div>
                    <div className="col-6 text-end header">Latest price (USDT)</div>
                    <hr className='mt-2 mb-3' />
                </div>
                {cryptoData.map((crypto, index) => (
                    <div className="row" key={index}>
                        <div className="col-6 currencyname">
                            <div style={styles.currencyItem}>
                                <img style={styles.img} src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/${crypto.symbol.toLowerCase()}.png`} alt={crypto.symbol} />
                                {crypto.symbol}
                            </div>
                        </div>
                        <div className="col-6 text-end currencyvalue">{crypto.price || 'Loading...'} <span style={styles.table_value_percent}>+10.5%</span></div>

                        <hr className='my-3' />
                    </div>
                ))}
            </div>



        </div>
    );
};

const styles = {
    currencyItem: {
        display: 'flex',
        flexDirection: 'row',
        width: '50px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    img: {
        marginRight: '10px',  // Changed to camelCase and added quotes around value
    },
    table_value_percent:
    {
        background: " #68e384",
        padding: "5px 10px",
        borderRadius: "3px",
        fontSize: "12px",
        color: "white"
    }
};

export default Home;
