import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ArrowClockwise, CashStack, Coin, CurrencyExchange, Wallet2, X } from 'react-bootstrap-icons';
import LoadingSpinner from './Loader/LoadingSpinner';
import { getWithExpiry } from './JS/JS';

const User = () => {

    const [loading, setloading] = useState(true)

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const navigate = useNavigate();
    const [usdt, setusdt] = useState()
    const [trx, settrx] = useState()
    const [email, setemail] = useState()
    const [id, setid] = useState()

    const [password, setpassword] = useState()
    const [newpassword, setnewpassword] = useState()
    const [confpassword, setconfpassword] = useState()

    const token = getWithExpiry("myuser")


    const logout = () => {
        const response = localStorage.clear("myuser")
        navigate("/navigate");
    }


    function maskEmail(email) {
        var atIndex = email.indexOf("@");
        if (atIndex <= 2) return email; // If the name part is too short to mask

        var maskedEmail = email[0] + email[1]; // Take the first two characters of the email
        maskedEmail += "**"; // Add the masking characters
        maskedEmail += email.slice(atIndex - 1); // Add the last character before "@" and the rest of the email

        return maskedEmail;
    }

    const checkuser = async () => {

        let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {

            navigate("/login")
        }
        else {
            setusdt(Number(data.data.userdata.usdt))
            settrx(Number(data.data.userdata.trx))

            setemail(maskEmail(data.data.userdata.email))
            setid(data.data.userdata.id)
            // setvisible(true)

        }
        setloading(false)
    }

    function formatToFourDecimalPlaces(number) {
        return number.toFixed(4);
    }


    useEffect(() => {
        checkuser()
    }, [])

    return (
        <div className='profile ' style={{background:"#f0ffff",}}>
            {loading && <LoadingSpinner />}

            <div style={{ height: "140px",background:"linear-gradient(134deg, rgb(27, 67, 76), rgb(240, 255, 255))" }} className=' d-flex align-items-center'>
                <div className="d-flex px-sm-5 px-3">

                    <img style={{ height: "70px", width: "70px" }} src="/logo/3.png" alt="" />
                    <div className='banner ms-3 my-auto'>
                        <p className='m-0' >UID:{id}</p>
                        <p className='m-0'>Email:{email}</p>
                    </div>
                </div>
            </div>
            <div className="container-fluid" style={{ background:"#f0ffff",position: "relative" }}>
                <div className="row   mx-auto  py-3" style={{background:"#a7c4ca", borderRadius: "10px", maxWidth: "90%", position: "relative", top: "-30px", zIndex: "2",backgroundPosition:"top",backgroundRepeat:"no-repeat",backgroundSize:"cover" }}>

                    <div className="col-6   " >
                        <div className="d-flex d-flex align-items-center  justify-content-center">

                            <img src="./icon_mine_TRX.png" style={{ height: "30px", width: "30px" }} alt="" />
                            <p className='m-0 d-flex justify-content-center ms-2 currencyname'><span >TRX</span></p>
                        </div>
                        <div className='text-center'>
                            <p className='m-0 currencyname'>{trx && formatToFourDecimalPlaces(trx)}</p>
                        </div>
                    </div>
                    <div className="col-6  border-end " >
                        <div className="d-flex align-items-center  justify-content-center">

                            <img src="https://cryptologos.cc/logos/tether-usdt-logo.png" style={{ height: "30px", width: "30px" }} alt="" />
                            <p className='m-0 d-flex justify-content-center ms-2 currencyname '><span>USDT</span></p>
                        </div>
                        <div className='text-center'>
                            <p className='m-0 currencyname'>{usdt && formatToFourDecimalPlaces(usdt)}</p>
                        </div>
                    </div>
                </div>

                <div className="row   text-center mx-auto  py-3 pt-4" style={{background:"#a7c4ca", borderRadius: "10px", fontSize: "11px", maxWidth: "90%", position: "relative", top: "-40px", zIndex: "1",backgroundPosition:"bottom",backgroundRepeat:"no-repeat",backgroundSize:"cover" }}>

                    <div className="col-3   " >
                        <div className="" onClick={() => navigate("/depositpage")} >
                            <img style={{ height: "30px", width: "30px"}} src='/assets/newicons/icons8-deposit-100.png'/>
                            <p className='m-0  '><span >Deposit</span></p>
                        </div>
                    </div>
                    <div className="col-3   " >
                        <div className="" onClick={() => navigate("/withdraw")}>
                        <img style={{ height: "30px", width: "30px"}} src='/assets/newicons/icons8-withdraw-100.png'/>
                            <p className='m-0  '><span >Withdraw</span></p>
                        </div>
                    </div>
                    <div className="col-3   " >
                        <div className="" onClick={() => navigate("/currencyexchange")}>
                            <img style={{ height: "30px", width: "30px"}} src='/assets/newicons/icons8-currency-exchange-100.png'/>
                            <p className='m-0  '><span >Exchange</span></p>
                        </div>
                    </div>
                    <div className="col-3   " >
                        <div className=""  >
                            <img style={{ height: "30px", width: "30px"}} src='/assets/newicons/icons8-vip-100.png'/>
                            <p className='m-0  '><span >VIP</span></p>
                        </div>
                    </div>

                </div>
            </div>




            <div className="d-flex justify-content-around text-center py-3" style={{ background:"#f0ffff",fontSize: "12px" }}>
                <div style={{ width: "20%" }} onClick={() => navigate("/team")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-business-conference-female-speaker-100.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Team</p>
                </div>
                <div style={{ width: "20%" }} onClick={() => navigate("/setup")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-admin-settings-male-100.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Set Up</p>
                </div>
                <div style={{ width: "20%" }} onClick={() => navigate("/rebate")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-giftcard-68.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Rebate</p>
                </div>


                <div style={{ width: "20%" }}>
                    <div onClick={() => navigate("/promotion")} className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-share-100.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Share</p>
                </div>

            </div>
            {/* <div className='mx-3' style={{ heigth: "100px", borderRadius: "10px" }} onClick={() => navigate("/mining")}>
                <img style={{ heigth: "100%", width: "100%", borderRadius: "10px" }} src="https://wintrx.net/assets/c-CBhJmLqg.jpg"></img>
            </div> */}
            <div className="d-flex justify-content-around text-center  py-3 mt-3" style={{background:"#f0ffff", fontSize: "12px" }}>
                <div style={{ width: "20%" }} onClick={() => navigate("/redemtionexchange")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-gift-card-100.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Gift Code</p>
                </div>
                <div style={{ width: "20%" }} onClick={() => navigate("/mining")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-bitcoin-minning-64.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Mining</p>
                </div>
                <div style={{ width: "20%" }} onClick={() => window.location.href = "https://t.me/bbcusdt"}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-telegram-64.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Join</p>
                </div>
                <div style={{ width: "20%" }} onClick={() => window.location.href = "https://t.me/Bbc_trading_supports"}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-customer-support-40.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Support</p>
                </div>

            </div>
            <div className="d-flex justify-content-around text-center py-3 " style={{background:"#f0ffff", fontSize: "12px" }}>
                <div style={{ width: "20%" }} onClick={() => navigate("/user/alltransaction")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-activity-history-100.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>All Transaction</p>
                </div>
                <div style={{ width: "20%" }} onClick={() => navigate("/gifthistory")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-gift-100.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Gift history</p>
                </div>
                <div style={{ width: "20%" }} onClick={() => navigate("/depositrecord")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-payment-history-48.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Deposit History</p>
                </div>
                <div style={{ width: "20%" }} onClick={() => navigate("/withdrawrecord")}>
                    <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
                        <img src="/assets/newicons/icons8-cash-withdrawal-48.png" style={{ height: "30px", width: "30px" }} alt="" />
                    </div>
                    <p className='mb-0'>Withdrawal History</p>
                </div>

            </div>



            <div className='container-fluid my-2  pb-3 profiletable py-3' style={{background:"#f0ffff",}}>
                {/* 
                <div className="row" style={{padding: "8px 0px"}} onClick={() => navigate("/user/alltransaction")}>
                    <div className="col-10 header d-flex align-items-center">

                        <img className='mx-2' src="https://img.icons8.com/color/48/transaction.png" style={{ height: "25px", width: "25px" }} alt="" /> All Transaction</div>
                    <div className="col-2  text-end  header">
                        <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                </div>
                
                
                 <div className="row" style={{padding: "8px 0px"}} onClick={() => navigate("/gifthistory")}>
                    <div className="col-10 header d-flex align-items-center">

                        <img className='mx-2' src="https://img.icons8.com/external-vectorslab-flat-vectorslab/53/external-Gift-List-shopping-and-commerce-vectorslab-flat-vectorslab.png" style={{ height: "25px", width: "25px" }} alt="" /> Gift History</div>
                    <div className="col-2  text-end  header">
                        <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                </div>
                
                <div className="row" style={{padding: "8px 0px"}} onClick={() => navigate("/depositrecord")}>
                    <div className="col-10 header d-flex align-items-center">

                        <img className='mx-2' src="https://img.icons8.com/fluency/48/000000/buy-with-card.png" style={{ height: "25px", width: "25px" }} alt="" /> Deposit History</div>
                    <div className="col-2  text-end  header">
                        <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                </div>
                
                
                <div className="row" style={{padding: "8px 0px"}} onClick={() => navigate("/withdrawrecord")}>
                    <div className="col-10 header d-flex align-items-center">

                        <img className='mx-2' src="https://img.icons8.com/external-flaticons-lineal-color-flat-icons/64/external-transaction-history-online-money-service-flaticons-lineal-color-flat-icons.png" style={{ height: "25px", width: "25px" }} alt="" />  Withdrawal History</div>
                    <div className="col-2  text-end  header">
                        <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                </div> */}










                <div className="row" style={{ padding: "8px 0px" }} onClick={logout}>
                    <div className="col-10 header d-flex align-items-center">

                        <img className='mx-2' src="/assets/newicons/icons8-logout-100.png" style={{ height: "25px", width: "25px" }} alt="" /> Logout</div>
                    <div className="col-2  text-end  header">
                        {/* <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /> */}
                    </div>
                </div>
            </div>


            <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <div className='d-flex'>
                        <p className='mb-0'>Update Password </p>
                        <X className='ms-auto' onClick={handleClose} />
                    </div>
                    <form onSubmit={() => alert("======")}>
                        <div className="row" >
                            <div className="form-outline my-2">
                                <label className='mb-2' htmlFor="password">Current Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    placeholder='Enter New password'
                                    value={password}
                                    onChange={(e) => setpassword(e.target.value)}
                                />
                            </div>
                            <div className="form-outline my-2">
                                <label className='mb-2' htmlFor="password">New Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    className="form-control"
                                    placeholder='Enter New password'
                                    value={password}
                                    onChange={(e) => setnewpassword(e.target.value)}
                                />
                            </div>
                            <div className="form-outline my-2">
                                <label className='mb-2' htmlFor="password">Confirm New Password</label>
                                <input
                                    type="password"
                                    id="Confirm New Password"
                                    className="form-control"
                                    placeholder='Confirm Password'
                                    value={password}
                                    onChange={(e) => setconfpassword(e.target.value)}
                                />
                            </div>
                            <div className='d-flex pt-0 justify-content-center'>
                                <button
                                    type="submit"
                                    className="loginbtn"
                                >
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>






        </div>
    )
}

export default User









