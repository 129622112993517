import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import { ArrowLeft, Calendar2, DistributeVertical } from 'react-bootstrap-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { getWithExpiry } from '../JS/JS'
import LoadingSpinner from '../Loader/LoadingSpinner'

const Gamerecordstatics5 = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [pageloader, setpageloader] = useState(true)

    const [nav, setnav] = useState("close")

    const [selectedValue, setSelectedValue] = useState(5);

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };
    const [result, setresult] = useState([]);


    const fetchclosingrecords = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/getstatistical', { interval: selectedValue, token: getWithExpiry("myuser"), game_type: `eth${selectedValue}` }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (data?.data?.message === "No records found") {
            setresult([])
        } else {
            setresult(data?.data?.btchistory)
        }
        setpageloader(false)

    }


    useEffect(() => {
        fetchclosingrecords();
    }, [selectedValue])


    const handleSelect = (eventKey) => {
        let selectedText;
        switch (eventKey) {
            case '1':
                selectedText = 1;
                break;
            case '2':
                selectedText = 5;
                break;
            case '3':
                selectedText = 15;
                break;
            default:
                selectedText = 'Select an option';
        }
        sessionStorage.setItem('selectedintervel', selectedText);

        // setSelectedValue(selectedText);
        fetchselectedinterval();

    };

    function sumField(data, field) {
        return data.reduce((acc, obj) => acc + (parseFloat(obj[field]) || 0), 0);
    }

    const fetchselectedinterval = () => {
        const value = sessionStorage.getItem('selectedintervel');
        // setSelectedValue(value);
    }

    useEffect(() => {
        fetchselectedinterval()
    }, [])

    return (
        <div>
            {pageloader && <LoadingSpinner />}


            <div className="shadow-sm position-sticky sticky-top" style={{ backgroundColor: "white" }}>
                <div className='d-flex justify-content-between align-items-center  p-2'>
                    <div className="" style={{ width: "20%" }}>{(location.pathname === "/index") ? <img style={{ height: "30px", width: "30px" }} src="https://genusdt.net/static/img/logo.png" alt="" /> : <ArrowLeft onClick={() => navigate(`/eth${selectedValue}`)} />}</div>
                    {/* <div className=""></div> */}

                    <div className="text-center d-flex align-items-center justify-content-center" style={{ width: "60%" }}>

                        <span>Record</span>
                    </div>

                    <div className=" d-flex justify-content-end" style={{ width: "20%" }}>
                        {/* {<DropdownButton
                            className='ms-auto navbutton'
                            as={ButtonGroup}
                            key={"info"}
                            id={`dropdown`}
                            onSelect={handleSelect}
                            title={selectedValue + " min"}
                        >
                            <Dropdown.Item eventKey="1" >1 min</Dropdown.Item>
                            <Dropdown.Item eventKey="2">5 min</Dropdown.Item>
                            <Dropdown.Item eventKey="3">15 min</Dropdown.Item>
                        </DropdownButton>} */}


                        {/* {(location.pathname === "/withdraw") && <span onClick={() => navigate("/withdrawrecord")}><Calendar2Event className='mx-2' /></span>} */}
                        {/* {(location.pathname == "/depositpage" || location.pathname == "/deposittrx" || location.pathname == "/depositusdt") && <span onClick={() => navigate("/depositrecord")}><Calendar2Event className='mx-2' /></span>} */}
                    </div>
                </div>
            </div>





            <div className="d-flex justify-content-around gamerecordnav py-2 bg-light">
                <p className={`mb-0 px-3 `} onClick={() => navigate("/gamerecordclosings115")}>Closing</p>
                <p className={`mb-0 px-3 `} onClick={() => navigate("/gamerecordtransations115")}>Trasaction</p>
                <p className={`mb-0 px-3 active text-white`} onClick={() => navigate("/gamerecordstatics115")}>Statical</p>
            </div>

            <div style={{ borderTop: "1px solid white" }}>
                <div className='d-flex bg-light text-center' style={{ color: "#999" }}>
                    <p className='mb-0' style={{ fontSize: "12px", lineHeight: "36px", width: "25%" }}>Date</p>
                    <p className='mb-0' style={{ fontSize: "12px", lineHeight: "36px", width: "25%" }}>Trading period</p>
                    <p className='mb-0' style={{ fontSize: "12px", lineHeight: "36px", width: "25%" }}>Amount</p>
                    <p className='mb-0' style={{ fontSize: "12px", lineHeight: "36px", width: "25%" }}>Profit-loss</p>
                </div>


                {result?.map((data, key) => <div key={key} >
                    <div className='text-center d-flex align-items-center' style={{ fontSize: "12px" }}>
                        <div style={{ width: "25%" }}>
                            <span>{data.dt}</span>
                        </div>
                        <div style={{ width: "25%" }}>
                            <p className='my-1'>{data.betnumtrx}</p>
                            <p className='my-1'>{data.betnumusdt}</p>
                        </div>
                        <div style={{ width: "25%" }}>
                            <p className='my-1'>{data.moneytrx}</p>
                            <p className='my-1'>{data.moneyusdt}</p>
                        </div>
                        <div style={{ width: "25%" }}>
                            <p className='my-1 text-danger' >{data.incometrx}</p>
                            <p className='my-1'>{data.incomeusdt}</p>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>)}
            </div>



            <div className="d-flex align-items-center py-2 bg-light" style={{ borderRadius: "10px 10px 0px 0px", position: "fixed", bottom: '0px', width: "100%" }}>
                <div className='d-flex bg-light text-center' style={{ color: "#999", width: "100%" }}>
                    <div className='mb-0' style={{ fontSize: "12px",  width: "33%" }}>
                        <p className='mb-0'>Trading Period</p>
                        <p className='mb-0'>{sumField(result, "betnumtrx")}</p>
                        <p className='mb-0'>{sumField(result, "betnumusdt")}</p>
                    </div>
                    <div className='mb-0' style={{ fontSize: "12px",  width: "33%" }}>
                        <p className='mb-0'>Lump Sum</p>
                        <p className='mb-0'>{sumField(result, "moneytrx")}</p>
                        <p className='mb-0'>{sumField(result, "moneyusdt")}</p>
                    </div>
                    <div className='mb-0' style={{ fontSize: "12px",  width: "33%" }}>
                        <p className='mb-0'>Profit-loss</p>
                        <p className='mb-0 text-danger'>{sumField(result, "incometrx")}</p>
                        <p className='mb-0 text-danger'>{sumField(result, "incomeusdt")}</p>
                    </div>
                </div>
            </div>




            <div className={`popup-container withdraw  ${showPopup && 'show'}`}>
                {<div className="popup-content px-0">
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px", color: "#999" }}>
                        <p>PD236876387638716</p>
                        <p >12-10-06 10:10:10</p>
                    </div>
                    <hr className='my-1' />

                    <div className='py-2 bg-light text-center' style={{ fontSize: "20px" }}> <span>5+6+10=21</span></div>

                    <div className='py-2' style={{ fontSize: "14px", lineHeight: "2" }}>

                        <p className='mb-0 px-2'>Transaction amount : 18 TRX</p>
                        <hr className='my-1 ' />
                        <p className='mb-0 px-2'>Profit and loss results : 18 TRX</p>
                        <hr className='my-1' />
                        <p className='mb-0 px-2'>Transaction option : </p>
                    </div>
                    {<div className=' mx-2 d-flex align-items-center text-center justify-content-center' style={{ color: "green", height: "30px", fontSize: "10px", width: "70px", borderRadius: "10px", borderLeft: "red 1px solid", border: "green 1px solid", borderBottom: "blue 1px solid" }}>
                        <span className='mb-0 ' style={{ lineHeight: "10px", width: "25px" }}> Big Single</span>
                    </div>}
                    <hr className='my-1' />
                    <p className='text-center mb-0' style={{ fontSize: "14px", color: "#999" }}>Cancel</p>
                </div>}

            </div>




        </div >
    )
}

export default Gamerecordstatics5