import React, { useEffect, useState } from 'react';
import Nav from '../Nav';
import Bottomnav from '../Bottomnav';
import { apiEndPointt } from '../API/ApiPaths';
import { getWithExpiry } from '../JS/JS';
import axios from 'axios';
import LoadingSpinner from '../Loader/LoadingSpinner';
import { useNavigate } from 'react-router-dom';

const Alltransaction = () => {
    const [responsedata, setresponsedata] = useState([]);
    const [pageloader, setpageloader] = useState(false);
    const [historytype, sethistorytype] = useState("all");

    const navigate=useNavigate();

    const fetchtransactions = async () => {
        try {
            setpageloader(true);
            const data = await axios.post(apiEndPointt.USER_ALLTRANSACTION, { token: getWithExpiry("myuser") }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            setresponsedata(data.data.userdata || []);
        } catch (error) {
            console.error('Error:', error);
            setresponsedata([]);
        } finally {
            setpageloader(false);
        }
    };

    useEffect(() => {
        fetchtransactions();
    }, []);

    const handleclick = (value) => {
        sethistorytype(value);
        fetchtransactions();
    };

    return (
        <div>
            {pageloader && <LoadingSpinner />}
            <Nav />

            <div className="row p-2 user_transaction_type">
                <p className={`mb-0 col-1 px-3 ${historytype === "all" && "active"}`} onClick={() => handleclick("all")}>
                    <span>All</span>
                </p>
                {/* <p className={`mb-0 col-1 px-3 ${historytype === "trade" && "active"} `} onClick={() => handleclick("trade")}><span>Trade</span></p> */}
            </div>

            <div className="row py-2 bg-light">
                <p className={`mb-0 col-4 px-3 text-start`} onClick={() => navigate("/gamerecordclosings")}>Amount</p>
                <p className={`mb-0 col-4 px-3 text-center`} onClick={() => navigate("/gamerecordtransations")}>Type</p>
                <p className={`mb-0 col-4 px-3 text-end`} onClick={() => navigate("/gamerecordstatics")}>Source</p>
            </div>

            {responsedata.length === 0 ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
                </div>
            ) : (
                responsedata.map((data, index) => (
                    <div key={index} className="d-flex py-2" style={{ fontSize: "12px" }}>
                        <div className={`mb-0 col-4 px-2 text-start`} onClick={() => navigate("/gamerecordclosings")}>
                            <p className='mb-0'>{data.amount}({data.curr})</p>
                            <p className='mb-0'>{data.transaction_date}</p>
                        </div>
                        <p className={`mb-0 col-4 px-2 text-center`} onClick={() => navigate("/gamerecordtransations")}>{data.type}</p>
                        <p className={`mb-0 col-4 px-2 text-end`} onClick={() => navigate("/gamerecordstatics")}>{data.orderid}</p>
                    </div>
                ))
            )}
            <Bottomnav />
        </div>
    );
};

export default Alltransaction;
