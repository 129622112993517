import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Copy, Dot, Link, Link45deg } from 'react-bootstrap-icons';
import { unstable_HistoryRouter } from 'react-router-dom';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Depositusdt = () => {

    const token = getWithExpiry("myuser")
    const [address, setaddress] = useState()
    const [pageloader, setpageloader] = useState(false)

    // usdt request
    const depositusdt = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/usdt', { token }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        setaddress(data.data.address)
        setpageloader(false)
    }
    
    
    
    
    const refreshbalance = async () => {
        setpageloader(true)
        
         
         
         
         setTimeout(() => {
          setpageloader(false)
          
        const unimodal = document.getElementById('unimodal');
        const unimsg = document.getElementById('unimsg');
        
        unimsg.textContent ="Refresh Successfully";
        unimodal.style.display = 'block';
        
        setTimeout(() => {
          unimodal.style.display = 'none';
        }, 1500);
        
        }, 1500);
        
       
        
        
       
        
        let data = await axios.post('https://tronapi.tronvid.site/payment/webhook', {  }, {
            headers: {
                'Content-Type': 'application/json',
            },
             
        });
        
       
    }


    function copyText() {

        var referLinkElement = document.getElementById("addd");

        // Create a temporary input element
        var tempInput = document.createElement("input");

        // Set the value of the temporary input to the text content of the referLinkElement

        tempInput.setAttribute("value", referLinkElement.textContent);

        // Append the temporary input element to the body
        document.body.appendChild(tempInput);

        // Select the text within the temporary input element

        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the temporary input element
        document.body.removeChild(tempInput);

        // Optionally, provide feedback to the user
        alert("Address copied to clipboard: " + referLinkElement.textContent);

    }
    useEffect(() => {
        depositusdt()
    }, [])


    return (
        <>
            {<div>
                {pageloader && <LoadingSpinner />}
                <div className="depositbanner " style={{ height: "200px",background:"linear-gradient(134deg, #1b434c, #f0ffff)" }}>
                </div>
                <div className="depositcard text-center mx-4" style={{ padding: "8px 21px", marginTop: "-150px", borderRadius: "10px" }}>
                    <p className='text-start my-2' style={{ fontSize: "11px", color: "#333" }}>Deposit Network</p>

                    <p className='btnss py-1'>USDT/TRC20</p>
                    <p style={{ fontSize: "11px", color: "#333" }}>Deposit Address</p>

                    <img id='qrcode' src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${address}`} alt="" />


                    <p className='my-3' style={{ fontSize: "11px", color: "#999" }}>This Address can only deposit USDT</p>
                    <hr />
                    <div className="d-flex text-center justify-content-between" style={{ fontSize: "10px" }}>
                        <Link45deg /><span id='addd'>{address}</span><Copy onClick={copyText} />
                    </div>
                </div>

                <div className="d-flex mx-4 justify-content-between py-3" style={{ fontSize: "12px" }}>
                    <p style={{ color: "#aaa" }}>Didn't receive then top up?</p>
                    <p style={{ color: "#74dbab", textDecoration: "underline" }}
                    onClick={refreshbalance}>Click to refresh balance</p>
                </div>


                <div className='p-2 row mx-4' style={{ backgroundColor: "white", borderRadius: "10px" }}>
                    <p className='col-6' style={{ fontSize: "11px" }}>Min. Deposit Amount</p><span style={{ fontSize: "10px", color: "#999" }} className='col-6'>10 USDT</span>
                    <p className='col-6' style={{ fontSize: "11px" }}>Expected Arrival</p><span style={{ fontSize: "10px", color: "#999" }} className='col-6'>1 Network Confirmation</span>
                    <p className='col-6' style={{ fontSize: "11px" }}>Expected Unlock</p><span style={{ fontSize: "10px", color: "#999" }} className='col-6'>1 Network Confirmation</span>

                </div>

                <div className='p-2 row mx-4' style={{ fontSize: "12px", lineHeight: "17px", color: "#000" }}>
                    <p>Please do not deposit any non-TRC20-USDT assets to the above address, otherwise the assets will not be retrieved.</p>
                    <p>After you recharge to the above address, you need to confirm the entire network node, the account will be received after 1 network confirmation, and the coin can be withdrawn after 1 network confirmation.</p>
                    <p>Minimum recharge amount: 1 USDT , recharge less than the minimum amount will not be credited to the account and will be returned.</p>
                    <p>Your recharge address will not change frequently, and you can recharge; if there is any change, we will try our best to notify you through website announcements or emails.</p>
                    <p>Please make sure that your computer and browser are safe to prevent information from being tampered with or leaked.</p>
                </div>
            </div>}
        </>
    )
}

export default Depositusdt