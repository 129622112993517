import React, { useEffect, useRef, useState } from 'react';
import "./vip.css";
import axios from 'axios';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
// import "./css/List_a4a597aa.css";
// import "./css/index-d1f65759.css";
// import "./css/index-39611422.css";
// import "./css/index-99a7cc56.css";
// import "./css/index-d50f137c.css";
const Vip = () => {

    const navigate = useNavigate();

    // slider with changing effect 
    const [isDragging, setisDragging] = useState(false);
    const [startX, setstartX] = useState();
    const [scrollLeft, setscrollLeft] = useState();
    const containerRef = useRef(null)

    const boxesRef = useRef([]);

    if (containerRef.current) {
        const offsetLeft = containerRef.current.offsetLeft;
        console.log('Offset Left:', offsetLeft);
    }
    const handlemousedown = (e) => {
        setisDragging(true)
        setstartX(e.pageX - containerRef.current.offsetLeft)
        setscrollLeft(containerRef.current.scrollLeft)
        // startX = e.pageX - container.offsetLeft;
        // scrollLeft = container.scrollLeft;
    }
    const handlemouseup = () => {
        setisDragging(false)
    }
    const handlemousemove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - containerRef.current.offsetLeft;
        const walk = (x - startX) * 3;
        // container.scrollLeft = scrollLeft - walk;

        containerRef.current.scrollLeft = containerRef.current.scrollLeft - walk;

    }

    useEffect(() => {
        // Access all the boxes through boxesRef.current
        boxesRef.current.forEach((box, index) => {
            if (box) {
                // console.log(`Box ${index + 1}`, box);
                // You can manipulate each box here, e.g., adding a style or event listener.
                box.style.backgroundColor = 'lightblue';
            }
        });


        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const contentId = entry.target.getAttribute('data-content');
                    const content = document.getElementById(contentId);
                    if (content) {
                        document.querySelectorAll('.slidercontent').forEach(el => el.classList.add('hidden'));
                        content.classList.remove('hidden');
                    }
                }
            });
        };


        const observer = new IntersectionObserver(handleIntersection, {
            threshold: 0.5, // Adjust the threshold as needed
        });

        // Iterate over each box and set the data attribute and observe it
        boxesRef.current.forEach((box, index) => {
            if (box) {
                box.setAttribute('data-content', `box${index + 1}`);
                observer.observe(box);
            }
        });

        // Cleanup: disconnect the observer when the component unmounts
        return () => {
            observer.disconnect();
        };



    }, []);

    //   slider with changing effect

    const [records, setrecords] = useState([1, 2, 3, 4, 5])

    // Utility functions
    const convertTimestampToDate = (timestamp) => {
        const date = new Date(parseInt(timestamp, 10));
        return ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + '-' + date.getFullYear();
    };

    const maskPhoneNumber = (phone) => {
        return phone.length >= 10 ? phone.substring(0, 2) + '*****' + phone.substring(7) : phone;
    };

    // Fetch and display VIP data
    const loadVIPData = async () => {






        const apiUrl = 'https://test.goloot.org/api/webapi/myvips';



        try {
            const response = await fetch(apiUrl);
            const data = await response.json();

            if (data.levelup == true) {
                document.getElementById('levelupamountbtn').innerHTML = `Reveive`;

            } else {
                document.getElementById('levelupamountbtn').innerHTML = `Reveived`;
                document.getElementById('levelupamountbtn').style.backgroundColor = " #424242 important;";
            }
            if (data.monthly == true) {
                document.getElementById('monthlyamountbtn').innerHTML = `Receive`;
            } else {
                document.getElementById('monthlyamountbtn').innerHTML = `Reveived`;
                document.getElementById('monthlyamountbtn').style.background = ` #424242 !important`;
            }




            updateVIPDisplay(data);
        } catch (error) {
            console.error('Error fetching VIP data:', error);
        }
    };

    const [vipLevel, setvipLevel] = useState(1)

    const [vipLevelstatus, setvipLevelstatus] = useState(["", "", "", "", "", "", "", "", "", ""])
    const [vipLevelstatusimage, setvipLevelstatusimage] = useState(["", "", "", "", "", "", "", "", "", ""])


    const setValueAtIndex = (index, newValue) => {
        setvipLevelstatus(prevValues => {
            const updatedValues = [...prevValues]; // Make a copy of the array
            updatedValues[index] = newValue;       // Set the new value at the specified index
            return updatedValues;                  // Update the state with the modified array
        });
    };
    const setValueAtIndex2 = (index, newValue) => {
        setvipLevelstatusimage(prevValues => {
            const updatedValues = [...prevValues]; // Make a copy of the array
            updatedValues[index] = newValue;       // Set the new value at the specified index
            return updatedValues;                  // Update the state with the modified array
        });
    };



    const updateVIPDisplay = (data) => {
        // Update the VIP cards and status based on the data
        const totalBet = Math.floor(data.data.total_bet / 100);
        const vipLevels = [60, 500, 5000, 50000, 200000, 800000, 5000000, 10000000, 50000000, 100000000];

        vipLevels.forEach((level, index) => {
            const vipStatus = totalBet < level ? 'Unfinished' : 'Completed';
            const statusImage = totalBet < level ? 'vipcardunfinishedicon.png' : 'vipcardcompletedicon.png';
            const vipIndex = index + 1;
            // setvipLevelstatus([])
            setValueAtIndex(index, vipStatus)
            setValueAtIndex2(index, `/assets/png/${statusImage}`)
            // document.getElementById(`vip${vipIndex}status`).innerHTML = vipStatus;
            // document.getElementById(`vipstatusimg${vipIndex}`).src = `/assets/png/${statusImage}`;
        });

        // Update level label and rewards
        const vipLevel = vipLevels.findIndex(level => totalBet < level) + 1;

        setvipLevel(vipLevel)

        // document.getElementById('viplevellabel').innerHTML = `VIP${vipLevel}`;
        // document.getElementById('levelupamount').innerHTML = getLevelUpAmount(vipLevel);
        // document.getElementById('monthlyamount').innerHTML = getMonthlyAmount(vipLevel);
        // document.getElementById('safevalue').innerHTML = `+${getSafeValue(vipLevel)}%`;
        // document.getElementById('rebatevalue').innerHTML = `+${getRebateValue(vipLevel)}%`;
    };

    const getLevelUpAmount = (vipLevel) => {
        const amounts = [60, 200, 700, 1900, 7000, 19900, 70000, 169000, 880000, 1990000];
        return amounts[vipLevel - 1];
    };

    const getMonthlyAmount = (vipLevel) => {
        const amounts = [30, 100, 300, 900, 2000, 7000, 29600, 70000, 369000, 890000];
        return amounts[vipLevel - 1];
    };

    const getSafeValue = (vipLevel) => {
        return [0.2, 0.2, 0.25, 0.25, 0.3, 0.3, 0.35, 0.35, 0.4, 0.45][vipLevel - 1];
    };

    const getRebateValue = (vipLevel) => {
        return [0.3, 0.3, 0.35, 0.35, 0.4, 0.45, 0.475, 0.5, 0.55, 0.6][vipLevel - 1];
    };

    // Fetch and display records
    const loadRecord = async () => {
        try {
            const response = await fetch('https://test.goloot.org/api/webapi/getviprecord', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            });


            // const response = await axios.post('https://test.goloot.org/api/webapi/getviprecord', { body: "" }, {
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            // });

            if (response.data.status === "success") {
                const data = await response.json();

                setrecords(data.data.slice(0, 3))
            }
        } catch (error) {
            console.error('Error fetching records:', error);
        }
    };

    // Handle tab switching
    const setActiveTab = (tabId) => {
        const history = document.getElementById('history');
        history.click()
        console.log(history, "++++++++++++++++++++++")
        const rules = document.getElementById('rules');
        history.classList.toggle('active', tabId === 'history');
        rules.classList.toggle('active', tabId === 'rules');
    };

    // Handle click events for level up and monthly amount buttons
    const handleLevelUp = async () => {
        await postData('https://test.goloot.org/api/webapi/getlevelup');
        loadVIPData();
    };

    const handleMonthly = async () => {
        await postData('https://test.goloot.org/api/webapi/getmonthly');
        loadVIPData();
    };

    const postData = async (url) => {
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({})
            });
            const data = await response.json();
            document.getElementById('response').innerText = `Response: ${JSON.stringify(data)}`;
        } catch (error) {
            console.error('Error in POST request:', error);
        }
    };

    // Event Listeners
    //   document.getElementById('monthlyamountbtn').addEventListener('click', handleMonthly);
    //   document.getElementById('levelupamountbtn').addEventListener('click', handleLevelUp);

    useEffect(() => {
        setActiveTab('history');
        loadVIPData();
        loadRecord();
    }, [])

    return (
        <div>


            <div id="app" style={{ backgroundColor: "#79d1a85e" }} data-v-app="">

                <div className="position-sticky sticky-top" style={{ background: "linear-gradient(90deg, #31b075, #bff2da)" }}>
                    <div className='d-flex justify-content-between align-items-center  p-2'>
                        <div className="" style={{ width: "20%" }}><ArrowLeft onClick={() => navigate(-1)} />

                        </div>
                    </div>
                </div>



                <div

                    data-v-e6a0183b=""
                    className="subordinate__container "
                    style={{ width: "100%", paddingInline: 0 }}
                >

                    <div >
                        <div
                            className='vipbglg'
                        />
                        <div style={{ padding: "0px 10px", marginTop: "-70px" }}>

                            <div
                                style={{
                                    backgroundColor: "rgb(55 144 103)",
                                    height: 150,
                                    width: "90%",
                                    padding: 10,
                                    margin: "0 auto",
                                    borderRadius: "5px 5px 10px 10px"
                                }}
                            >
                                <div style={{ display: "flex" }}>
                                    <div
                                        style={{
                                            width: "30%",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <img
                                            src="/assets/png/1-a6662edb.png"
                                            style={{ borderRadius: "50%", height: 70, width: 70 }}
                                        />
                                    </div>
                                    <div
                                        style={{
                                            width: "70%",
                                            display: "flex",
                                            flexDirection: "column",
                                            justifyContent: "center"
                                        }}
                                    >
                                        {" "}
                                        <img
                                            style={{ width: 80 }}
                                            src="https://achagames.com/assets/png/2-45f9f9a0.png"
                                        />
                                        <p
                                            style={{
                                                position: "relative",
                                                color: "white",
                                                fontSize: 10,
                                                fontWeight: 600,
                                                left: "17%",
                                                top: "-33.6%"
                                            }}
                                            id="viplevellabel"
                                        >
                                            {`VIP${vipLevel}`}
                                        </p>
                                        <p style={{ fontSize: 15, fontWeight: 600, color: "white" }}>
                                            MemberNNGJIZ
                                        </p>
                                    </div>
                                </div>
                                <div style={{ display: "flex", padding: "30px 0px" }}>
                                    <div style={{ width: "50%", textAlign: "center" }}>
                                        <p
                                            style={{ fontSize: 15, fontWeight: 500, color: "white" }}
                                            className="experiencebox"
                                        />
                                        <p style={{ fontSize: 10, color: "#ffffff85" }}>My experience</p>
                                    </div>
                                    <div style={{ width: "50%", textAlign: "center" }}>
                                        <p style={{ fontSize: 12, color: "#ffffffb3" }}>
                                            <span style={{ fontSize: 15, fontWeight: 500, color: "white" }}>
                                                11
                                            </span>{" "}
                                            days
                                        </p>
                                        <p style={{ fontSize: 10, color: "#ffffff85" }}>Payout time</p>
                                    </div>
                                    <div />
                                </div>
                            </div>
                            <div className="container" id="container" ref={containerRef} onMouseDown={handlemousedown} onMouseUp={handlemouseup} onMouseMove={handlemousemove}>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[0] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner1.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP1
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg1"
                                            src={vipLevelstatusimage[0]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip1status"
                                        >
                                            {vipLevelstatus[0]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    {/* <p style="font-size:13px; color:white;" >Dear <span style="color: yellow;"> VIP1</span> customer</p> */}
                                    <p style={{ fontSize: 14, color: "white" }} id="vip1card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={100} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /60
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            100 EXP can be leveled up

                                        </p>
                                    </div>
                                </div>













                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[1] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner2.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP2
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg2"
                                            src={vipLevelstatusimage[1]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip2status"
                                        >
                                            {vipLevelstatus[1]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip2card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={200} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /500
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            200 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[2] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner3.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP3
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg3"
                                            src={vipLevelstatusimage[2]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip3status"
                                        >
                                            {vipLevelstatus[2]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip3card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={5000} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /5000
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            5000 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[3] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner4.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP4
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg4"
                                            src={vipLevelstatusimage[3]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip4status"
                                        >
                                            {vipLevelstatus[3]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip4card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={50000} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /50000
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            50000 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[4] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner5.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP5
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg5"
                                            src={vipLevelstatusimage[4]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip5status"
                                        >
                                            {vipLevelstatus[4]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip5card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={200000} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /200000
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            200000 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[5] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner6.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP6
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg6"
                                            src={vipLevelstatusimage[5]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip6status"
                                        >
                                            {vipLevelstatus[5]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip6card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={800000} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /800000
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            800000 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[6] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner7.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP7
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg7"
                                            src={vipLevelstatusimage[6]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip7status"
                                        >
                                            {vipLevelstatus[6]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip7card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={5000000} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /5000000
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            5000000 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[7] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner8.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP8
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg8"
                                            src={vipLevelstatusimage[7]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip8status"
                                        >
                                            {vipLevelstatus[7]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip8card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={10000000} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /10000000
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            10000000 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[8] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner9.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP9
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg9"
                                            src={vipLevelstatusimage[8]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip9status"
                                        >
                                            {vipLevelstatus[8]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip9card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={50000000} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /50000000
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            50000000 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                                <div
                                    className="box"
                                    ref={(el) => (boxesRef.current[9] = el)}
                                    style={{ backgroundImage: "url(/assets/png/viplevelbanner10.png)" }}
                                >
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <img style={{ width: 45 }} src="/assets/png/CROWN.png" />
                                        <p style={{ fontSize: 20, color: "#f8d79b", margin: "0 5px" }}>
                                            VIP10
                                        </p>
                                        <img
                                            style={{ width: 18, zIndex: 2 }}
                                            id="vipstatusimg10"
                                            src={vipLevelstatusimage[9]}
                                        />
                                        <span
                                            style={{
                                                padding: "2px 15px",
                                                borderRadius: 20,
                                                background:
                                                    "linear-gradient(to right, #f3e9d0, #f5c46e) !important",
                                                marginLeft: "-10px",
                                                zIndex: 1,
                                                fontSize: 10,
                                                fontWeight: 550
                                            }}
                                            id="vip10status"
                                        >
                                            {vipLevelstatus[9]}
                                        </span>
                                    </div>
                                    <p style={{ fontSize: 13, color: "white" }}>Upgrading VIP requires</p>
                                    <p style={{ fontSize: 14, color: "white" }} id="vip10card" />
                                    <p
                                        style={{
                                            border: "1px solid white",
                                            width: 130,
                                            padding: "2px 4px",
                                            margin: "10px 0px",
                                            fontSize: 14,
                                            color: "white"
                                        }}
                                    >
                                        Bet RS100=1EXP
                                    </p>
                                    <div className="progress-bar__wrapper">
                                        <progress className="progress-bar" value={40} max={100000000} />
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            padding: "0px 8px"
                                        }}
                                    >
                                        <p
                                            style={{
                                                padding: "0px 5px",
                                                background: "#eff2e8",
                                                borderRadius: 10,
                                                justifyContent: "center",
                                                display: "flex",
                                                alignItems: "center",
                                                margin: "3px 0"
                                            }}
                                        >
                                            <span className="experiencebox" />
                                            /100000000
                                        </p>
                                        <p style={{ fontSize: 12, color: "white", margin: "2px 0" }}>
                                            100000000 EXP can be leveled up
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="container" style={{ justifyContent: "center" }}>
                                <div className="slidercontent" id="box1">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP1 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    60
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    30
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.2%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.3%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box2">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP2 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    200
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    100
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.2%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.3%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box3">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP3 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    700
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    300
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.25%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.35%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box4">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP4 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    1,900
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    900
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.25%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.35%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box5">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP5 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    7,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    2,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.3%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.4%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box6">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP6 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    16,900
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    7,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.3%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.45%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box7">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP7 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    70,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    29,600
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.35%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.475%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box8">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP8 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    169,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    70,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.35%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.5%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box9">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP9 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    880,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    369,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.4%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.55%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                                <div className="slidercontent" id="box10">
                                    <section className="zoomouteffect">
                                        <p style={{ fontSize: 15, color: "white", margin: "20px 0" }}>
                                            VIP10 Benifits level
                                        </p>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon1.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Level up rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    1,990,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon2.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Monthly rewards</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Each account can onluy receive 1 time per month
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#fa2367",
                                                        fontSize: 13,
                                                        fontWeight: 600,
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        textAlign: "center",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    890,000
                                                </p>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        textAlign: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon3.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Safe</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase the extra income of the safe
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2,
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    0.45%
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                padding: "10px 10px",
                                                margin: "10px auto",
                                                borderRadius: 5,
                                                background: "rgb(83 202 147)",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center"
                                            }}
                                        >
                                            <div
                                                style={{
                                                    width: "15%",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center"
                                                }}
                                            >
                                                <img
                                                    style={{ width: "90%" }}
                                                    src="	/assets/png/vipbenifiticon4.png"
                                                />
                                            </div>
                                            <div style={{ width: "65%", paddingLeft: 10 }}>
                                                <p style={{ fontSize: 15, color: "white" }}>Rebate rate</p>
                                                <p style={{ fontSize: 12, color: "#ffffffa8", width: 200 }}>
                                                    Increase income of rebate
                                                </p>
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <p
                                                    style={{
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex",
                                                        alignItems: "center"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    0.6%
                                                </p>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            </div>
                            <div>
                                <p style={{ fontSize: 15, color: "white", margin: "20px auto" }}>
                                    My Benifits{" "}
                                </p>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div
                                        style={{
                                            width: "48%",
                                            background: "rgb(83 202 147)",
                                            height: 230,
                                            borderRadius: 5
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                borderRight: 5,
                                                height: "65%",
                                                backgroundImage: "url(	/assets/png/vip_mybenefit1.png)",
                                                backgroundSize: "cover",
                                                justifyContent: "bottom"
                                            }}
                                        >
                                            <div style={{ width: 100, margin: "0 auto", paddingTop: "55%" }}>
                                                <p
                                                    style={{
                                                        background: "rgba(0, 0, 0, .5)",
                                                        borderRadius: 20,
                                                        padding: "2px 5px",
                                                        width: 100,
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "#fa2367",
                                                        fontWeight: 600,
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    <span id="levelupamount">{getLevelUpAmount(vipLevel)}</span>
                                                </p>
                                                <p
                                                    style={{
                                                        background: "rgba(0, 0, 0, .5)",
                                                        borderRadius: 20,
                                                        padding: "2px 5px",
                                                        width: 100,
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "35%",
                                                borderRadius: 5,
                                                color: "white",
                                                padding: 5
                                            }}
                                        >
                                            <p style={{ fontSize: 13 }}>Level up rewards</p>
                                            <p style={{ fontSize: 12, color: "#ffffff80" }}>
                                                Each account can only receive 1 time
                                            </p>
                                            <button id="levelupamountbtn" onClick={handleLevelUp} ></button>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: "48%",
                                            background: "rgb(83 202 147)",
                                            height: 230,
                                            borderRadius: 5
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                borderRight: 5,
                                                height: "65%",
                                                backgroundImage: "url(	/assets/png/vip_mybenefit2.png)",
                                                backgroundSize: "cover",
                                                justifyContent: "bottom"
                                            }}
                                        >
                                            <div style={{ width: 100, margin: "0 auto", paddingTop: "55%" }}>
                                                <p
                                                    style={{
                                                        background: "rgba(0, 0, 0, .5)",
                                                        borderRadius: 20,
                                                        padding: "2px 5px",
                                                        width: 100,
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "#fa2367",
                                                        fontWeight: 600,
                                                        fontSize: 13,
                                                        display: "flex",
                                                        marginBottom: 2
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/walleticon.png"
                                                    />
                                                    <span id="monthlyamount">{getMonthlyAmount(vipLevel)}</span>
                                                </p>
                                                <p
                                                    style={{
                                                        background: "rgba(0, 0, 0, .5)",
                                                        borderRadius: 20,
                                                        padding: "2px 5px",
                                                        width: 100,
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/diamondicon.png"
                                                    />
                                                    0
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "35%",
                                                borderRadius: 5,
                                                color: "white",
                                                padding: 5
                                            }}
                                        >
                                            <p style={{ fontSize: 13 }}>Monthly rewards</p>
                                            <p style={{ fontSize: 12, color: "#ffffff80" }}>
                                                Each account can only receive 1 time per month
                                            </p>
                                            <button id="monthlyamountbtn" onClick={handleMonthly} />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        padding: "10px 0"
                                    }}
                                >
                                    <div
                                        style={{
                                            width: "48%",
                                            background: "rgb(83 202 147)",
                                            height: 230,
                                            borderRadius: 5
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                borderRight: 5,
                                                height: "65%",
                                                backgroundImage: "url(	/assets/png/vip_mybenefit3.png)",
                                                backgroundSize: "cover",
                                                justifyContent: "bottom"
                                            }}
                                        >
                                            <div style={{ width: 100, margin: "0 auto", paddingTop: "65%" }}>
                                                <p
                                                    style={{
                                                        background: "rgba(0, 0, 0, .5)",
                                                        borderRadius: 20,
                                                        padding: "2px 5px",
                                                        width: 100,
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "#59adf8",
                                                        fontSize: 13,
                                                        display: "flex"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/protecticon.png"
                                                    />
                                                    <span id="safevalue">{`+${getSafeValue(vipLevel)}%`}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "35%",
                                                borderRadius: 5,
                                                color: "white",
                                                padding: 5
                                            }}
                                        >
                                            <p style={{ fontSize: 13 }}>Safe</p>
                                            <p style={{ fontSize: 12, color: "#ffffff80" }}>
                                                Increase the extra income of the safe
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        style={{
                                            width: "48%",
                                            background: "rgb(83 202 147)",
                                            height: 230,
                                            borderRadius: 5
                                        }}
                                    >
                                        <div
                                            style={{
                                                width: "100%",
                                                borderRight: 5,
                                                height: "65%",
                                                backgroundImage: "url(	/assets/png/vip_mybenefit4.png)",
                                                backgroundSize: "cover",
                                                justifyContent: "bottom"
                                            }}
                                        >
                                            <div style={{ width: 100, margin: "0 auto", paddingTop: "65%" }}>
                                                <p
                                                    style={{
                                                        background: "rgba(0, 0, 0, .5)",
                                                        borderRadius: 20,
                                                        padding: "2px 5px",
                                                        width: 100,
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        color: "yellow",
                                                        fontSize: 13,
                                                        display: "flex"
                                                    }}
                                                >
                                                    <img
                                                        style={{ width: 20, marginRight: 3 }}
                                                        src="/assets/png/dollaricon.png"
                                                    />
                                                    <span id="rebatevalue">{`+${getRebateValue(vipLevel)}%`}</span>
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "100%",
                                                height: "35%",
                                                borderRadius: 5,
                                                color: "white",
                                                padding: 5
                                            }}
                                        >
                                            <p style={{ fontSize: 13 }}>Rebate rate</p>
                                            <p style={{ fontSize: 12, color: "#ffffff80" }}>
                                                Increase income ofthe rebate
                                            </p>
                                            <p
                                                style={{
                                                    width: "95%",
                                                    margin: "5px auto",
                                                    textAlign: "center",
                                                    borderRadius: 20,
                                                    background:
                                                        "linear-gradient(130deg, #efd7a4 0%, #cca263 100%)",
                                                    color: "#4d2639",
                                                    padding: "5px 5px"
                                                }}
                                            // onClick="window.location.href='/viprebatedetail'"
                                            >
                                                Check the details
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ padding: "2%" }}>
                                <div className="tabs">
                                    <input type="radio" id="tab1" name="tab-control" defaultChecked />
                                    <input type="radio" id="tab2" name="tab-control" />
                                    <ul>
                                        <li id="history" title="Tab 1">
                                            <label
                                                htmlFor="tab1"
                                                role="button"
                                                onClick={() => setActiveTab('history')}
                                            >
                                                <span>History</span>
                                            </label>
                                        </li>
                                        <li id="rules" title="Tab 2">
                                            <label
                                                htmlFor="tab2"
                                                role="button"
                                                onClick={() => setActiveTab('rules')}
                                            >
                                                <span>Rules</span>
                                            </label>
                                        </li>
                                    </ul>
                                    <div
                                        className="content"
                                        style={{ padding: 5, background: "none !important" }}
                                    >
                                        <section
                                            style={{ backgroundColor: "rgb(83 202 147) !important", padding: 0 }}
                                        >
                                            <div id="recordcontainer" >
                                                {
                                                    records?.map((item, index) => <div key={index} style={{ display: "flex", borderBottom: "1px solid #42424287", backgroundColor: "#53ca93" }}>
                                                        <div style={{ width: "75%", padding: 10 }}>
                                                            <p className="historycard_head">Successfully received xxxxxxxxxxxxxxxxx</p>

                                                            <p className="historycard_body">
                                                                Successfully received item.type
                                                            </p>
                                                            <p className="historycard_date">
                                                                convertTimestampToDate(item.time)
                                                            </p>
                                                        </div>
                                                        <div
                                                            style={{
                                                                width: "25%",
                                                                display: "flex",
                                                                flexDirection: "column",
                                                                alignItems: "center",
                                                                justifyContent: "center"
                                                            }}
                                                        >
                                                            <p className="bonus-amount">
                                                                <img className="icon" src="/assets/png/walleticon.png" />
                                                                item.amount
                                                            </p>
                                                            <p className="diamond-amount">
                                                                <img className="icon" src="/assets/png/diamondicon.png" />0
                                                            </p>
                                                        </div>
                                                    </div>
                                                    )

                                                }
                                            </div>
                                            <div
                                                style={{
                                                    background: "rgb(206 238 223)",
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <button
                                                    id="rlinks"
                                                    data-v-8d20ef36=""
                                                    style={{
                                                        background: "linear-gradient(90deg, #31b075, #bff2da)",
                                                        padding: "5px 30px",
                                                        fontSize: 15,
                                                        fontWeight: 600,
                                                        color: "white",
                                                        border: "none",
                                                        borderRadius: 20,
                                                        margin: "15px auto",
                                                        boxShadow: '#dedbdb63 1px 3px 8px 1px, rgba(17, 17, 26, 0.1) 9px 0px 24px, rgb(17 17 26 / 12%) 0px 16px 56px',
                                                    }}
                                                    data-clipboard-text=""
                                                // onClick="window.location.href='/mian/history'"
                                                >
                                                    View all
                                                </button>
                                            </div>
                                        </section>
                                        <section>
                                            <div className="ruletab">
                                                <h1
                                                    style={{
                                                        display: "flex",
                                                        textAlign: "center",
                                                        justifyContent: "center",
                                                        alignItems: "center",
                                                        height: 110,
                                                        fontSize: 25,
                                                        fontWeight: 600
                                                    }}
                                                >
                                                    VIP privilages
                                                </h1>
                                                <div style={{ color: "white", padding: 15 }}>
                                                    <h4>VIP rule description</h4>
                                                    <h6>upgrade standard :</h6>
                                                    <p>
                                                        The IP member's experience points (valid bet amount) that
                                                        meet the requirements of the corresponding rank will be
                                                        promoted to the corresponding VIP level, the member's VIP
                                                        data statistics period starts from 00:00:00 days VIP system
                                                        launched.VIP level calculation is refreshed every 10
                                                        minutes! The corresponding experience level is calculated
                                                        according to valid odds 100:1 !
                                                    </p>
                                                    <h6>upgrade order :</h6>
                                                    <p>
                                                        The VIP level that meets the corresponding requirements can
                                                        be promoted by one level every day, but the VIP level cannot
                                                        be promoted by leapfrogging.
                                                    </p>
                                                    <h6>Level maintainance :</h6>
                                                    <p>
                                                        VIP members need to complete the maintenance requirements of
                                                        the corresponding level within 30 days after the "VIP level
                                                        change"; if the promotion is completed during this period,
                                                        the maintenance requirements will be calculated according to
                                                        the current level.
                                                    </p>
                                                    <h6>Downgrade standard :</h6>
                                                    <p>
                                                        If a VIP member fails to complete the corresponding level
                                                        maintenance requirements within 30 days, the system will
                                                        automatically deduct the experience points corresponding to
                                                        the level. If the experience points are insufficient, the
                                                        level will be downgraded, and the corresponding discounts
                                                        will be adjusted to the downgraded level accordingly.
                                                    </p>
                                                    <h6>upgrade bonus :</h6>
                                                    <p>
                                                        The upgrade benefits can be claimed on the VIP page after
                                                        the member reaches the VIP membership level, and each VIP
                                                        member can only get the upgrade reward of each level once.
                                                    </p>
                                                    <h6>Monthly reward :</h6>
                                                    <p>
                                                        VIP members can earn the highest level of VIP rewards once a
                                                        month.Can only be received once a month. Prizes cannot be
                                                        accumulated. And any unclaimed rewards will be refreshed on
                                                        the next settlement day. When receiving the highest level of
                                                        monthly rewards this month Monthly Rewards earned in this
                                                        month will be deducted e.g. when VIP1 earns 500 and upgrades
                                                        to VIP2 to receive monthly rewards 500 will be deducted.
                                                    </p>
                                                    <h6>Real-time rebate :</h6>
                                                    <p>
                                                        The higher the VIP level, the higher the return rate, all
                                                        the games are calculated in real time and can be
                                                        self-rewarded!
                                                    </p>
                                                    <h6>Safe :</h6>
                                                    <p>
                                                        VIP members who have reached the corresponding level will
                                                        get additional benefits on safe deposit based on the
                                                        member's VIP level.
                                                    </p>
                                                </div>
                                            </div>
                                        </section>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </div>
    )
}

export default Vip