import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dot } from 'react-bootstrap-icons';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Depositrecord = () => {
  const token = getWithExpiry("myuser");
  const [depositrecord, setdepositrecord] = useState([]);
  const [pageloader, setpageloader] = useState(false);

  const deposithistory = async () => {
    setpageloader(true);
    try {
      let data = await axios.post('https://tronapi.tronvid.site/deposithistory', { token }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setdepositrecord(data.data.userdata || []);
    } catch (error) {
      console.error('Error fetching deposit history:', error);
      setdepositrecord([]);
    } finally {
      setpageloader(false);
    }
  };

  useEffect(() => {
    deposithistory();
  }, []);

  return (
    <>
      {pageloader && <LoadingSpinner />}
      <div style={{background:"#f0ffff",}}>
        {depositrecord.length === 0 ? (
          <div style={{ width: "100%", textAlign: "center" }}>
            <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
          </div>
        ) : (
          depositrecord.map((item, index) => (
            <div className='rechargecards' key={index}>
              <div>
                <p>Recharge</p>
                <strong>{item.actual_pay} ({item.pay_currency === "trx" ? "TRX" : "USDT"})</strong>
                <p className='rechargecardtimer'>{item.created_at}</p>
              </div>
              <div className='d-flex align-items-center'>
                {item.amount_status === "1" && (
                  <p style={{ fontSize: "15px", color: "green" }}>
                    <Dot />Successful
                  </p>
                )}
                {item.amount_status === "0" && (
                  <p style={{ fontSize: "15px", color: "rgba(195, 195, 66, 0.737)" }}>
                    <Dot />Pending
                  </p>
                )}
                {item.amount_status === "2" && (
                  <p style={{ fontSize: "15px", color: "red" }}>
                    <Dot />Failed
                  </p>
                )}
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
}

export default Depositrecord;
