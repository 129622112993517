import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import "./Attendance.css"
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';
const Attendance = () => {

    const [pageloader, setpageloader] = useState(false);

    const images = [1, 2, 3, 4, 5, 6, 7, 9, 10, 11, 12, 13, 14, 15, 17,18,19,20]
    
    const randomsize = () => {
        setpageloader(true)
        var list = document.getElementsByClassName("listing-item")
        const imageurl=["./icon_mine_TRX.png","./usdt-BK5BR48P.png"]
        const myArray = [20, 30, 50, 45, 15, 40];

        for (var i = 0; i < list.length; i++) {
            const randomElement = myArray[Math.floor(Math.random() * myArray.length)];
            list[i].style.height = randomElement + "px";
            list[i].style.width = randomElement + "px";
            const randomurl = imageurl[Math.floor(Math.random() * imageurl.length)];
            list[i].style.backgroundImage =`url(${randomurl})`;
        }
        setpageloader(false)
    }

    useEffect(() => {
        randomsize()
    }, [])


    const handleReceive = async () => {
        try {

            setpageloader(true)
            let response = await axios.post('https://tronapi.tronvid.site/getmining', 
                { token: getWithExpiry("myuser")}, 
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );    
            setpageloader(false)
            if (response.data.status === 'success' || response.data.status === 'error') {
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
    
                unimsg.textContent = response.data.message;
                unimodal.style.display = 'block';
                
    
                setTimeout(() => {
                    unimodal.style.display = 'none';
                }, 1500);
            }
        } catch (error) {
            console.error('Error making POST request', error);
        }
    };



    return (
        <div>
            {pageloader&&<LoadingSpinner/>}
            <div className="attendance_container">
             
                <div className="attendance_bonus_container d-flex justify-content-around align-items-center">
                    <div className='py-5 px-3'>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item my-2 mx-2  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item my-2 mx-2  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item my-2 mx-2  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()} className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div onClick={() => handleReceive()}  className="ballcenter listing-item minetrx  d-flex justify-content-center align-items-center" >
                            <div className="ball d-flex justify-content-center align-items-center">
                                <div className="ball1  d-flex justify-content-center align-items-center">
                                    <div className="ball2  d-flex justify-content-center align-items-center">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Attendance