import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingSpinner from '../Loader/LoadingSpinner';
import { getWithExpiry } from '../JS/JS';

const Gamerecordclose15 = () => {

    const navigate = useNavigate();

    const location = useLocation()
    const [pageloader, setpageloader] = useState(true)

    const [nav, setnav] = useState("close")
    const [selectedValue, setSelectedValue] = useState(15);

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };


    const [result, setresult] = useState([]);


    const fetchclosingrecords = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/getethhistory', { interval: selectedValue,token: getWithExpiry("myuser"), game_type: `eth${selectedValue}` }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data?.data?.message === "No records found") {
            setresult([])
        } else {
            setresult(data?.data?.btchistory)
        }
        setpageloader(false)
    }


    useEffect(() => {
        fetchclosingrecords();
    }, [selectedValue])



    const handleSelect = (eventKey) => {
        let selectedText;
        switch (eventKey) {
            case '1':
                selectedText = 1;
                break;
            case '2':
                selectedText = 5;
                break;
            case '3':
                selectedText = 15;
                break;
            default:
                selectedText = 'Select an option';
        }
        sessionStorage.setItem('selectedintervel', selectedText);

        // setSelectedValue(selectedText);
        fetchselectedinterval();

    };

    const fetchselectedinterval = () => {
        const value = sessionStorage.getItem('selectedintervel');
        // setSelectedValue(value);
    }

    useEffect(() => {
        fetchselectedinterval()
    }, [])

    return (
        <div>
            {pageloader && <LoadingSpinner />}


            <div className="shadow-sm position-sticky sticky-top" style={{ backgroundColor: "white" }}>
                <div className='d-flex justify-content-between align-items-center  p-2'>
                    <div className="" style={{ width: "20%" }}>{(location.pathname === "/index") ? <img style={{ height: "30px", width: "30px" }} src="https://genusdt.net/static/img/logo.png" alt="" /> : <ArrowLeft onClick={() => navigate(`/eth${selectedValue}`)} />}</div>
                    {/* <div className=""></div> */}
                    <div className="text-center d-flex align-items-center justify-content-center" style={{ width: "60%" }}>

                        <span>Record</span>
                    </div>
                    <div className="navbutton d-flex justify-content-end" style={{ width: "20%" }}>
                        {/* {<DropdownButton
                            className='ms-auto '
                            as={ButtonGroup}
                            key={"info"}
                            id={`dropdown`}
                            onSelect={handleSelect}
                            // info={"info".toLowerCase()}
                            title={selectedValue + " min"}>

                            <Dropdown.Item eventKey="1" >1 min</Dropdown.Item>
                            <Dropdown.Item eventKey="2">5 min</Dropdown.Item>
                            <Dropdown.Item eventKey="3">15 min</Dropdown.Item>
                        </DropdownButton>} */}


                        {/* {(location.pathname === "/withdraw") && <span onClick={() => navigate("/withdrawrecord")}><Calendar2Event className='mx-2' /></span>} */}
                        {/* {(location.pathname == "/depositpage" || location.pathname == "/deposittrx" || location.pathname == "/depositusdt") && <span onClick={() => navigate("/depositrecord")}><Calendar2Event className='mx-2' /></span>} */}
                    </div>
                </div>
            </div>



            <div className="d-flex justify-content-around gamerecordnav py-2 bg-light">
                <p className={`mb-0 px-3  active text-white`} onClick={() => navigate("/gamerecordclosings15")}>Closing</p>
                <p className={`mb-0 px-3 `} onClick={() => navigate("/gamerecordtransations15")}>Trasaction</p>
                <p className={`mb-0 px-3 `} onClick={() => navigate("/gamerecordstatics15")}>Statical</p>
            </div>

            {(nav === "close") && <div className='my-2'>

                {result?.map((data, index) => <div key={index}>
                    <div className='d-flex justify-content-between px-2'>
                        <div>
                            <p style={{ fontSize: "11px", color: "#999" }}>{data.uid}</p>
                            <p className='mb-0' style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>{data.price}</p>
                        </div>
                        <div>
                            <p className='text-end' style={{ fontSize: "11px", color: "#999" }}>{data.updated_at}</p>
                            <div className="d-flex justify-content-end text-center me-2">
                                {(data?.color === "big") && <div className=' d-flex align-items-center justify-content-center big' >Big</div>}
                                {(data?.color === "small") && <div className=' mx-2 d-flex align-items-center justify-content-center small' >Small</div>}
                                {(data?.color2 === "single") && <div className=' mx-2 d-flex align-items-center justify-content-center single' >Single</div>}


                                {(data?.color2 === "double") && <div className=' mx-2 d-flex align-items-center justify-content-center double' >Double</div>}


                                {(data?.number === "0") && <div className={`selectboxmulti1`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", borderLeft: "red 2px solid", borderTop: "red 2px solid", borderRight: "blue 2px solid", borderBottom: "blue 2px solid" }} >0</div>}

                                {(data?.number === "1") && <div className={`selectboxgreen`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }}>1</div>}

                                {(data?.number === "2") && <div className={`selectboxred`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }}>2</div>}

                                {(data?.number === "3") && <div className={`selectboxgreen`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }}>3</div>}

                                {(data?.number === "4") && <div className={`selectboxred`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }}>4</div>}


                                {(data?.number === "5") && <div className={`selectboxmulti2`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", borderLeft: "green 2px solid", borderTop: "green 2px solid", borderRight: "blue 2px solid", borderBottom: "blue 2px solid" }}>5</div>}

                                {(data?.number === "6") && <div className={`selectboxred`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }}>6</div>}

                                {(data?.number === "7") && <div className={`selectboxgreen`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }}>7</div>}

                                {(data?.number === "8") && <div className={`selectboxred`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }}>8</div>}

                                {(data?.number === "9") && <div className={`selectboxgreen`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }}>9</div>}

                                {/* {<div className=' d-flex align-items-center justify-content-center' style={{ color: "green", height: "25px", fontSize: "10px", width: "50px", borderRadius: "5px", borderLeft: "red 2px solid", border: "green 2px solid", borderBottom: "blue 2px solid" }}>
                                    <span className='mb-0 ' style={{ lineHeight: "10px", width: "25px" }}> Big Single</span>
                                </div>} */}

                            </div>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>)}

            </div>}



        </div >
    )
}

export default Gamerecordclose15