import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { apiEndPointt } from './API/ApiPaths';
import LoadingSpinner from './Loader/LoadingSpinner';

const Signup = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setemail] = useState('');
  const [password, setpassword] = useState('');
  const [withdraw, setwithdraw] = useState('');
  const [Invitationcode, setInvitationcode] = useState('');
  const [otp, setotp] = useState('');
  const [mobile, setmobile] = useState('');
  const [pageloader, setpageloader] = useState(false);
  const [field, setfield] = useState('otp');
  const [time, settime] = useState(0);
  const [passwordfield, setpasswordfield] = useState('password');

  useEffect(() => {
    if (location.search) {
      let code = location.search.split('=')[1];
      setInvitationcode(code);
    }
  }, [location.search]);

  const sendotp = async () => {
    setpageloader(true);
    try {
      const { data } = await axios.post('https://tronapi.tronvid.site/sendotp', { email }, {
        headers: { 'Content-Type': 'application/json' },
      });

      if (data.status === 'success') {
        const unimodal = document.getElementById('unimodal');
        const unimsg = document.getElementById('unimsg');
        unimsg.textContent = data.message;
        unimodal.style.display = 'block';

        setpageloader(false);
        setTimeout(() => unimodal.style.display = 'none', 1500);

        timer();
      }
    } catch (error) {
      console.error(error);
      setpageloader(false);
    }
  };


  const validateWithdrawPassword = () => {
    if (/^\d{6}$/.test(withdraw)) {
      return true;
    }

    const unimodal = document.getElementById('unimodal');
    const unimsg = document.getElementById('unimsg');
    unimsg.textContent = "Please Enter 6 digit Withdrawal password";
    unimodal.style.display = 'block';

    setTimeout(() => {
      unimodal.style.display = 'none';
    }, 1500);

    return false;
  };


  const signupuser = async () => {

    if (!validateWithdrawPassword()) {
      return;
    }


    setpageloader(true);
    try {
      const { data } = await axios.post(apiEndPointt.SIGNUP, {
        email,
        password,
        invitecode: Invitationcode,
        withpass: withdraw,
        mobile,
      }, {
        headers: { 'Content-Type': 'application/json' },
      });

      const unimodal = document.getElementById('unimodal');
      const unimsg = document.getElementById('unimsg');
      unimsg.textContent = data.message;
      unimodal.style.display = 'block';

      setTimeout(() => {
        unimodal.style.display = 'none';
        if (data.status === 'success') window.location.href = '/login';
      }, 1500);

      setpageloader(false);
    } catch (error) {
      console.error(error);
      setpageloader(false);
    }
  };

  const timer = () => {
    let timeleft = 60;
    setfield('time');
    const downloadTimer = setInterval(() => {
      if (timeleft <= 0) {
        setfield('otp');
        settime(0);
        clearInterval(downloadTimer);
      } else {
        settime(timeleft);
      }
      timeleft -= 1;
    }, 1000);
  };

  const handleWithdrawChange = (event) => {
    const input = event.target.value;
    // Allow only numbers and limit to 6 digits
    const cleanedInput = input.replace(/\D/g, ''); // Remove non-numeric characters
    if (cleanedInput.length <= 6) {
      setwithdraw(cleanedInput);
    }
  };

  return (
    <div style={{ marginBottom: "-60px", height: "100vh", maxHeight: "100vh", overflow: "", background: "#f0ffff" }}>
      {pageloader && <LoadingSpinner />}
      <div className='d-flex justify-content-center Login'>


        <div className="col-lg-6" >


          {/* <div class="">


            <div className='bgmanneger'>

              <div className="text-center py-2" style={{ fontWeight: "600", color: "white" }}>
                <h6>Sign Up</h6>
                <p>Sign Up to start using TRX PLAY</p>
              </div>
              <div className="d-flex justify-content-center">
                <img src="/logo/3.png" alt="" style={{ height: "50px", width: "50px" }} />
              </div>

            </div>
            <div className="ocean">
              <div className="wave"></div>
              <div className="wave"></div>
              <div className="wave"></div>
            </div>
          </div> */}


          <div class="logintopimgdiv">


          </div>
          <div class="logintopimgdivboxshadow px-3">


            <div className="" style={{ width: "80%", color: "white", fontSize: "23px", fontWeight: "600", padding: "10px", position: "absolute", top: "50px" }}>
              <p>Email !</p>
              <p style={{ fontSize: "18px" }}>Registration</p>
            </div>

            <div className="card " style={{ border: "none", borderRadius: "15px", height: "100%", background: "#f0ffff", overflow: "hidden" }}>
              <div className="d-flex" style={{ background: "#a8b2b2", color: "white" }}>
                <div className="text-center  p-2" style={{ width: "50%", fontSize: "18px", fontWeight: "500" }} onClick={() => navigate('/login')} >Login</div>
                <div className="text-center formactive1 p-2" style={{ width: "50%", fontSize: "18px", fontWeight: "500" }}>Sign Up</div>
              </div>
              <div className="card-body px-4 px-md-5 pt-0">
                <div className="row">
                  <div data-mdb-input-init="" className="form-outline my-2">
                    <label className='mb-2' htmlFor="email">Email</label>
                    <div className="d-flex">
                      <input
                        type="text"
                        id="email"
                        className="form-control"
                        placeholder='Enter Email'
                        value={email}
                        onChange={(e) => setemail(e.target.value)}
                        style={{ background: "#daf0f5", borderRadius: "50px", border: "none" }}

                      />
                    </div>
                  </div>
                  <div data-mdb-input-init="" className="form-outline my-2">
                    <label className='mb-2' htmlFor="mobile">Mobile number</label>
                    <input
                      type="text"
                      id="mobile"
                      className="form-control"
                      placeholder='Enter Contact'
                      value={mobile}
                      onChange={(e) => setmobile(e.target.value)}
                      style={{ background: "#daf0f5", borderRadius: "50px", border: "none" }}

                    />
                  </div>
                  <div className="form-outline my-2">
                    <label className='mb-2' htmlFor="password">Password</label>
                    <div style={{ position: 'relative' }}>
                      <input
                        type={passwordfield}
                        id="password"
                        className="form-control"
                        placeholder='Enter password'
                        value={password}
                        onChange={(e) => setpassword(e.target.value)}
                        style={{ background: "#daf0f5", borderRadius: "50px", border: "none" }}

                      />
                      <div style={{ position: 'absolute', right: '10px', top: '5px' }}>
                        {passwordfield === 'password' ? (
                          <EyeSlash onClick={() => setpasswordfield('text')} />
                        ) : (
                          <Eye onClick={() => setpasswordfield('password')} />
                        )}
                      </div>
                    </div>
                  </div>
                  <div data-mdb-input-init="" className="form-outline my-2">
                    <label className='mb-2' htmlFor="withdraw">Withdraw Password</label>
                    <input
                      type="text"
                      id="withdraw"
                      className="form-control"
                      placeholder='Enter password'
                      maxLength="6"
                      minLength="6"
                      value={withdraw}
                      onChange={handleWithdrawChange}
                      style={{background:"#daf0f5",borderRadius:"50px",border:"none"}}

                    />
                  </div>
                  <div data-mdb-input-init="" className="form-outline my-2">
                    <label className='mb-2' htmlFor="invitationCode">Invitation Code</label>
                    <input
                      type="text"
                      id="invitationCode"
                      className="form-control"
                      placeholder='Enter Code'
                      value={Invitationcode}
                      onChange={(e) => setInvitationcode(e.target.value)}
                      style={{ background: "#daf0f5", borderRadius: "50px", border: "none" }}

                    />
                  </div>
                  <div className='d-flex pt-0 justify-content-center'>
                    <button
                      onClick={signupuser}
                      data-mdb-button-init=""
                      data-mdb-ripple-init=""
                      className="loginbtn linear_gradiend_green"
                    >
                      Sign Up
                    </button>
                  </div>
                  <div className='text-center py-3'>
                    <p style={{ color: '#958d9e' }}>
                      Have an account?
                      <span onClick={() => navigate('/login')} className='fw-bold text-dark'>
                        SIGN IN
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="ocean2">
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
          </div> */}
            {/* <div className="bgmanneger2">
          </div> */}
          </div>

        </div>



      </div>
    </div>
  );
};

export default Signup;
