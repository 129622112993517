import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Loader/LoadingSpinner';
import { getWithExpiry } from '../JS/JS';
import axios from 'axios';

const Teamtodaycommission = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const lvltype = location.state?.lvl;

    const [records, setRecords] = useState([]);
    const [pageloader, setPageLoader] = useState(true);

    const lvlcommition = async () => {
        setPageLoader(true);
        try {
            let response = await axios.post('https://tronapi.tronvid.site/rebaterecord', 
            { token: getWithExpiry("myuser"), level: lvltype }, 
            { headers: { 'Content-Type': 'application/json' } });
            
            setRecords(response.data.userdata || []);
        } catch (error) {
            console.error('Error fetching data:', error);
            setRecords([]); // Ensure records is set to an empty array on error
        } finally {
            setPageLoader(false);
        }
    };

    useEffect(() => {
        lvlcommition();
    }, [lvltype]);

    return (
        <>
            {pageloader ? (
                <LoadingSpinner />
            ) : records.length === 0 ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                    <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
                </div>
            ) : (
                <div className="historypagedata ">
                    {records.map((data, index) => (
                        <div key={index}>
                            <div className="cards px-3">
                                <p className='d-flex mb-0 justify-content-between'>
                                    <span>Lv{lvltype}</span>
                                    <span>{data?.amount}{data?.curr}</span>
                                </p>
                                <p className='mb-0'>{data?.createdate}</p>
                            </div>
                            <hr className='my-1' />
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Teamtodaycommission;
