import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import { ArrowLeft } from 'react-bootstrap-icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { getWithExpiry } from '../JS/JS'
import LoadingSpinner from '../Loader/LoadingSpinner'


const Gamerecordtransactionbtc5 = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [pageloader, setpageloader] = useState(true)


    const [selectedValue, setSelectedValue] = useState(5);
    const [nav, setnav] = useState("close")

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = (id) => {
        setselectedid(id)
        setShowPopup(!showPopup);
    };



    const [result, setresult] = useState([]);
    const [selectedid, setselectedid] = useState();


    const fetchclosingrecords = async () => {
        setpageloader(true)

        let data = await axios.post('https://tronapi.tronvid.site/userbtcbethistory', { interval: selectedValue, token: getWithExpiry("myuser"), game_type: `btc${selectedValue}` }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data?.data?.message === "No records found") {
            setresult([])
        } else {
            setresult(data?.data?.btchistory)
        }

        setpageloader(false)

    }


    useEffect(() => {
        fetchclosingrecords();
    }, [selectedValue])

    const handleSelect = (eventKey) => {
        let selectedText;
        switch (eventKey) {
            case '1':
                selectedText = 1;
                break;
            case '2':
                selectedText = 5;
                break;
            case '3':
                selectedText = 15;
                break;
            default:
                selectedText = 'Select an option';
        }
        sessionStorage.setItem('selectedintervel', selectedText);

        // setSelectedValue(selectedText);
        fetchselectedinterval();

    };


    function resultup(results) {

        var responseresult = "";
        if (results === "big") {

            responseresult = "Big";
        } else if (results === "small") {

            responseresult = "Small";

        } else if (results === "single") {

            responseresult = "Single";
        } else if (results === "double") {

            responseresult = "Double";
        } else if (results === "bigsingle") {

            responseresult = "Big Single";
        } else if (results === "smallsingle") {

            responseresult = "Small Single";
        } else if (results === "smalldouble") {

            responseresult = "Small Double";
        } else if (results === "bigdouble") {

            responseresult = "Big Double";
        }

        return responseresult


    }

    const fetchselectedinterval = () => {
        const value = sessionStorage.getItem('selectedintervel');
        // setSelectedValue(value);
    }

    useEffect(() => {
        fetchselectedinterval()
    }, [])

    return (
        <div>
            {pageloader && <LoadingSpinner />}



            <div className="shadow-sm position-sticky sticky-top" style={{ backgroundColor: "white" }}>
                <div className='d-flex justify-content-between align-items-center  p-2'>
                    <div className="" style={{ width: "20%" }}>{(location.pathname === "/index") ? <img style={{ height: "30px", width: "30px" }} src="https://genusdt.net/static/img/logo.png" alt="" /> : <ArrowLeft onClick={() => navigate("/btc1")} />}</div>
                    {/* <div className=""></div> */}
                    <div className="text-center d-flex align-items-center justify-content-center" style={{ width: "60%" }}>

                        <span>Record</span>
                    </div>
                    <div className="navbutton d-flex justify-content-end" style={{ width: "20%" }}>
                        {/* {<DropdownButton
                            className='ms-auto '
                            as={ButtonGroup}
                            key={"info"}
                            id={`dropdown`}
                            onSelect={handleSelect}
                            // info={"info".toLowerCase()}
                            title={selectedValue + " min"}
                        >
                            <Dropdown.Item eventKey="1" >1 min</Dropdown.Item>
                            <Dropdown.Item eventKey="2">5 min</Dropdown.Item>
                            <Dropdown.Item eventKey="3">15 min</Dropdown.Item>
                        </DropdownButton>} */}


                        {/* {(location.pathname === "/withdraw") && <span onClick={() => navigate("/withdrawrecord")}><Calendar2Event className='mx-2' /></span>} */}
                        {/* {(location.pathname == "/depositpage" || location.pathname == "/deposittrx" || location.pathname == "/depositusdt") && <span onClick={() => navigate("/depositrecord")}><Calendar2Event className='mx-2' /></span>} */}
                    </div>
                </div>
            </div>









            <div className="d-flex justify-content-around gamerecordnav py-2 bg-light">
                <p className={`mb-0 px-3 `} onClick={() => navigate("/gamerecordclosingsbtc5")}>Closing</p>
                <p className={`mb-0 px-3 active text-white`} onClick={() => navigate("/gamerecordtransationsbtc5")}>Trasaction</p>
                <p className={`mb-0 px-3  `} onClick={() => navigate("/gamerecordstaticsbtc5")}>Statical</p>
            </div>



            {<div className='my-2'>

                {result?.map((data, index) => <div key={index}>
                    <div className="d-flex justify-content-between px-3" onClick={() => togglePopup(index)}>
                        <div>
                            <p style={{ fontSize: "11px", color: "#999" }}>PD {data.timer_id}</p>
                            {(data?.status === "0") && <p className='text-center mb-0' style={{ width: "55px", fontSize: "15px", border: "1px solid #ff2d55", color: "#ff2d55", borderRadius: "5px" }}>LOSE</p>}
                            {(data?.status === "2") && <p className='text-center' style={{ width: "55px", fontSize: "15px", border: "1px solid #20c895", color: "#20c895", borderRadius: "5px" }}>WIN</p>}
                            {(data?.status === "1") && <p className='text-center' style={{ width: "70px", fontSize: "15px", border: "1px solid #e3e129", color: "#e3e129", borderRadius: "5px" }}>Pending</p>}
                        </div>
                        <div className='text-end'>
                            <p style={{ fontSize: "11px", color: "#999" }}>{data.update_at}</p>
                            <p className='mb-0 d-flex justify-content-end' style={{ justifyContent: 'space-between' }}>{resultup(data.spot)}
                                <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} />
                            </p>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>)}

            </div>}





            <div className={`popup-container withdraw  ${showPopup && 'show'}`}>
                {<div className="popup-content px-0">
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px", color: "#999" }}>
                        <p>PD {result[selectedid]?.timer_id}</p>
                        <p >{result[selectedid]?.update_at}</p>
                    </div>
                    <hr className='my-1' />

                    <div className='py-2 bg-light text-center' style={{ fontSize: "20px" }}> <span>{result[selectedid]?.result}</span></div>

                    <div className='py-2' style={{ fontSize: "14px", lineHeight: "2" }}>

                        <p className='mb-0 px-2'>

                            Transaction amount : {result[selectedid]?.amount + " " + result[selectedid]?.curr}
                        </p>
                        <hr className='my-1 ' />
                        <p className='mb-0 px-2'>Profit and loss results : {result[selectedid]?.payable_amount + " " + result[selectedid]?.curr}</p>
                        <hr className='my-1' />
                        <p className='mb-0 px-2'>Transaction option : </p>
                    </div>


                    {
                        <div className="d-flex ms-3 text-center">
                            {(result[selectedid]?.spot === "big") && <div className=' d-flex flex-column align-items-center justify-content-center big' style={{ borderWidth: "1px" }} >Big <span style={{ fontSize: "10px", lineHeight: "9px" }}>{result[selectedid]?.payable_amount}</span></div>}
                            {(result[selectedid]?.spot === "small") && <div className=' mx-2 d-flex  flex-column align-items-center justify-content-center small' style={{ borderWidth: "1px" }}>Small <span style={{ fontSize: "10px", lineHeight: "9px" }}>{result[selectedid]?.payable_amount}</span></div>}
                            {(result[selectedid]?.spot === "single") && <div className=' mx-2 d-flex flex-column align-items-center justify-content-center single' style={{ borderWidth: "1px" }}>Single <span style={{ fontSize: "10px", lineHeight: "9px" }}>{result[selectedid]?.payable_amount}</span></div>}
                            {(result[selectedid]?.spot === "double") && <div className=' mx-2 d-flex flex-column align-items-center justify-content-center double' style={{ borderWidth: "1px" }} >Double <span style={{ fontSize: "10px", lineHeight: "9px" }}>{result[selectedid]?.payable_amount}</span></div>}


                            {(result[selectedid]?.spot === "smalldouble") &&
                                <div
                                    style={{
                                        width: '70px',
                                        marginRight: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '40px',
                                        borderRadius: '5px',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: 'flex-end',
                                            borderRadius: '5px 5px 0 0',

                                            border: '1px solid #db3431',
                                            color: '#db3431',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '40%',
                                            fontSize: '8px',
                                            fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                            width: '100%',
                                            borderBottom: 'none',
                                        }}
                                    >
                                        SMALL
                                    </div>
                                    <div className='d-flex flex-column'
                                        style={{
                                            alignSelf: 'flex-start',
                                            borderRadius: '0 0 5px 5px',
                                            border: '1px solid #f69045',
                                            color: '#f69045',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '60%',
                                            width: '100%',
                                            fontSize: '8px',
                                            fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                            borderTop: 'none',
                                        }}
                                    >
                                        DOUBLE
                                        <span style={{ fontSize: "10px", lineHeight: "15px" }}>{result[selectedid]?.payable_amount}</span>
                                    </div>
                                </div>

                            }
                            {(result[selectedid]?.spot === "smallsingle") &&
                                <div className='d-flex flex-column'
                                    style={{
                                        width: '70px',
                                        marginRight: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '40px',
                                        borderRadius: '5px',
                                        boxSizing: 'border-box',


                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: 'flex-end',
                                            borderRadius: '5px 5px 0 0',

                                            border: '1px solid #db3431',
                                            color: '#db3431',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '40%',
                                            fontSize: '8px',
                                            fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                            width: '100%',
                                            borderBottom: 'none',
                                        }}
                                    >
                                        SMALL
                                    </div>
                                    <div className='d-flex flex-column'
                                        style={{
                                            alignSelf: 'flex-start',
                                            borderRadius: '0 0 5px 5px',

                                            border: '1px solid #3182e1',
                                            color: '#3182e1',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '60%',
                                            width: '100%',
                                            fontSize: '8px',
                                            fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                            borderTop: 'none',
                                        }}
                                    >
                                        SINGLE
                                        <span style={{ fontSize: "10px", lineHeight: "15px" }}>{result[selectedid]?.payable_amount}</span>
                                    </div>
                                </div>
                            }

                            {(result[selectedid]?.spot === "bigsingle") &&
                                <div
                                    style={{
                                        width: '70px',
                                        marginRight: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '40px',
                                        borderRadius: '5px',
                                        boxSizing: 'border-box',
                                    }}
                                >
                                    <div
                                        style={{
                                            alignSelf: 'flex-end',
                                            borderRadius: '5px 5px 0 0',

                                            border: '1px solid #32b277',
                                            color: '#32b277',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '40%',
                                            fontSize: '8px',
                                            borderBottom: 'none',
                                            fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                            width: '100%',

                                        }}
                                    >
                                        BIG
                                    </div>
                                    <div className='d-flex flex-column'
                                        style={{
                                            alignSelf: 'flex-start',
                                            borderRadius: '0 0 5px 5px',

                                            border: '1px solid #3182e1',
                                            color: '#3182e1',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '60%',
                                            width: '100%',
                                            fontSize: '8px',
                                            fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                            borderTop: 'none',
                                        }}
                                    >
                                        SINGLE

                                        <span style={{ fontSize: "10px", lineHeight: "15px" }}>{result[selectedid]?.payable_amount}</span>
                                    </div>
                                </div>

                            }


                            {(result[selectedid]?.spot === "bigdouble") &&
                                <div
                                    style={{
                                        width: '70px',
                                        marginRight: '5px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        height: '40px',
                                        borderRadius: '5px',
                                        boxSizing: 'border-box',
                                        borderbottom: 'none',
                                    }}
                                >
                                    <div className='d-flex '
                                        style={{
                                            alignSelf: 'flex-end',
                                            borderRadius: '5px 5px 0 0',
                                            border: '1px solid #32b277',
                                            color: '#32b277',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '40%',
                                            fontSize: '8px',
                                            fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                            width: '100%',
                                            borderBottom: 'none',
                                        }}
                                    >
                                        BIG
                                    </div>
                                    <div className='d-flex flex-column'
                                        style={{
                                            alignSelf: 'flex-start',
                                            borderRadius: '0 0 5px 5px',

                                            border: '1px solid #f69045',
                                            color: '#f69045',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            height: '60%',
                                            width: '100%',
                                            fontSize: '8px',
                                            fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                            borderTop: 'none',
                                        }}
                                    >
                                        DOUBLE

                                        <span style={{ fontSize: "10px", lineHeight: "15px" }}>{result[selectedid]?.payable_amount}</span>
                                    </div>
                                </div>
                            }


                        </div>
                    }

                    <hr className='my-1' />

                    <p className='text-center mb-0' style={{ fontSize: "14px", color: "#999" }} onClick={() => setShowPopup(!showPopup)}>Cancel</p>

                </div>}

            </div>

        </div >
    )

}

export default Gamerecordtransactionbtc5