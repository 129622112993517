import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'react-bootstrap-icons';

const Forgetpass = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [OTP, setOTP] = useState('');

    const handlesubmit = async () => {
        try {
            let data = await axios.post('https://tronapi.tronvid.site/forgot', {
                email: email,
                otp: OTP,
                password: password,
                headers: {
                    'Content-Type': 'application/json',
                },
            });
        } catch (error) {
        }

    }

    const sendotp = async () => {

        let data = await axios.post('https://tronapi.tronvid.site/sendotp', { email }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
    }


    return (
        <div className='d-flex justify-content-center Login' style={{ marginBottom: "-60px", height: "100vh", maxHeight: "100vh", overflow: "hidden", background: "#f0ffff" }}>
            <div className="col-lg-6">

                {/* <div className="">
                    <div className='bgmanneger4'>
                        <div className="text-center pb-3" style={{ fontWeight: "600", color: "white" }}>
                            <p>Enter Your Email Address to reset your password</p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <img src="/logo/3.png" alt="" style={{ height: "50px", width: "50px" }} />
                        </div>
                    </div>
                    <div className="ocean">
                        <div className="wave"></div>
                        <div className="wave"></div>
                        <div className="wave"></div>
                    </div>
                </div> */}


                <div class="logintopimgdiv">
                    <div className='d-flex text-white justify-content-between p-2'>
                        <div style={{ width: "10%", fontSize: "20px" }} className='d-flex justify-content-center align-items-center' onClick={() => navigate(-1)}> <ArrowLeft /></div>
                        <h5 style={{ width: "80%" }} className='d-flex justify-content-center align-items-center' onClick={handlesubmit}> Forget Password</h5>
                        <div style={{ width: "10%" }}></div>
                    </div>

                </div>
                <div class="logintopimgdivboxshadow px-3">



                    <div className="card" style={{ border: "none", borderRadius: "10px", background: "#f0ffff" }}>
                        
                        <div className="card-body px-4 px-md-5">
                            <form onSubmit={handlesubmit}>
                                <div className="row">
                                    <div className="form-outline my-2">
                                        <label className='mb-2' htmlFor="email">Email Address</label>
                                        <div className="d-flex">

                                            <input
                                                type="text"
                                                id="email"
                                                onChange={(e) => setPassword(e.target.value)}
                                                className="form-control"
                                                placeholder='Your Email'
                                            />
                                            <p className='mb-0 mx-2 d-flex text-center justify-content-center align-items-center' style={{ width: "80px", borderRadius: "10px", fontSize: "10px", padding: "10px", background: "#1b434c", fontWeight: "700" }} onClick={sendotp}><span> Send OTP</span></p>
                                        </div>
                                    </div>
                                    <div className="form-outline my-2">
                                        <label className='mb-2' htmlFor="email">Enter OTP</label>
                                        <input
                                            type="number"
                                            id="otp"
                                            onChange={(e) => setOTP(e.target.value)}
                                            className="form-control"
                                            placeholder='Your Email'
                                        />
                                    </div>
                                    <div className="form-outline my-2">
                                        <label className='mb-2' htmlFor="email">Enter New password</label>
                                        <input
                                            type="password"
                                            id="password"
                                            onChange={(e) => setPassword(e.target.value)}
                                            className="form-control"
                                            placeholder='Your Email'
                                        />
                                    </div>
                                    <div className='d-flex pt-0 justify-content-center'>
                                        <button
                                            type="submit"
                                            className="loginbtn"
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* <div className="ocean1">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                <div className="bgmanneger1">
                </div> */}
            </div>
        </div>
    );
};

export default Forgetpass;
