import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { EyeSlash, InfoCircleFill } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const UpdatePassword = () => {


    const navigate = useNavigate()

    const [passwordfield, setpasswordfield] = useState("password");
    const [confirmpasswordfield, setconfirmpasswordfield] = useState("password");


    const [pageloader, setpageloader] = useState(false)

    const [field, setfield] = useState("otp")
    const [time, settime] = useState()
    const [getuser, setgetuser] = useState()
    const [otp, setotp] = useState()
    const [password, setpassword] = useState()
    const [cpassword, setcpassword] = useState()


    const togglefields = (type, field) => {
        if (type === "password") {
            (field === "pass") && setpasswordfield("text");
            (field === "cpass") && setconfirmpasswordfield("text");
        }
        if (type === "text") {
            (field === "pass") && setpasswordfield("password");
            (field === "cpass") && setconfirmpasswordfield("password");
        }
    }


    const checkuser = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token: getWithExpiry("myuser") }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {
            navigate("/login")
        }
        else {
            setgetuser(data.data.userdata)
            // if (data.data.userdata.w_address !== null) {
            //     setshowaddress(false)
            // }
            setpageloader(false)
        }
    }


    const sendotp = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/sendotp', { email: getuser.email }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (data.data.status === "success") {
            timer();
            setpageloader(false)
        }

    }

    function timer() {
        // document.getElementById("getOtp").style.display = 'none';
        // document.getElementById("countdown").style.display = 'block';
        var timeleft = 60;
        setfield("time")
        var downloadTimer = setInterval(function () {
            if (timeleft <= 0) {
                setfield("otp")
                settime(0)
                clearInterval(downloadTimer);
                // document.getElementById("getOtp").style.display = 'block';
                // document.getElementById("countdown").style.display = 'none';
            } else {
                // document.getElementById("countdown").innerHTML = timeleft + " Seconds";
            }

            timeleft -= 1;
            settime(timeleft)
        }, 1000);
    }

    const updatepass = async () => {
        setpageloader(true)
        if (password === cpassword) {
            let data = await axios.post('https://tronapi.tronvid.site/changepass', { email: getuser.email, password: password, otp: otp }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (data.data.status === "success") {
                timer();
                               // localStorage.setItem("myuser", response.data.token);
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
                unimsg.textContent = data.data.message;
                unimodal.style.display = 'block';
                
                setTimeout(() => {
                  unimodal.style.display = 'none';
                }, 1500);
                
                
            }else{
                                // localStorage.setItem("myuser", response.data.token);
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
                unimsg.textContent = data.data.message;
                unimodal.style.display = 'block';
                
                setTimeout(() => {
                  unimodal.style.display = 'none';
                }, 1500);
            }
            setpageloader(false)
        }
        else {
            
                           // localStorage.setItem("myuser", response.data.token);
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
                unimsg.textContent = "password miss match";
                unimodal.style.display = 'block';
                
                setTimeout(() => {
                  unimodal.style.display = 'none';
                }, 1500);
           
            setpageloader(false)
        }
    }

    useEffect(() => {
        checkuser()
    }, [])

    return (
        <div>
            {pageloader && <LoadingSpinner />}
            <div style={{ width: "100%" }} className='p-3'>
                {/* <hr style={{ color: "#999" }} /> */}


                <label htmlFor="currency" className=' my-1 mt-2 ms-1'>Enter OTP</label>
                <div className="px-2 d-flex align-items-center" style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "10px", width: "100%", height: "35px" }}>
                    <img src="https://m.bousdt.org/static/login/icon_register_invitation.png" style={{ height: "25px", width: "25px" }} alt="" />
                    <input type={passwordfield} className='mx-auto' style={{ background:"none",border: "none", width: "80%" }} placeholder='Enter Email Otp' onChange={(e) => setotp(e.target.value)} />

                    {(field === "otp") && <p className='linear_gradiend_green mb-0  d-flex text-center justify-content-center align-items-center' id="getOtp" style={{ width: "60px", borderRadius: "10px", fontSize: "8px", padding: "7px", fontWeight: "600" }} onClick={sendotp}><span> Send OTP</span></p>}

                    {(field === "time") && <p className='linear_gradiend_green mb-0  d-flex text-center justify-content-center align-items-center ' id="countdown" style={{ width: "60px", borderRadius: "10px", fontSize: "8px", padding: "7px", fontWeight: "600" }} onClick={sendotp}><span>{time} Sec </span></p>}
                </div>
                <label htmlFor="currency" className=' my-1 mt-2 ms-1'>New Password</label>
                <div className="px-2 d-flex align-items-center" style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "10px", width: "100%", height: "35px" }}>
                    <img src="https://m.bousdt.org/static/login/icon_register_invitation.png" style={{ height: "25px", width: "25px" }} alt="" />
                    <input type={passwordfield} className='mx-auto' style={{ background:"none",border: "none", width: "80%" }} onChange={(e) => setpassword(e.target.value)} placeholder='Enter Password' />
                    <EyeSlash onClick={() => togglefields(passwordfield, "pass")} className="" />
                </div>

                <label htmlFor="currency" className=' my-1 mt-3 ms-1'>Confirm New Password</label>
                <div className="px-2 d-flex align-items-center" style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "10px", width: "100%", height: "35px" }}>
                    <img src="https://m.bousdt.org/static/login/icon_register_invitation.png" style={{ height: "25px", width: "25px" }} alt="" />
                    <input type={confirmpasswordfield} className='mx-auto' style={{ background:"none",border: "none", width: "80%" }} placeholder='Confirm Password' onChange={(e) => setcpassword(e.target.value)} />
                    <EyeSlash onClick={() => togglefields(confirmpasswordfield, "cpass")} className="" />
                </div>


                <p className='mx-auto my-2 linear_gradiend_green  justify-content-center d-flex align-items-center bold text-white' style={{ borderRadius: "10px", height: "35px", width: "100%" }} onClick={updatepass} >Submit</p>

                <div className='p-3 mx-2 my-3 bg-light' style={{ borderRadius: "10px", background: "white" }}>
                    <p className='text-danger'><InfoCircleFill /> TIPS:</p>
                    <p style={{ color: "#999", lineHeight: "25px" }}> 1.Withdrawal and transfer of money is prohibited within 24 hours after resetting the login password</p>


                </div>

            </div>
        </div>
    )
}

export default UpdatePassword