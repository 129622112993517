import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { EyeSlash, InfoCircleFill } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Setup = () => {
    const navigate = useNavigate();


    const [pageloader, setpageloader] = useState(true)

    const [showPopup, setShowPopup] = useState(false);
    const [showPopupname, setShowPopupname] = useState("");

    const [getuser, setgetuser] = useState()
    const [name, setname] = useState()
    const [avatarvalue, setavatarvalue] = useState()


    function maskEmail(email) {
        var atIndex = email.indexOf("@");
        if (atIndex <= 2) return email; // If the name part is too short to mask

        var maskedEmail = email[0] + email[1]; // Take the first two characters of the email
        maskedEmail += "**"; // Add the masking characters
        maskedEmail += email.slice(atIndex - 1); // Add the last character before "@" and the rest of the email

        return maskedEmail;
    }


    const togglePopup = (value) => {
        setShowPopupname(value)
        setShowPopup(!showPopup);
    };


    const checkuser = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token: getWithExpiry("myuser") }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {
            navigate("/login")
        }
        else {
            setgetuser(data.data.userdata)
            // if (data.data.userdata.w_address !== null) {
            //     setshowaddress(false)
            // }
            setavatarvalue(Number(data.data.userdata.avatar))
            setpageloader(false)
        }
    }
    const updatename = async () => {
        setpageloader(true)
        setShowPopup(!showPopup);
        let data = await axios.post('https://tronapi.tronvid.site/updatename', { token: getWithExpiry("myuser"), name: name }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {
            navigate("/login")
        }
        else {
            setgetuser(data.data.userdata)
            checkuser();
            setpageloader(false)
        }
    }


    const updateavatar = async () => {
        setpageloader(true)
        setShowPopup(!showPopup);
        let data = await axios.post('https://tronapi.tronvid.site/updateavatar', { token: getWithExpiry("myuser"), avatar: avatarvalue }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {
            navigate("/login")
        }
        else {
            setgetuser(data.data.userdata)
            checkuser();
            setpageloader(false)
        }
    }




    useEffect(() => {
        checkuser()
        // updatename()
    }, [])




    return (
        <div>
            {pageloader&&<LoadingSpinner/>}
            <div className='container-my-1   pb-3 profiletable'>

                <div className="bg-white mb-2">
                    <div className="row px-3 py-2" onClick={() => togglePopup("avatar")}>
                        <div className="col-10 header d-flex justify-content-between">
                            <p className='mb-0 d-flex align-items-center'>Avatar</p>
                            <img src="/logo/3.png" alt="" style={{ height: "40px", width: "40px" }} />

                        </div>
                        <div className="col-2  text-end  header d-flex justify-content-end align-items-center">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                </div>
                <div className="bg-white">


                    <div className="row px-3 py-2" onClick={() => togglePopup("name")}>
                        <div className="col-10 header d-flex justify-content-between">
                            <p className='mb-0'>Name</p>
                            <p className='mb-0' style={{ color: "#999" }}>{getuser?.username}</p>

                        </div>
                        <div className="col-2  text-end  header">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                    <hr className='m-0' />
                    
                    <hr className='m-0' />
                    <div className="row px-3 py-2">
                        <div className="col-10 header d-flex justify-content-between header">
                            <p className='mb-0'>Email</p>
                            <p className='mb-0' style={{ color: "#999" }}>{getuser && maskEmail(getuser?.email)}</p>

                        </div>
                        <div className="col-2  text-end  header">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                    <hr className='m-0' />
                    <div className="row px-3 py-2" >
                        <div className="col-10 header d-flex justify-content-between header">
                            <p className='mb-0'>Phone Number</p>
                            <p className='mb-0' style={{ color: "#999" }}>{getuser?.mobile}</p>
                        </div>
                        <div className="col-2  text-end  header">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                </div>
                <div className="my-2 bg-white">
                    <div className="row px-3 py-2" onClick={() => navigate("/updatewithdraw")}>
                        <div className="col-10 header">
                            <p>Withdrawal Address</p>

                        </div>
                        <div className="col-2  text-end  header">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                    <hr className='m-0' />
                    <div className="row px-3 py-2" onClick={() => navigate("/updatepass")}>
                        <div className="col-10 header">
                            <p>Change Login Password</p>
                        </div>
                        <div className="col-2  text-end  header">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                    <hr className='m-0' />
                    <div className="row px-3 py-2" onClick={() => navigate("/updatewpass")}>
                        <div className="col-10 header">
                            <p>Change Withdrawal Password</p>
                        </div>
                        <div className="col-2  text-end  header">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                </div>
                <div className="bg-white">
                    <div className="row px-3 py-2" onClick={() => alert("/redemtionexchange")}>
                        <div className="col-10 header">
                            <p>Download APP</p>
                        </div>
                        <div className="col-2  text-end  header">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                    <hr className='m-0' />
                    <div className="row px-3 py-2" onClick={() => alert("/index")}>
                        <div className="col-10 header">
                            <p>Night Mode</p>
                        </div>
                        <div className="col-2  text-end  header">
                            <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} /></div>
                    </div>
                </div>



                <div className={`popup-container withdraw  ${showPopup && 'show'}`} style={{ borderTopRightRadius: "20px", borderTopLeftRadius: "20px", background: "white", boxShadow: "rgba(0, 0, 0, 0.1) 1px -9px 19px 1px" }}>

                    {(showPopupname === "avatar") && <div style={{ width: "100%" }} className='p-3'>
                        <p className='text-center '>Choose Avatar</p>
                        <div className="d-flex mb-2 justify-content-around avtar">
                            <img src="/avatar/1.png" className={(avatarvalue === 1) &&"active"} alt="" onClick={() => setavatarvalue(1)} />
                            <img src="/avatar/2.png" className={(avatarvalue === 2) &&"active"} alt="" onClick={() => setavatarvalue(2)} />
                            <img src="/avatar/3.png" className={(avatarvalue === 3) &&"active"} alt="" onClick={() => setavatarvalue(3)} />
                            <img src="/avatar/4.png" className={(avatarvalue === 4) &&"active"} alt="" onClick={() => setavatarvalue(4)} />
                        </div>
                        <div className="d-flex justify-content-around avtar">
                            <img src="/avatar/5.png" className={(avatarvalue === 5) &&"active"} alt="" onClick={() => setavatarvalue(5)} />
                            <img src="/avatar/6.png" className={(avatarvalue === 6) &&"active"} alt="" onClick={() => setavatarvalue(6)} />
                            <img src="/avatar/7.png" className={(avatarvalue === 7) &&"active"} alt="" onClick={() => setavatarvalue(7)} />
                            <img src="/avatar/8.png" className={(avatarvalue === 8) &&"active"} alt="" onClick={() => setavatarvalue(8)} />
                        </div>
                        <p className='mx-auto my-3 linear_gradiend_green  justify-content-center d-flex align-items-center bold ' style={{ borderRadius: "10px", height: "35px", width: "100%" }} onClick={updateavatar} >Save</p>
                    </div>}


                    {(showPopupname === "name") && <div style={{ width: "100%" }} className='p-3'>
                        <p className='text-center'>Edit Name</p>

                        <label htmlFor="currency" className=' mb-2 mt-2 ms-1'>Change Name </label>
                        <div className="px-2 d-flex align-items-center" style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "10px", width: "100%", height: "35px" }}>
                            <img src="/edit-name.svg" style={{ height: "25px", width: "25px" }} alt="" />
                            <input type="text" className='mx-auto' style={{ border: "none", width: "80%" }} onChange={(e) => setname(e.target.value)} placeholder='Enter Name' />
                        </div>
                        <p className='mx-auto my-2 linear_gradiend_green  justify-content-center d-flex align-items-center bold text-white' style={{ borderRadius: "10px", height: "35px", width: "100%" }} onClick={updatename} >Save</p>
                    </div>}

                    {(showPopupname === "withdrawaladdress") && <div style={{ width: "100%" }} className='p-3'>
                        <p className='text-center text-white'>Modify Withdrawal Address</p>
                        <p className='text-center text-white'>Increase Withdrawal Address</p>
                        <hr style={{ color: "#999" }} />

                        <p className='mx-auto my-2 text-white justify-content-center d-flex align-items-center' style={{ borderRadius: "10px", height: "35px", width: "100%", background: "#ff2d55" }} >+ Add Withdrawal Address</p>

                        <label htmlFor="currency" className='text-white my-1 mt-2 ms-2'>Currency</label>
                        <div className="px-2 d-flex align-items-center" style={{ background: "#999", borderRadius: "10px", width: "100%", height: "35px" }}>
                            <input type="text" className='mx-auto px-3' style={{ borderRadius: "10px", background: "#999", border: "none", width: "80%", color: "white", }} placeholder='TRC20' />
                            <p className='mb-0 d-flex'>
                                <img className='mx-1' src="https://m.bousdt.org/static/usdt.png" style={{ width: "20px", width: "20px" }} alt="" />
                                USDT
                            </p>
                        </div>
                        <label htmlFor="Withdrawal" className='text-white my-1 mt-2 ms-2'>Withdrawal address</label>
                        <input type="text" className='mx-auto px-3' style={{ borderRadius: "10px", background: "#999", border: "none", width: "100%", color: "white", height: "35px" }} placeholder='Please Enter USDT wallet address' />
                        <label htmlFor="Remark" className='text-white my-1 mt-2 ms-2'>Remark</label>
                        <input type="text" className='mx-auto px-3' style={{ borderRadius: "10px", background: "#999", border: "none", width: "100%", color: "white", height: "35px" }} placeholder='Please enter a note' />
                        <p className='mx-auto my-2 text-white justify-content-center d-flex align-items-center' style={{ borderRadius: "10px", height: "35px", width: "100%", background: "#ff2d55" }} >Save</p>

                    </div>}



                </div>






            </div>
        </div>
    )
}

export default Setup