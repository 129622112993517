import React from 'react'

const Referallevesls = () => {
    return (
        <div className='bg-white' style={{ minHeight: "80vh" }}>
            <div className="d-flex justify-content-between mx-auto container py-3" style={{ maxWidth: "200px" }}>
                <p>LV1</p>
                <p>LV2</p>
                <p>LV3</p>
            </div>
            <div className="d-flex justify-content-center">
                <div className='text-center my-3 levelbox mx-2'>
                    <p className='m-0'>Commission</p>
                    <p className='m-0'>0</p>
                </div>
                <div className='text-center my-3 levelbox mx-2'>
                    <p className='m-0'>Residual</p>
                    <p className='m-0'>0</p>
                </div>

            </div>
            <hr />
            <p className='text-center'>Data will be refrehed again in</p>
            <table className="table text-center">
                <thead >
                    <tr>
                        <th scope="col" className='bg-light'>User ID</th>
                        <th scope="col" className='bg-light'>Email</th>
                        <th scope="col" className='bg-light'>Joined at</th>
                    </tr>
                </thead>
                <tbody>
                    {/* <tr>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </tr> */}

                </tbody>
            </table>
            <p className='text-center'>No Record Found</p>

        </div>
    )
}

export default Referallevesls