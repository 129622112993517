import React, { useState } from 'react'
import { ButtonGroup, Dropdown, DropdownButton, Modal } from 'react-bootstrap'
import { ArrowLeft, Calendar2Event, Translate, X } from 'react-bootstrap-icons'
import { useLocation, useNavigate } from 'react-router-dom'

const Nav = () => {
    const navigate = useNavigate();
    const location = useLocation()


    const [showModal, setShowModal] = useState(false);
    const [animateOut, setAnimateOut] = useState(false);
    const handleClose = () => {
        setAnimateOut(true);
        setTimeout(() => setShowModal(false), 300); // Match this duration with the animation duration
    };
    const handleShow = () => {
        setAnimateOut(false);
        setShowModal(true);
    };

    const indexpaths = ["/eth1", "/eth5", "/eth15", "/btc1", "/btc5", "/btc15", "/gamesection"];
    const gameethpaths = ["/eth1", "/eth5", "/eth15"];
    const gamebtcpaths = ["/btc1", "/btc5", "/btc15"];

    const handleBackClick = () => {

        if (indexpaths.includes(location.pathname)) {
            navigate("/index")
        }
        else {
            navigate(-1);
        }
    };

    const background = (location.pathname == "/mining") ? "white" : "white";

    return (

        <div className="shadow-sm position-sticky sticky-top" style={{ backgroundColor: "#1b434c",color:"white" }}>
            <div className='d-flex justify-content-between align-items-center  p-2'>
                <div className="" style={{ width: "20%" }}>{(location.pathname === "/index") ? <img style={{ height: "30px", width: "30px" }} src="/logo/3.png" alt="" /> :
                    <ArrowLeft onClick={handleBackClick} />

                }</div>
                <div className=""></div>
                <div className="text-center d-flex align-items-center justify-content-center" style={{ width: "60%" }}>


                    {(location.pathname == "/index" || location.pathname == "/") && <span>BBCUSDT</span>}
                    {(location.pathname == "/raferalpage") && <span>Referal Link</span>}
                    {(location.pathname == "/updatepass") && <span>Update Login Password</span>}
                    {(location.pathname == "/updatewpass") && <span>Update Widthdraw Password</span>}

                    {(location.pathname == "/mining") && <span>Mining</span>}

                    {(location.pathname == "/currencyexchange") && <span>Exchange</span>}

                    {(location.pathname == "/eth1") && <span>ETH 1 MIN</span>}
                    {(location.pathname == "/eth5") && <span>ETH 5 MIN</span>}
                    {(location.pathname == "/eth15") && <span>ETH 15 MIN</span>}

                    {(location.pathname == "/btc1") && <span>BTC 1 MIN</span>}
                    {(location.pathname == "/btc5") && <span>BTC 5 MIN</span>}
                    {(location.pathname == "/btc15") && <span>BTC 15 MIN</span>}
                    {(location.pathname == "/gifthistory") && <span>Gift Record</span>}
                    {(location.pathname == "/user/alltransaction") && <span>Transaction</span>}



                    {(location.pathname == "/depositpage" || location.pathname == "/deposittrx" || location.pathname == "/depositusdt") && <span>Recharge</span>}

                    {(location.pathname == "/depositrecord") && <span>Record</span>}

                    {(location.pathname == "/withdraw") && <span>Withdraw</span>}

                    {(location.pathname == "/withdrawrecord") && <span>Withdrawals record</span>}
                    {(location.pathname == "/withdrawalrecorddetail") && <span>Withdrawals details</span>}
                    {(location.pathname == "/user") && <span>MENU</span>}
                    {(location.pathname == "/setup") && <span>Set up</span>}
                    {(location.pathname == "/activity") && <span>Activity</span>}
                    {(location.pathname == "/activityaward") && <span>Activity Award</span>}
                    {(location.pathname == "/rebate") && <span>Team</span>}
                    {(location.pathname == "/redemtionsystem") && <span>Redemption System</span>}
                    {(location.pathname == "/redemtionexchange") && <span>Claim Your Reward</span>}
                    {(location.pathname == "/gamesection") && <span>Games</span>}
                    {(location.pathname == "/updatewithdraw") && <span>Withdrawal Address</span>}

                    {(location.pathname == "/activitydetail") && <span>Activity</span>}

                    {(location.pathname == "/teamtodaycommission") && <span>Team commission</span>}
                    {(location.pathname == "/team" || location.pathname == "/teamhistory" || location.pathname == "/teamtoday") && <span>Team Statistics</span>}

                </div>
                <div className="navbutton d-flex justify-content-end" style={{ width: "20%" }}>
                    {
                        // (location.pathname === "/index") && 

                        // <DropdownButton
                        //     className='ms-auto '
                        //     as={ButtonGroup}
                        //     key={"info"}
                        //     id={`dropdown`}
                        //     // info={"info".toLowerCase()}
                        //     title={<Translate />}
                        // >
                        //     <Dropdown.Item eventKey="1">English</Dropdown.Item>
                        //     <Dropdown.Item eventKey="2">Chinese</Dropdown.Item>
                        //     <Dropdown.Item eventKey="3">Arabic</Dropdown.Item>
                        //     <Dropdown.Item eventKey="3">Russian</Dropdown.Item>
                        // </DropdownButton>
                    }


                    {(location.pathname === "/withdraw") && <span onClick={() => navigate("/withdrawrecord")}><Calendar2Event className='mx-2' /></span>}
                    {(location.pathname == "/depositpage" || location.pathname == "/deposittrx" || location.pathname == "/depositusdt") && <span onClick={() => navigate("/depositrecord")}><Calendar2Event className='mx-2' /></span>}
                    {(location.pathname == "/mining") && <span onClick={handleShow}>Rule</span>}


                    {
                        // (gamebtcpaths.includes(location.pathname)) && <span onClick={handleShow}>Rule</span>

                        // }
                        // {
                        // (gameethpaths.includes(location.pathname)) && <span onClick={handleShow}>Rule</span>

                    }





                    <div className='d-none justify-content-center align-item-center' style={{ width: "100%" }} >
                        <Modal
                            show={showModal}
                            onHide={handleClose}
                            centered
                            size="sm"
                            className={`d-flex justify-content-center ${animateOut ? 'modal-zoom-out' : 'modal-zoom-in'}`}
                            style={{ width: "100%" }}
                        >

                            <Modal.Body style={{ width: "100%", backgroundImage: "url(	http://localhost:12078/static/media/bg.2c72052c.437d09c6a2be5652fb5e.png)", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "cover", borderRadius: "5px" }}>
                                <div className="" style={{ width: "100%" }}>
                                    <div className="d-flex">
                                        <p className="mx-auto text-white mb-3" style={{ width: "280px", fontSize: "18px" }}>Daily mining rules</p>
                                        <X className="text-white" onClick={handleClose} />
                                    </div>
                                    <p className="mx-auto text-white" style={{ width: "280px", fontSize: "14px", height: "400px", overflow: "scroll" }}>In the blockchain network, all transfer information is kept by miners. Miners collect user-initiated transactions, then package them into blocks and splice them into a blockchain. The mining process is the process of maintaining the operation of the blockchain network. In order to reward miners for their contribution to maintaining the operation of the blockchain network, the blockchain system uses Tokens to reward miners. Therefore, all mainstream currencies such as BTC, ETH, LTC, ZCASH, DASH, and XMR circulating in the market are produced through mining, except for some special contracts. In other words, miners become producers of digital currency by maintaining the blockchain network. The mined mine is the digital currency, and the mining machine is the money printing machine for the digital currency. Compared with the ups and downs of the digital currency trading market, mining is a relatively safe investment method. With the increasing popularity of digital currency mining, some projects using "mobile mining" activities have gradually begun to attract the attention of all parties. Compared with traditional mining machine mining, mobile mining only needs to download a BU App or open the BU website, and you can obtain "computing power" through login or authorization, and you can obtain the specified digital currency through mining.</p>
                                </div>

                            </Modal.Body>

                        </Modal>
                    </div>




                </div>
            </div>
        </div>
    )
}

export default Nav