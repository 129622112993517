import axios from 'axios';
import { color } from 'chart.js/helpers';
import React, { useEffect, useState } from 'react'
import { Copy } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Loader/LoadingSpinner';
import { getWithExpiry } from '../JS/JS';

const Updatewithdrawaladdress = () => {

    const [showaddress, setshowaddress] = useState(true)
    const [showaddressadd, setshowaddressadd] = useState(true)
    const [withadd, setswithadd] = useState(0)
    
    const [waddress, setwaddress] = useState("")
    const [passwordfield, setpasswordfield] = useState("password");
    const [field, setfield] = useState("otp");
    const [time, settime] = useState()
    const [otp, setotp] = useState()
    const [getuser, setgetuser] = useState()
    const [pageloader, setpageloader] = useState(false)

    const navigate = useNavigate();


    function timer() {
        // document.getElementById("getOtp").style.display = 'none';
        // document.getElementById("countdown").style.display = 'block';
        var timeleft = 60;
        setfield("time")
        var downloadTimer = setInterval(function () {
            if (timeleft <= 0) {
                setfield("otp")
                settime(0)
                clearInterval(downloadTimer);
                // document.getElementById("getOtp").style.display = 'block';
                // document.getElementById("countdown").style.display = 'none';
            } else {
                // document.getElementById("countdown").innerHTML = timeleft + " Seconds";
            }

            timeleft -= 1;
            settime(timeleft)
        }, 1000);
    }




    const checkuser = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token: getWithExpiry("myuser") }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {
            navigate("/login")
        }
        else {
            setgetuser(data.data.userdata)
            if (data.data.userdata.w_address === 0) {
                setshowaddress(true)
                
            }else{
                setswithadd(data.data.userdata.w_address)
            }
            setpageloader(false)
        }
    }

    const sendotp = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/sendotp', { email: getuser?.email }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "success") {
            timer();
                           // localStorage.setItem("myuser", response.data.token);
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
                unimsg.textContent = data.data.message;
                unimodal.style.display = 'block';
                
                setTimeout(() => {
                  unimodal.style.display = 'none';
                }, 1500);
                
                
            setpageloader(false)
        }
    }
    const addwallet = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/addwallet', { token: getWithExpiry("myuser"), address: waddress, otp: otp }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        setpageloader(false)
            if (data.data.status === 'success') {
                
                
               
                // localStorage.setItem("myuser", response.data.token);
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
                unimsg.textContent = data.data.message;
                unimodal.style.display = 'block';
                
                setTimeout(() => {
                  unimodal.style.display = 'none';
                  navigate("/user")
                }, 1500);
                
                

            } else {
                
                
                
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
                unimsg.textContent = data.data.message;
                unimodal.style.display = 'block';
                
                setTimeout(() => {
                  unimodal.style.display = 'none';
                }, 1500);
                
            }
        
    }

    useEffect(() => {
        checkuser()
    }, [])


    return (
        <div>
            {pageloader && <LoadingSpinner />}
            <div style={{ width: "100%" }} className='p-3'>
                {/* <p className='text-center '>Modify Withdrawal Address</p> */}
                {/* <p className='text-center '>Increase Withdrawal Address</p> */}
                {/* <hr style={{ color: "#999" }} /> */}

                {showaddress && showaddressadd && (withadd == 0 ) && <p  className='mx-auto my-2  justify-content-center d-flex align-items-center linear_gradiend_green' style={{ borderRadius: "5px", height: "35px", width: "100%" }} onClick={() => setshowaddressadd(false)}>+ Add Withdrawal Address</p>}
                
                
                
                
                
                
                



                {(!showaddressadd) && 
                
                <div>

                    <label htmlFor="currency" className=' my-1 mt-2 ms-1'>Enter OTP</label>
                    <div className="px-2 d-flex align-items-center" style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "5px", width: "100%", height: "35px" }}>
                        <img src="https://img.icons8.com/external-anggara-flat-anggara-putra/32/external-password-business-and-finance-anggara-flat-anggara-putra.png" style={{ height: "25px", width: "25px" }} alt="" />
                        <input type={passwordfield} className='mx-auto' style={{ border: "none", width: "80%" }} placeholder='Enter Email Otp'  onChange={(e)=>setotp(e.target.value)} />

                        {(field === "otp") && <p className='linear_gradiend_green mb-0  d-flex text-center justify-content-center align-items-center' id="getOtp" style={{ width: "60px", borderRadius: "5px", fontSize: "8px", padding: "7px", fontWeight: "600" }} onClick={sendotp}><span> Send OTP</span></p>}

                        {(field === "time") && <p className='linear_gradiend_green mb-0  d-flex text-center justify-content-center align-items-center ' id="countdown" style={{ width: "60px", borderRadius: "5px", fontSize: "8px", padding: "7px", fontWeight: "600" }} onClick={sendotp}><span>{time} Seconds </span></p>}
                    </div>


                    <label htmlFor="currency" className=' my-1 mt-2 ms-2'>Currency</label>
                        <div className="px-2 d-flex align-items-center" style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "5px", width: "100%", height: "35px" }}>
                        
                        <img className='mx-1' src="https://img.icons8.com/external-tal-revivo-light-tal-revivo/24/external-large-peer-to-peer-blockchain-and-cryptocurrency-network-crypto-light-tal-revivo.png" style={{ height: "20px", width: "20px" }} alt="" />
                        
                            <input className='mx-auto px-3' style={{ borderRadius: "5px", border: "none", width: "80%" }} disabled placeholder='TRC20'
                                
                            />
                        </div>

                    <label htmlFor="Withdrawal" className=' my-1 mt-2 ms-2'>Withdrawal address</label>
                    <input type="text" className='mx-auto px-3' style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "5px", border: "none", width: "100%", height: "35px" }} placeholder='Please Enter  wallet address' onChange={(e)=>setwaddress(e.target.value)}/>






                    <label htmlFor="Remark" className=' my-1 mt-2 ms-2'>Remark</label>
                    <input type="text" className='mx-auto px-3' style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "5px", border: "none", width: "100%", height: "35px" }} placeholder='Please enter a note' />
                    <p className='mx-auto my-2  justify-content-center d-flex align-items-center linear_gradiend_green' style={{ borderRadius: "5px", height: "35px", width: "100%" }} onClick={addwallet} >Save</p>
                </div>
                    
                    
                    
                }
                
                
                
                
                
                
                
                
                

                {(withadd != 0 ) && (showaddress) && <div>
                    <label htmlFor="Withdrawal" className=' my-1 my-2 ms-1 bolder'>Withdrawal address</label>
                    <div className="px-2 d-flex align-items-center" style={{ boxShadow: "1px 0px 3px 0px #00000052", borderRadius: "5px", width: "100%", height: "35px" }}>
                        <img src="https://m.bousdt.org/static/login/icon_register_invitation.png" style={{ height: "25px", width: "25px" }} alt="" />
                        <input type="text" defaultValue={getuser?.w_address} disabled className='mx-auto' style={{ border: "none", width: "80%", background: "transparent", color: "black" }} placeholder='TRC20' />
                        <Copy style={{ color: "#27aa6d" }} />
                    </div>
                </div>}

            </div>
        </div >
    )
}

export default Updatewithdrawaladdress