import { useEffect, useState } from 'react';
import { Megaphone } from 'react-bootstrap-icons';
import Carousel from 'react-bootstrap/Carousel';
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from './JS/JS';
import axios from 'axios';
// import ExampleCarouselImage from 'components/ExampleCarouselImage';

function Carouselbanner() {
  const navigate = useNavigate();

  const texts = [
    "User ****: Won 1201.25 USDT",
    "User ****: Withdrawal 1899.70 TRX",
    "User ****: Exchange 2678.45 USDT",
    "User ****: Deposit 1456.32 TRX",
    "User ****: Won 1824.09 TRX",
    "User ****: Withdrawal 2534.64 USDT",
    "User ****: Exchange 1932.32 USDT",
    "User ****: Deposit 854.63 USDT",
    "User ****: Won 2743.65 TRX",
    "User ****: Withdrawal 1753.99 USDT",
    "User ****: Exchange 564.78 USDT",
    "User ****: Deposit 1234.44 TRX",
    "User ****: Won 987.45 USDT",
    "User ****: Withdrawal 1356.78 TRX",
    "User ****: Exchange 2394.21 USDT",
    "User ****: Deposit 1542.67 TRX",
    "User ****: Won 1876.89 USDT",
    "User ****: Withdrawal 986.54 TRX",
    "User ****: Exchange 1739.24 USDT",
    "User ****: Deposit 652.34 TRX",
    "User ****: Won 1267.56 USDT",
    "User ****: Withdrawal 789.01 TRX",
    "User ****: Exchange 2142.34 USDT",
    "User ****: Deposit 2943.89 TRX",
    "User ****: Won 1432.45 USDT",
    "User ****: Withdrawal 2876.89 TRX",
    "User ****: Exchange 891.23 USDT",
    "User ****: Deposit 2492.10 TRX",
    "User ****: Won 2134.56 USDT",
    "User ****: Withdrawal 654.32 TRX",
    "User ****: Exchange 1989.01 USDT",
    "User ****: Deposit 1876.54 TRX",
    "User ****: Won 1245.67 USDT",
    "User ****: Withdrawal 2345.78 TRX",
    "User ****: Exchange 1678.90 USDT",
    "User ****: Deposit 2377.65 TRX",
    "User ****: Won 1435.67 USDT",
    "User ****: Withdrawal 2643.21 TRX",
    "User ****: Exchange 234.56 USDT",
    "User ****: Deposit 1790.12 TRX",
    "User ****: Won 1956.78 USDT",
    "User ****: Withdrawal 2734.89 TRX",
    "User ****: Exchange 1546.78 USDT",
    "User ****: Deposit 2789.01 TRX",
    "User ****: Won 345.67 USDT",
    "User ****: Withdrawal 1901.23 TRX",
    "User ****: Exchange 234.78 USDT",
    "User ****: Deposit 1677.89 TRX",
    "User ****: Won 1789.12 USDT",
    "User ****: Withdrawal 2456.12 TRX",
    "User ****: Exchange 678.23 USDT",
    "User ****: Deposit 1574.89 TRX",
    "User ****: Won 345.90 USDT",
    "User ****: Withdrawal 2834.78 TRX",
    "User ****: Exchange 1567.89 USDT",
    "User ****: Deposit 2767.34 TRX",
    "User ****: Won 2345.90 USDT",
    "User ****: Withdrawal 1790.34 TRX",
    "User ****: Exchange 678.56 USDT",
    "User ****: Deposit 2234.45 TRX",
    "User ****: Won 1578.34 USDT",
    "User ****: Withdrawal 2546.78 TRX",
    "User ****: Exchange 2678.90 USDT",
    "User ****: Deposit 2345.67 TRX",
    "User ****: Won 2234.56 USDT",
    "User ****: Withdrawal 1567.90 TRX",
    "User ****: Exchange 1245.12 USDT",
    "User ****: Deposit 1987.34 TRX",
    "User ****: Won 223.45 USDT",
    "User ****: Withdrawal 1789.34 TRX",
    "User ****: Exchange 2345.78 USDT",
    "User ****: Deposit 2687.12 TRX",
    "User ****: Won 1745.89 USDT",
    "User ****: Withdrawal 2678.56 TRX",
    "User ****: Exchange 1956.34 USDT",
    "User ****: Deposit 2890.78 TRX",
    "User ****: Won 223.78 USDT",
    "User ****: Withdrawal 1456.12 TRX",
    "User ****: Exchange 2345.34 USDT",
    "User ****: Deposit 2789.78 TRX",
    "User ****: Won 2234.56 USDT",
    "User ****: Withdrawal 1578.90 TRX",
    "User ****: Exchange 2546.78 USDT",
    "User ****: Deposit 2890.12 TRX",
    "User ****: Won 1783.45 USDT",
    "User ****: Withdrawal 2567.34 TRX",
    "User ****: Exchange 2345.78 USDT",
    "User ****: Deposit 2789.01 TRX",
    "User ****: Won 2234.89 USDT",
    "User ****: Withdrawal 2578.12 TRX",
    "User ****: Exchange 2656.90 USDT",
    "User ****: Deposit 2890.34 TRX",
    "User ****: Won 123.56 USDT",
    "User ****: Withdrawal 2767.89 TRX",
    "User ****: Exchange 2345.67 USDT",
    "User ****: Deposit 2789.34 TRX",
    "User ****: Won 2234.12 USDT",
    "User ****: Withdrawal 2578.56 TRX"
  ];



  function getRandomText() {
    const randomIndex = Math.floor(Math.random() * texts.length);
    setrandomText(texts[randomIndex])
    setTimeout(() => {
      getRandomText()
    }, 2500);
  }
  const [randomText, setrandomText] = useState()

  const [teamsize, setteamsize] = useState(0);


  const fetchlevels = async () => {
    try {
      const token = getWithExpiry("myuser");
      let response = await axios.post('https://tronapi.tronvid.site/levelmember', { token }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.data.status) {
        setteamsize(response.data.level1.length + response.data.level2.length + response.data.level3.length + response.data.level4.length);
      }
    } catch (error) {
      console.error('Error fetching levels:', error);
    }
  }


  useEffect(() => {
    getRandomText();
    fetchlevels();
  }, [])


  // setInterval(getRandomText, 2500);
  //   // setInterval(() => {
  //   //   getRandomText(texts);
  //   //   console.log("====")
  //   // }, 2550);

  const img_circle = { height: "50px", width: "50px", borderRadius: "50%", background: "#d6cccc4a" }
  const img_circle_img = { height: "45px", width: "45px" }
  const team_card = { borderRadius: "10px", background: "#d6cccc4a", width: "45%", padding: "10px" }
  const team_card1 = { width: "50%" }
  const team_card3 = { borderRadius: "10px", background: "#d6cccc4a", width: "100%", padding: "10px" }
  const team_card2 = { borderRadius: "10px", width: "40%", height: "150px" }

  const team_card_img = { height: "50px", width: "50px" }

  return (
    <>
      <Carousel fade style={{ margin: "0px auto", borderRadius: "5px", width: "100%", overflow: "hidden" }}>
        <Carousel.Item>
          <img className="mx-auto" style={{ height: "150px", width: "100%" }} src="/posters/5.png" alt="" />

        </Carousel.Item>
        <Carousel.Item>
          <img className="mx-auto" style={{ height: "150px", width: "100%" }} src="/posters/1.png" alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="mx-auto" style={{ height: "150px", width: "100%" }} src="/posters/2.png" alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="mx-auto" style={{ height: "150px", width: "100%" }} src="/posters/3.png" alt="" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="mx-auto" style={{ height: "150px", width: "100%" }} src="/posters/4.png" alt="" />
        </Carousel.Item>
      </Carousel>


      <div className='d-flex justify-content-center' style={{ position: "relative", height: "70px", marginTop: "-5px" }}>
        <span style={{ boxShadow: "rgb(116 198 164 / 57%) 0px 7px 29px 0px", border: "none", textOverflow: "hiden", backgroundColor: "white", width: "95%", position: "absolute", top: "5px", borderRadius: "35px", zIndex: "2", height: "50px", fontSize: "12px" }} className='py-2 px-3 mx-auto' type="text" ><Megaphone className='me-3 ms-1' style={{ marginTop: "8px", fontSize: "18px" }} />
          <span style={{ position: "absolute", height: "35px", overflow: "hidden", width: "80%" }} className='vertical_marque' >Lorem ipsum dolor sit amet consectetur adipisicing elit. Aperiam excepturi exercitationem porro blanditiis, ipsam fugit consequatur quod facilis quidem velit? Cum impedit corrupti magni dicta, fugiat voluptate distinctio nam maxime.</span>
          {/* <span style={{ position: "absolute" }} className='vertical_marque'>{randomText}</span> */}
        </span>
      </div>



   

     





      <div className="d-flex mb-4" style={{boxShadow:"rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",padding:"10px 0"}}>
        <div className="col-3 justify-content-center align-items-center d-flex flex-column">

          <div className='d-flex justify-content-center align-items-center' style={img_circle}>
            <img style={img_circle_img} src="/assets/newicons/icons8-calculator-100.png" alt="" />
          </div>
          <p className='mb-0 mt-1' style={{ fontSize: "14px", fontWeight: "600" }}>Calculator</p>
        </div>
        <div className="col-3 justify-content-center align-items-center d-flex flex-column" onClick={() => navigate("/promotion")}>

          <div className='d-flex justify-content-center align-items-center' style={img_circle}>
            <img style={img_circle_img} src="https://cdn3d.iconscout.com/3d/premium/thumb/referral-bonus-3d-icon-download-in-png-blend-fbx-gltf-file-formats--program-invite-friend-customer-job-benefits-perks-pack-business-icons-6741761.png?f=webp" alt="" />

          </div>
          <p className='mb-0 mt-1' style={{ fontSize: "14px", fontWeight: "600" }}>Invite</p>
        </div>
        <div className="col-3 justify-content-center align-items-center d-flex flex-column" onClick={() => navigate("/currencyexchange")}>

          <div className='d-flex justify-content-center align-items-center' style={img_circle}>
            <img style={img_circle_img} src="/assets/newicons/icons8-currency-exchange-100.png" alt="" />

          </div>
          <p className='mb-0 mt-1' style={{ fontSize: "14px", fontWeight: "600" }}>Exchange</p>
        </div>
        <div className="col-3 justify-content-center align-items-center d-flex flex-column" onClick={() => navigate("/activity")}>

          <div className='d-flex justify-content-center align-items-center' style={img_circle}>
            <img style={img_circle_img} src="https://cdn3d.iconscout.com/3d/premium/thumb/activity-checklist-3d-icon-download-in-png-blend-fbx-gltf-file-formats--list-document-report-task-travel-and-tourism-pack-holidays-icons-8973815.png?f=webp" alt="" />

          </div>
          <p className='mb-0 mt-1' style={{ fontSize: "14px", fontWeight: "600" }}>Activity</p>
        </div>
      </div>



     

     <div className="top-trade-container">
      <h2 className="heading">Top Trade Data</h2>
      <div className="trade-cards">
        <div
          className="trade-card"
          onClick={() => navigate("/btc1")}
        >
          <p className="card-title">BTC Game</p>
        </div>
        <div
          className="trade-carde"
          onClick={() => navigate("/eth1")}
        >
          <p className="card-title">ETH Game</p>
        </div>
      </div>
      
      
 <h2 className="heading">Hot Games</h2>     
    <div className="swipe-item">
  <div className="game-info">
    <div className="game-image">
      <img src="https://www.betcasn.com/images/games/game2_bg.webp" alt="Game Background" className="background-img" />
    </div>
    <div className="game-description">
      <h3 className="game-title">Three Crazy Piggies</h3>
      <p className="game-text">
        Deep in the Everloom Forest, a legend tells of a fierce and cunning gray wolf that preys on weak animals. To avoid becoming the wolf's next meal, the three little pig brothers decided to build houses. The eldest, impatient, quickly erected a straw house. The cautious second brother used recycled timber to build a wooden house. The prudent youngest brother constructed a sturdy stone house with riverbed stones and mud.
      </p>
    </div>
  </div>
  <div className="game-info">
    <div className="game-image">
      <img src="https://www.betcasn.com/images/games/game3_bg.webp" alt="Game Background" className="background-img" />
    </div>
    <div className="game-description">
      <h3 className="game-title">Wings of Iguazu</h3>
      <p className="game-text">
        Deep in the Iguazu Falls, a nearly extinct species of precious bird hides—the Blue Macaw. These parrots possess dazzling, radiant blue feathers, like gems in the deep sea, shimmering and shining. This rare creature only appears once every few centuries and is considered a sign of good luck
      </p>
    </div>
  </div>
  <div className="game-info">
    <div className="game-image">
      <img src="https://www.betcasn.com/images/games/game4_bg.webp" alt="Game Background" className="background-img" />
    </div>
    <div className="game-description">
      <h3 className="game-title">Yakuza Honor</h3>
      <p className="game-text">
        In the bustling streets of Tokyo, two rival yakuza organizations are engaged in a fierce conflict. The Azure Dragon Gang is an established yakuza group that upholds honor and tradition, are currently locked in a standoff with the ambitious upstart gang, Night Wolves. This strife stems from the battle for control over Tokyo's underground world and its immense wealth. Both leaders vow to   protect their territory from any encroachment.
      </p>
    </div>
  </div>
</div>




 <h2 className="heading">Featured Games</h2>     
    <div className="swipe-item">
  <div className="game-info">
    <div className="game-image">
      <img src="https://www.betcasn.com/images/games/game8_bg.jpg" alt="Game Background" className="background-img" />
    </div>
    <div className="game-description">
      <h3 className="game-title">Zombie Outbreak</h3>
      <p className="game-text">
        The peaceful life in a town named Dark Valley has been shattered by an unexpected disaster. A mysterious virus has been spread across the entire town in the night, turning people into terrifying zombies. Overnight, the once serene town has become a place of silence and fear.
      </p>
    </div>
  </div>
  <div className="game-info">
    <div className="game-image">
      <img src="https://www.betcasn.com/images/games/game9_bg.jpg" alt="Game Background" className="background-img" />
    </div>
    <div className="game-description">
      <h3 className="game-title">Anubis Wrath</h3>
      <p className="game-text">
        In a mysterious grand hall of ancient Egypt, the wrath of Anubis burns. He witnesses the wrongdoings of humanity and the chaos of the world, determined to carry out a stern judgement to uncover and rectify those lost souls.
      </p>
    </div>
  </div>
  <div className="game-info">
    <div className="game-image">
      <img src="https://www.betcasn.com/images/games/game6_bg.webp" alt="Game Background" className="background-img" />
    </div>
    <div className="game-description">
      <h3 className="game-title">Futebol Fever</h3>
      <p className="game-text">
        Football superstar Carlos Bol rose to the global stage from the small village of Falcon with a selection match. As an appreciation to the football community, he founded the "Future Star Challenge" at the peak of his career, aiming to nurture young football talents with similar backgrounds.
      </p>
    </div>
  </div>
</div>




    </div>

    </>
  );
}

export default Carouselbanner;