// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.loader {
    width: 40px;
    height: 20px;
    --c:no-repeat radial-gradient(farthest-side,#27aa6d 93%,#d4f8e7);


    background:
      var(--c) 0    0,
      var(--c) 50%  0,
      var(--c) 100% 0;
    background-size: 8px 8px;
    position: relative;
    animation: l4-0 1s linear infinite alternate;
  }
  .loader:before {
    content: "";
    position: absolute;
    width: 8px;
    height: 12px;
    background: #74dbab;
    left: 0;
    top: 0;
    animation: 
      l4-1 1s  linear infinite alternate,
      l4-2 0.5s cubic-bezier(0,200,.8,200) infinite;
  }
  @keyframes l4-0 {
    0%      {background-position: 0  100%,50% 0   ,100% 0}
    8%,42%  {background-position: 0  0   ,50% 0   ,100% 0}
    50%     {background-position: 0  0   ,50% 100%,100% 0}
    58%,92% {background-position: 0  0   ,50% 0   ,100% 0}
    100%    {background-position: 0  0   ,50% 0   ,100% 100%}
  }
  @keyframes l4-1 {
    100% {left:calc(100% - 8px)}
  }
  @keyframes l4-2 {
    100% {top:-0.1px}
  }


`, "",{"version":3,"sources":["webpack://./src/components/Loader/apiloader.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,WAAW;IACX,YAAY;IACZ,gEAAgE;;;IAGhE;;;qBAGiB;IACjB,wBAAwB;IACxB,kBAAkB;IAClB,4CAA4C;EAC9C;EACA;IACE,WAAW;IACX,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,mBAAmB;IACnB,OAAO;IACP,MAAM;IACN;;mDAE+C;EACjD;EACA;IACE,SAAS,4CAA4C;IACrD,SAAS,4CAA4C;IACrD,SAAS,4CAA4C;IACrD,SAAS,4CAA4C;IACrD,SAAS,+CAA+C;EAC1D;EACA;IACE,MAAM,qBAAqB;EAC7B;EACA;IACE,MAAM,UAAU;EAClB","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\r\n.loader {\r\n    width: 40px;\r\n    height: 20px;\r\n    --c:no-repeat radial-gradient(farthest-side,#27aa6d 93%,#d4f8e7);\r\n\r\n\r\n    background:\r\n      var(--c) 0    0,\r\n      var(--c) 50%  0,\r\n      var(--c) 100% 0;\r\n    background-size: 8px 8px;\r\n    position: relative;\r\n    animation: l4-0 1s linear infinite alternate;\r\n  }\r\n  .loader:before {\r\n    content: \"\";\r\n    position: absolute;\r\n    width: 8px;\r\n    height: 12px;\r\n    background: #74dbab;\r\n    left: 0;\r\n    top: 0;\r\n    animation: \r\n      l4-1 1s  linear infinite alternate,\r\n      l4-2 0.5s cubic-bezier(0,200,.8,200) infinite;\r\n  }\r\n  @keyframes l4-0 {\r\n    0%      {background-position: 0  100%,50% 0   ,100% 0}\r\n    8%,42%  {background-position: 0  0   ,50% 0   ,100% 0}\r\n    50%     {background-position: 0  0   ,50% 100%,100% 0}\r\n    58%,92% {background-position: 0  0   ,50% 0   ,100% 0}\r\n    100%    {background-position: 0  0   ,50% 0   ,100% 100%}\r\n  }\r\n  @keyframes l4-1 {\r\n    100% {left:calc(100% - 8px)}\r\n  }\r\n  @keyframes l4-2 {\r\n    100% {top:-0.1px}\r\n  }\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
