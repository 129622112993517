import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { ArrowLeft, Calendar, Calendar2, Link45deg } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../../JS/JS';
import LoadingSpinner from '../../Loader/LoadingSpinner'


// eslint-disable-next-line no-undef
const Tradepage15min = () => {

    const navigate = useNavigate();


    const [apiresponsestate, setapiresponsestate] = useState()

const [pageloader, setpageloader] = useState(false)

    const [timenav, settimenav] = useState(1)

    const [trxresult, settrxresult] = useState(0)

    const [showPopup, setShowPopup] = useState(false);

    const [showPopupname, setShowPopupname] = useState("");


    const [betvalues, setbetvalues] = useState([])
    const [secondbetvalues, setsecondbetvalues] = useState([])

    const [currency, setcurrency] = useState("TRX")
    const [amount, setamount] = useState(0)
    const [totalbet, settotalbet] = useState(0)

    const [intervalId, setIntervalId] = useState();
    const [intervalResponse, setIntervalResponse] = useState();


    const [usdtbalance, setusdtbalance] = useState(0);
    const [trxbalance, settrxbalance] = useState(0);
    const [periods, setperiods] = useState();
    const [resultNumb, setResultNumb] = useState();



    const checkuser = async () => {
        
              setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token: getWithExpiry("myuser") }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.status === false) {
            navigate("/login")
        }
        else {
            setusdtbalance(parseFloat(data.data.userdata.usdt).toFixed(4))
            settrxbalance(parseFloat(data.data.userdata.trx).toFixed(4))
        }
        
              setpageloader(false)
    }



    const handleamount = (value) => {
        setamount(value)
        setamount(Number(value))
    }
    useEffect(() => {
        var tbet = amount * betvalues.length;
        settotalbet(parseFloat(tbet).toFixed(4));
    }, [amount, betvalues]);



    const applybet = async () => {
        handleClose()
              setpageloader(true)
        let formdata = {
            token: getWithExpiry("myuser"),
            select: betvalues,
            currency: currency,
            amount: amount,
            totalbet: amount * betvalues.length,
            time: 1
        }

        // alert(currency)

        let data = await axios.post('https://tronapi.tronvid.site/bet_eth', formdata, {
            headers: {
                'Content-Type': 'application/json',
            },
        });


        closeform()
        checkuser();

        // setapiresponsestate(data.data.message)
        // setresponsepopup("zoominresponse")

        const unimodal = document.getElementById('unimodal');
        const unimsg = document.getElementById('unimsg');

        unimsg.textContent = data.data.message;
        unimodal.style.display = 'block';

        setTimeout(() => {
            unimodal.style.display = 'none';
        }, 1500);
        
              setpageloader(false)
    }

    const clearform = () => {
        setbetvalues(betvalues);
        setamount(0);
        setcurrency("TRX");
    }


    const closeform = () => {
        setbetvalues([]);
        setamount(0);
        setcurrency("TRX");
        togglePopup();
    }


    const togglePopup = (value) => {
        setShowPopupname(value)
        setShowPopup(!showPopup);
    };


    const handlebetclick1 = (value) => {
        setbetvalues(prevItems => {
            if (prevItems.includes(value)) {
                return prevItems.filter(i => i !== value);
            } else {
                return [...prevItems, value];
            }
        });
    }

    const updateperiod = async () => {
        try {      
            setpageloader(true)
            let response = await axios.post('https://tronapi.tronvid.site/getperiodeth', { interval: 15 }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            let data = response.data;

            if (response.status === 200 && data.status === 'success') { // Ensure the status check matches the API response


                var element1 = document.getElementById('lastissue');


                setperiods(data.currentperiod.uid);
                setResultNumb(data.lastissue.number);

                element1.innerHTML = data.lastissue.price;
                checkuser();
                
                      setpageloader(false)
            }
        } catch (error) {
            console.error('Error updating period:', error);
        }
    }

    const startcount1 = async (intervalResponse) => {

        clearInterval(intervalId);


        const newIntervalId = setInterval(() => {
            const countDownDate = Date.parse(new Date()) / 1e3;
            const gametime = intervalResponse * 60;
            const now = new Date().getTime();
            const distance = gametime - countDownDate % gametime;

            const minutes = Math.floor(distance / 60);
            const seconds = distance % 60;

            const minutesString = ('0' + minutes).slice(-2);
            const secondsString = ('0' + Math.floor(seconds)).slice(-2);

            const secondsHTML = "<span class='timer'>" + secondsString[0] + "</span>" + "<span class='timer-e'>" + secondsString[1] + "</span>";
            const minutesHTML = "<span class='timer-s'>" + minutesString[0] + "</span>" + "<span class='timer'>" + minutesString[1] + "</span>";

            const timerDisplay = minutesHTML + "<span style='color: #b8a263 !important;'>:</span>" + secondsHTML;

            document.getElementById("timer-fift").innerHTML = timerDisplay;

            if (distance == 898) {
                updateperiod();
                // checkuser();

            }

        }, 1000);

        setIntervalId(newIntervalId);
    };

    useEffect(() => {
        startcount1(1);
        changeInterval(1);
        updateperiod();
        return () => clearInterval();  // Clear interval on component unmount
    }, [intervalResponse]); // Re-run effect when intervalResponse changes


    // Array to store all interval IDs
    const intervalIds = [];

    // Save the original setInterval function
    const originalSetInterval = window.setInterval;

    // Override the setInterval function
    window.setInterval = function (callback, delay) {
        // Call the original setInterval function and get the interval ID
        const intervalId = originalSetInterval(callback, delay);
        // Store the interval ID in the array
        intervalIds.push(intervalId);
        // Return the interval ID
        return intervalId;
    };

    // Function to get all interval IDs
    function getAllIntervalIds() { return intervalIds; }

    // Function to clear all intervals
    function clearAllIntervals() {
        intervalIds.forEach(intervalId => clearInterval(intervalId));
        // Optionally, clear the intervalIds array after clearing all intervals
        intervalIds.length = 0;
    }

    // Clear all intervals
    clearAllIntervals();



    const handletimernav = (interval) => {
        settimenav(interval);

        setIntervalResponse(interval);
        navigate("/eth" + interval)
    }

    const [showModal, setShowModal] = useState(false);
    const [animateOut, setAnimateOut] = useState(false);

    const handleShow = () => {
        setAnimateOut(false);
        setShowModal(true);
    };

    const handleClose = () => {
        setAnimateOut(true);
        setTimeout(() => setShowModal(false), 300); // Match this duration with the animation duration
    };

    const [responsepopup, setresponsepopup] = useState("apiresponsepopup ")


    const [liveprice, setliveprice] = useState();


    const loadlivevalue = async () => {


        try {
            let data = await axios.get('https://tronapi.tronvid.site/ethprice', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });


            if (data.data.status === "success") {
                setliveprice(data.data.ethprice)

                setTimeout(() => {
                    loadlivevalue();
                }, 15000);
            }


        } catch (error) {
        }


    }

    useEffect(() => {
        checkuser()
        loadlivevalue();
    }, [])


function formatValues(values) {
  return values.join(', ');
}


    return (
        <div style={{ minHeight: "80vh" }}>
    {pageloader && <LoadingSpinner />}

            <div className={`row justify-content-center align-items-center ${responsepopup} `} onClick={() => setresponsepopup("zoomoutresponse")} style={{ position: "absolute", width: "100%", height: "75%", zIndex: "1056" }}>
                <p className="  text-white px-2 mb-0 " style={{ background: "#000000b5", borderRadius: "5px", fontSize: "17px", fontWeight: "300", width: "auto" }}>{apiresponsestate}</p>
            </div>


            {/* ////////////////   trade confirm box */}
            <div className='d-none justify-content-center align-item-center' style={{ width: "100%" }} >
                <Modal
                    show={showModal}
                    onHide={handleClose}
                    centered
                    size="sm"
                    className={`d-flex justify-content-center ${animateOut ? 'modal-zoom-out' : 'modal-zoom-in'}`}
                    style={{ width: "100%" }}
                >
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Modal title</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body >
                        <div className="d-flex justify-content-center text-center" style={{ width: "100%" }}>
                            <p className="mx-auto text-center" style={{ width: "200px" }}>
                            Spend {totalbet} {currency} to trade "{formatValues(betvalues)}"</p>
                        </div>

                        <div className="d-flex justify-content-between">
                            <p className='m-0 px-3 py-1' style={{ border: "1px solid #74dbab", borderRadius: "10px" }} onClick={handleClose}> Cancel</p>
                            <p className='m-0 px-3 py-1 linear_gradiend_green text-white' style={{ border: "0px solid black", borderRadius: "10px" }} onClick={applybet}>Trade</p>
                        </div>

                    </Modal.Body>
                    {/* <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary">
                            Save changes
                        </Button>
                    </Modal.Footer> */}
                </Modal>
            </div>
















            <div id="bgimgdiv">
                <div className="timenavbar">
                    <div className="d-flex justify-content-between align-items-center p-2" style={{ borderBottom: "2px solid white" }}>
                        <div className="d-flex align-items-center">

                            <img src="/ethlogo.png" style={{ width: "25px" }} alt="" />
                            <p className="mb-0 mx-1">ETH/USDT</p>
                        </div>
                        <p className="mb-0 text-danger ">{liveprice}</p>
                    </div>
                    <div className="d-flex justify-content-around text-center align-items-center " style={{ fontWeight: "600" }}>
                        <p className={`my-1 ${(timenav === 1) && "timerstyle"}`} onClick={() => handletimernav(1)}><span>1 min</span></p>
                        <p className={`my-1 ${(timenav === 5) && "timerstyle"}`} onClick={() => handletimernav(5)}><span>5 min</span></p>
                        <p className={`my-1 ${(timenav === 15) && "timerstyle"}`} onClick={() => handletimernav(15)}><span>15 min</span></p>
                    </div>
                </div>



                <div id="chartbg" className='my-1' style={{ height: "45vh" }}>
                    <div id="chart-container" >

                        <canvas id="cdskchart" />


                    </div>

                </div>

            </div>


            <div className="bg-light " style={{}}>
                <div className="row px-3">




                    <div className=" tradefont"><p>Last issue:</p>
                        <span id="lastissue" style={{ margin: '0px 20px' }}>

                        </span>

                    </div>
                    <hr className='my-1' />
                    <div className=" tradefont align-items-center" > <p>Result:</p>
                        <div id="preslt"

                            style={{
                                background: resultNumb ? `url(./result/eth${resultNumb}.png) ` : 'none',

                                width: '26px',
                                height: '26px',
                                margin: '0px 38px'
                            }}
                        >

                        </div>

                    </div>
                    <hr className='my-1' />



                    <div className=" tradefont" style={{ justifyContent: 'space-between' }}>
                        <p style={{ display: 'flex', justifyContent: 'space-between', width: '230px' }}>Balance :
                            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '9px', height: '90%', lineHeight: '0', alignItems: 'center', margin: '0px' }}>
                                <span id="balance" style={{ lineHeight: '0', height: '50%', display: 'flex', alignItems: 'center' }}>{trxbalance} TRX</span>
                                <span id="balanceu" style={{ lineHeight: '0', height: '50%', display: 'flex', alignItems: 'center' }}>{usdtbalance} USDT</span>
                            </div>
                            <span id="prd">PD {periods}</span>

                        </p>

                        <div>
                            <span id="timer-fift"></span>
                        </div>



                    </div>





                    <hr className='my-1' />


                    <div className="d-flex align-items-center py-2 bg-light" style={{ borderRadius: "10px 10px 0px 0px", position: "fixed", bottom: '0px' }}>
                        <button
                            type="submit"
                            className='m-2 linear_gradiend_green'
                            style={{ width: "95%", borderRadius: "10px", border: "none", backgroundColor: "orange", padding: "5px 20px", fontWeight: "600", color: "black" }}
                            onClick={() => togglePopup("trx")}
                        >
                            Buy
                        </button>
                        <Calendar2 className="mx-2" onClick={() => navigate("/gamerecordclosings")} />
                    </div>


                </div>

            </div>


            <div className={`popup-container withdraw ${showPopup && 'show'}`}>
                {(showPopupname === "trx") && <div className="popup-content">
                    <span style={{ fontSize: "11px" }}>PD{periods}</span>
                    <hr className='my-1' />
                    <button className="close-btn" onClick={togglePopup}>X</button>
                    {/* {children} */}
                    <span style={{ fontSize: "13px" }}>choose:-</span>



                    


                    <div className="d-flex justify-content-evenly my-1 py-2 text-center" style={{ fontSize: "10px", lineHeight: "1.5" }}>
                        <div className={`col-4 p-1 ${betvalues.includes("red") && "selectboxred"}`} style={{ width: "30%", borderRadius: "10px", border: "red 2px solid" }} onClick={() => handlebetclick1("red")}>
                            <p className='mb-0'>BUY</p>
                            <p className='mb-0'>1.95</p>
                        </div>
                        
                        <div className={`col-4 p-1 ${betvalues.includes("green") && "selectboxgreen"}`} style={{ width: "30%", borderRadius: "10px", border: "green 2px solid" }} onClick={() => handlebetclick1("green")}>
                            <p className='mb-0'>SELL</p>
                            <p className='mb-0'>1.95</p>
                        </div>
                    </div>





                    <hr className='my-1' />
                    <div className="row">
                        <div className="col-md-1 col-4 tradefont">Currency :</div>

                        <div className="col-md-11 col-6  " style={{ fontSize: "12px" }}>
                            <div className='d-flex align-items-center'>

                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="TRXfield"
                                        value="TRX"
                                        defaultChecked
                                        onChange={(e) => setcurrency(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                        TRX
                                    </label>
                                </div>
                                <div className="form-check form-check-inline" style={{ fontSize: "12px" }}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="Usdtfield"
                                        value="USDT"
                                        onChange={(e) => setcurrency(e.target.value)}

                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        USDT
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr className='mb-0' />
                        <div className="col-md-1 col-4 tradefont">Amount :</div>
                        <div className="col-md-11 col-6 tradefont d-flex align-items-center">
                            <input type="text" placeholder={"Enter Amount"} onChange={(e) => handleamount(e.target.value)} className='popupinput px-2' style={{ width: "100%", height: "20px" }} />


                        </div>
                        <hr className='mb-0' />
                        <div className="col-md-1 col-4 tradefont">Total Bet :</div>
                        <div className="col-md-11 col-6 tradefont"> {totalbet} {currency}</div>
                        <hr className='mb-0' />
                        <div className="col-md-1 col-4 tradefont">Total Balance :</div>
                        <div className="col-md-11 col-6 tradefont">{(currency === "TRX") ? (trxbalance + " " + currency) : (usdtbalance + " " + currency)}</div>

                        <hr className='mb-0' />






                        <p
                            className='text-center my-2 linear_gradiend_green'
                            style={{ width: "100%", borderRadius: "10px", fontSize: "12px", border: "none", backgroundColor: "orange", padding: "5px 20px", fontWeight: "600", color: "black" }}
                            onClick={handleShow}
                        >
                            Confirm
                        </p>

                        <div className="d-flex justify-content-between">

                            <p
                                className='text-center mb-0'
                                style={{ width: "49%", border: "1px solid black", borderRadius: "10px", fontSize: "12px", padding: "5px 20px", fontWeight: "600", color: "black" }}
                                onClick={clearform}
                            >
                                Clean Up
                            </p>
                            <p
                                className='text-center mb-0'
                                style={{ width: "49%", border: "1px solid black", borderRadius: "10px", fontSize: "12px", padding: "5px 20px", fontWeight: "600", color: "black" }}
                                onClick={closeform}
                            >
                                Closure
                            </p>

                        </div>




                    </div>

                </div>}

            </div>

        </div >
    )
}

export default Tradepage15min