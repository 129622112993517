import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiEndPointt } from "./API/ApiPaths";
import { getWithExpiry } from "./JS/JS";
// import { apiEndPointt } from "../../webapi/apiPaths";

const Protectedroutes = ({ children }) => {
    const navigate = useNavigate();
    const [Response, setResponse] = useState()


    ////////////////////////

    const checkLogin = async () => {

        const token = getWithExpiry("myuser")

        let data = await axios.post(apiEndPointt.VERIFYUSER, { token: token }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log(children)

        if (data.data.status === "error") {
            navigate("/login")
            setResponse(false)
        } else
            if (data.data.status === "success") {
                setResponse(true)
            }
    }
    useEffect(() => {
        checkLogin();   
    }, [Response])


    return Response ? (
        <>{children}</>
    ) : (
        navigate("/login")
    );
};

// <>
{/* </> */ }
// }

export default Protectedroutes;