import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const handleReceive = async () => {
  try {
    const codeElement = document.getElementById('form3Example1');
    const code = codeElement ? codeElement.value : '';
    
    let response = await axios.post('https://tronapi.tronvid.site/claim', 
      { 
        token: getWithExpiry("myuser"), 
        code: code 
      }, 
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    
    if (response.data.status === 'success' || response.data.status === 'error') {
      const unimodal = document.getElementById('unimodal');
      const unimsg = document.getElementById('unimsg');
      
      unimsg.textContent = response.data.message;
      unimodal.style.display = 'block';
      
      setTimeout(() => {
        unimodal.style.display = 'none';
      }, 1500);
    }
  } catch (error) {
    console.error('Error making POST request', error);
  }
};

const Redemtionexchange = () => {
  const navigate = useNavigate();
  const [pageloader, setpageloader] = useState(true)
  const [code, setcode] = useState()

  setTimeout(() => {
    setpageloader(false)
  }, 500);

  const handleSubmit = (e) => {
    setpageloader(true)
    e.preventDefault();
    handleReceive();
    setpageloader(false)
  }
  

  return (
    <div className="py-5 redemtionbackground" style={{ minHeight: "80vh" }}>
      {pageloader&&<LoadingSpinner/>}
      <div className="row gx-lg-5 justify-content-center align-items-center mb-5">
        <div className="col-lg-6 mb-lg-0">
          <div className="card bg-glass mx-3">
            <div className="card-body px-4 px-md-5 text-white">
              <h1 className='text-center'>Claim Your Reward</h1>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div data-mdb-input-init="" className="form-outline my-2">
                    <input
                      type="text"
                      id="form3Example1"
                      className="form-control text-center"
                      placeholder='Enter Code'
                      onChange={(e)=>setcode(e.target.value)}
                    />
                  </div>
                  <div className='d-flex pt-3 justify-content-center'>
                    <button
                      type="submit"
                      data-mdb-button-init=""
                      data-mdb-ripple-init=""
                      className="btn btn-primary btn-block mb-4"
                    >
                      Click Claim
                    </button>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <p className='text-center' style={{ width: "200px", fontSize: "14px" }}> 
                      Please enter the code provided by your referrer to claim your reward.
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Redemtionexchange;
