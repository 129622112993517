import React from "react";
import "./pageloader.css"

export default function LoadingSpinner() {
  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: "110vh", width: "100%", zIndex: "5", position: "absolute", background: "#000000ad",zIndex:"10000", top: "-10%" }}>

      <div className="pageloadercontainer" >
        <div className="pageloader" >
        </div>
      </div>

      {/* <img src="/logo/3.png" className="zoom-in-out-box" alt="" /> */}
    </div>
  );
}