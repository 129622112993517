import React from 'react'

const Activitydetail = () => {
    return (
        <div className='p-3' style={{ backgroundColor: "#21275b",minHeight:"85vh" }}>
            <div className='p-1  text-white' style={{ backgroundColor: "#2a3270", borderRadius: "5px" }}>
                <h3 className='mx-auto mt-3 bold text-center'>Activity Rules</h3>
                <ul style={{paddingLeft:"7%"}} className='py-3'>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nobis non illo distinctio repellendus, velit ex! Ipsam minus aspernatur, atque voluptatum natus, quas enim nisi mollitia unde nihil esse commodi.</li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nobis non illo distinctio repellendus, velit ex! Ipsam minus aspernatur, atque voluptatum natus, quas enim nisi mollitia unde nihil esse commodi.</li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nobis non illo distinctio repellendus, velit ex! Ipsam minus aspernatur, atque voluptatum natus, quas enim nisi mollitia unde nihil esse commodi.</li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nobis non illo distinctio repellendus, velit ex! Ipsam minus aspernatur, atque voluptatum natus, quas enim nisi mollitia unde nihil esse commodi.</li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nobis non illo distinctio repellendus, velit ex! Ipsam minus aspernatur, atque voluptatum natus, quas enim nisi mollitia unde nihil esse commodi.</li>
                    <li>Lorem ipsum dolor sit amet consectetur adipisicing elit. Vel nobis non illo distinctio repellendus, velit ex! Ipsam minus aspernatur, atque voluptatum natus, quas enim nisi mollitia unde nihil esse commodi.</li>
                </ul>
            </div>
        </div>
    )
}

export default Activitydetail