import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Link45deg } from 'react-bootstrap-icons';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';
import { useNavigate } from 'react-router-dom'

const Withdraw = () => {
    
    const navigate = useNavigate();
    
    
    const token = getWithExpiry("myuser");

    const [waddress, setwaddress] = useState('');
    const [wpass, setwpass] = useState('');
    const [currency, setcurrency] = useState("TRX");
    const [amount, setamount] = useState(0);
    const [pageloader, setpageloader] = useState(false);
    const [updateaddress, setupdateaddress] = useState('');
    const [trxbalance, settrxbalance] = useState(0);
    const [usdtbalance, setusdtbalance] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [showPopupname, setShowPopupname] = useState("");
    const [responsepopup, setresponsepopup] = useState("apiresponsepopup");
    const [apiresponsestate, setapiresponsestate] = useState('');

    const copyText = () => {
        var referLinkElement = document.getElementById("addd");
        var tempInput = document.createElement("input");
        tempInput.setAttribute("value", referLinkElement.textContent);
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        alert("Address copied to clipboard: " + referLinkElement.textContent);
    };

    const withdraw = async () => {
        setpageloader(true);
        try {
            let data = await axios.post('https://tronapi.tronvid.site/withdraw', { wpass, currency, amount, token }, {
                headers: { 'Content-Type': 'application/json' },
            });
        setpageloader(false)
            if (data.data.status === 'success') {
                
                
               
                // localStorage.setItem("myuser", response.data.token);
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
                unimsg.textContent = data.data.message;
                unimodal.style.display = 'block';
                
                setTimeout(() => {
                  unimodal.style.display = 'none';
                  navigate("/user")
                }, 1500);
                
                

            } else {
                
                
                
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');
                
                unimsg.textContent = data.data.message;
                unimodal.style.display = 'block';
                
                setTimeout(() => {
                  unimodal.style.display = 'none';
                }, 1500);
                
            }
        } catch (error) {
            console.error(error);
            setpageloader(false);
        }
    };

    const updatewithdrawaddress = async () => {
        try {
            let data = await axios.post('https://tronapi.tronvid.site/addwallet', { address: updateaddress, token }, {
                headers: { 'Content-Type': 'application/json' },
            });
            setapiresponsestate(data.data.message);
            setresponsepopup("zoominresponse");
        } catch (error) {
            console.error(error);
        }
    };

    const checkuser = async () => {
        setpageloader(true);
        try {
            let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token }, {
                headers: { 'Content-Type': 'application/json' },
            });
            
            const trxValue = parseFloat(data.data.userdata.trx);
            const usdtValue = parseFloat(data.data.userdata.usdt);


            const trxBalances = isNaN(trxValue) ? '0.0000' : trxValue.toFixed(4);
            
            const usdtBalances = isNaN(usdtValue) ? '0.0000' : usdtValue.toFixed(4);
            
            settrxbalance(trxBalances);
            setusdtbalance(usdtBalances);
            setwaddress(data.data.userdata.w_address);
            setpageloader(false);
        } catch (error) {
            console.error(error);
            setpageloader(false);
        }
    };

    const togglePopup = (value) => {
        setShowPopupname(value);
        setShowPopup(!showPopup);
    };
    
    const toggleall = (value) => {
        if (value === "TRX") {
            setamount(trxbalance - 0.01);
        } else {
            setamount(usdtbalance - 0.01);
        }
    };
  

    useEffect(() => {
        checkuser();
    }, []);
    
    
    
  const containerStyle = {
        borderRadius: '0.26667rem',
        width: '100%',
        marginBottom: '0.4rem',
    };

    const topStyle = {
        background: 'linear-gradient(134deg, #1b434c, #f0ffff)',
        borderRadius: '0.16rem',
        height: '42px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '0.26667rem',
        marginBottom: '10px',
        marginTop: '20px',
    };

    const textStyle = {
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: '15px',
        paddingLeft: '1.54667rem',
        backgroundImage: 'url("https://img.icons8.com/color/48/filled-plus-2-math.png")',
        backgroundSize: '18px',
        backgroundRepeat: 'no-repeat',
        height: '1.14667rem',
        lineHeight: '1.14667rem',
    };

    const descriptionStyle = {
        color: '#198754',
        fontSize: '10px',
        textAlign: 'center',
        fontWeight: 600,
    };

    return (
        <>
            {pageloader && <LoadingSpinner />}
            <div className={`popup-container withdraw ${showPopup ? 'show' : ''}`} style={{ borderTopRightRadius: "20px", borderTopLeftRadius: "20px",background:"#f0ffff" }}>
                <div className={`row justify-content-center align-items-center ${responsepopup}`} onClick={() => setresponsepopup("zoomoutresponse")} style={{ position: "absolute", width: "100%", height: "100%", zIndex: "1056" }}>
                    <p className="text-white px-2 mb-0" style={{ background: "#000000b5", borderRadius: "5px", fontSize: "17px", fontWeight: "300", width: "auto" }}>{apiresponsestate}</p>
                </div>
                {showPopupname === "address" && (
                    <div style={{ width: "100%", borderTopRightRadius: "20px", borderTopLeftRadius: "20px" }} className='p-3 bg-dark'>
                        <button className="close-btn text-white" onClick={togglePopup}>X</button>
                        <p className='text-center text-white'>Add Withdrawal Address</p>
                        <hr style={{ color: "#999" }} />
                        <label htmlFor="Withdrawal" className='text-white my-1 mt-2 ms-2'>Withdrawal address</label>
                        <input type="text" className='mx-auto px-3' onChange={(e) => setupdateaddress(e.target.value)} style={{ borderRadius: "10px", background: "#999", border: "none", width: "100%", color: "white", height: "35px" }} placeholder='Please Enter USDT wallet address' />
                        <label htmlFor="Remark" className='text-white my-1 mt-2 ms-2'>Remark</label>
                        <input type="text" className='mx-auto px-3' style={{ borderRadius: "10px", background: "#999", border: "none", width: "100%", color: "white", height: "35px" }} placeholder='Please enter a note' />
                        <p className='mx-auto my-3 text-white justify-content-center d-flex align-items-center' style={{ borderRadius: "10px", height: "35px", width: "100%", background: "#ff2d55" }} onClick={copyText}>Save</p>
                    </div>
                )}
                {showPopupname === "payment" && (
                    <div className="popup-content" 
                    style={{ background: "white !important" }}>
                        <button className="close-btn" onClick={togglePopup}>X</button>
                        <h3 className='my-3' style={{ fontSize: "18px" }}>Withdraw Password</h3>
                        <input type="password" className='mx-auto px-3 mb-3' 
                        style={{ borderRadius: '10px', background: 'rgb(233, 236, 239)', border: 'none', width: '100%', color: 'black', height: '35px', padding: '0 1rem', }}
                        onChange={(e) => setwpass(e.target.value)} placeholder='Enter Password' />
                        <div className="d-flex justify-content-center">
                            <p className='mx-auto mb-2 text-white justify-content-center d-flex align-items-center' style={{ borderRadius: "10px", height: "35px", width: "100%", background: "#48bf88" }} onClick={withdraw}>Withdraw</p>
                        </div>
                    </div>
                )}
            </div>
            <div className='py-1 '>
            
            
                <div className='d-flex p-3 justify-content-between'>
                    <div className='bg-white d-flex align-items-center' style={{ width: "47%", height: "70px", borderRadius: "10px" }}>
                        <img className='m-3' style={{ height: "35px", width: "35px" }} src="/icon_mine_TRX.png" alt="" />
                        <div>
                            <p style={{ fontSize: "15px", fontWeight: "600" }} className='mb-0'>
                                {trxbalance}
                            </p>
                            <p style={{ fontSize: "11px" }} className='mb-0'>TRX Balance</p>
                        </div>
                    </div>
                    <div className='bg-white d-flex align-items-center' style={{ width: "47%", height: "70px", borderRadius: "10px" }}>
                        <img className='m-3' style={{ height: "35px", width: "35px" }} src="/usdt-BK5BR48P.png" alt="" />
                        <div>
                            <p style={{ fontSize: "15px", fontWeight: "600" }} className='mb-0'>
                                {usdtbalance }
                            </p>
                            <p style={{ fontSize: "11px" }} className='mb-0'>USDT Balance</p>
                        </div>
                    </div>
                </div>
                
                
                
                
                <div className="p-3 my-3 withdraw mb-3" style={{ backgroundColor: "white" }}>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='widhrawheads mb-1'>Withdraw Currency</p>
                        <div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio1"
                                    value="TRX"
                                    defaultChecked
                                    onChange={(e) => setcurrency(e.target.value)}
                                />
                                <label className="form-check-label widhrawheads" htmlFor="inlineRadio1">
                                    TRX
                                </label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name="inlineRadioOptions"
                                    id="inlineRadio2"
                                    value="USDT"
                                    onChange={(e) => setcurrency(e.target.value)}
                                />
                                <label className="form-check-label widhrawheads" htmlFor="inlineRadio2">
                                    USDT
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="p-3 withdraw" style={{ backgroundColor: "white" }}>
                    
                    
                    
                    
                    
                    
                    
                    
                    <p className='widhrawheads mb-1 mt-2'>Withdraw Amount</p>
                    <div className="d-flex justify-content-center align-items-center inputs py-0" style={{ paddingRight: '0px' }}>
                        <input className="inputs" placeholder='input' style={{ width: "100%",outline: "none" }} type="text" value={amount} onChange={(e) => setamount(e.target.value)} />
                        <button 
                            style={{ height: '43px', border: 'navajowhite', width: '40px', borderTopRightRadius: '5px', borderBottomRightRadius: '5px' }}
                            onClick={() => toggleall(currency)}>
                            All
                        </button>
                    </div>
                    
                    
                    
                    
                    {waddress === "0" ? (
                    
        <div style={containerStyle} className="addWithdrawType">
            <div style={topStyle} className="addWithdrawType-top">
                <div style={textStyle}  onClick={() => navigate("/updatewithdraw")}>
                    Add address
                </div>
            </div>
            <div style={descriptionStyle} className="addWithdrawType-text">
                Need to add beneficiary information to be able to withdraw money
            </div>
        </div>
                        
                    ) : (
                        <>
                            <p className='widhrawheads mb-1 mt-2'>Withdraw Address</p>
                            <div className="d-flex justify-content-center align-items-center inputs py-0">
                                <Link45deg />
                                <input type="text" placeholder={waddress} disabled className='inputs' style={{ width: "100%",outline: "none" }} />
                            </div>
                        </>
                    )}
                </div>
                
                
                
                
                
                
                
                <div className="px-3 my-3 withdraw" style={{ backgroundColor: "white" }}>
                    <div className="d-flex justify-content-between">
                        <p className='widhrawheads mb-1 mt-2'>Network Free</p>
                        <p className='widhrawheads mb-1 mt-2 text-danger'>1.00 {currency}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='widhrawheads mb-1 mt-2'>Available Balance</p>
                        <p className='widhrawheads mb-1 mt-2 text-success'>{(currency === "TRX") ? (`${Number(trxbalance)?.toFixed(4)} TRX`) : (`${Number(usdtbalance).toFixed(4)} USDT`)}</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='widhrawheads mb-1 mt-2'>Withdraw Limit</p>
                        <p className='widhrawheads mb-1 mt-2 text-primary'>10.00 - 500000.00</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='widhrawheads mb-1 mt-2'>Received Amount</p>
                        <p className='widhrawheads mb-1 mt-2 text-success'>{amount - 1} {currency}</p>
                    </div>
                </div>
                <ul style={{ fontSize: "12px" }} className='px-4'>
                    <li>The minimum withdrawal amount is: 10 {currency} (TRC20).</li>
                    <li>In order to ensure the safety of funds, when your account security policy is changed or your password is changed, we will manually review the withdrawals. Please be patient and wait for the staff to contact you by phone or email.</li>
                    <li>If the withdrawal has not arrived within 10 minutes, please contact the management customer service.（Manual review for you quickly）</li>
                    <li>Please make sure that your computer and browser are safe to prevent information from being altered or leaked.</li>
                </ul>
                <div className="d-flex justify-content-center mb-5">
                    <button style={{ borderRadius: "10px", background:"linear-gradient(134deg, #1b434c, #f0ffff)", border: "none", fontSize: "15px", width: "100%" }} className='m-3 p-2 px-3 text-white' onClick={() => togglePopup("payment")}>Withdraw Now</button>
                </div>
            </div>
        </>
    );
};

export default Withdraw;
