import React, { useState } from 'react'

const Redemtionsetting = () => {

  const [currency, setcurrency] = useState ("TRX")
  const [amount, setamount] = useState(0)
  const [giftscount, setgiftscount] = useState(0)
  const [type, settype] = useState("normal")
  return (
    <div className="redemtionbackground " style={{ minHeight: "550px" }}>
      <div className="row gx-lg-5 justify-content-center align-items-center mb-5">
        <div className="col-lg-4 p-4 mb-5 mb-lg-0  ">
          <div className="card bg-glass ">

            <div className="card-body pb-5  pt-4 px-md-5 text-light ">
              <h1>New Redemption</h1>
              <form>
                <div className="row">
                  <div data-mdb-input-init="" className="d-flex justify-content-between form-outline my-2">
                    <label className="form-label col-4" htmlFor="form3Example1">
                      Total Gifts
                    </label>
                    <input
                      type="text"
                      id="gift"
                      className="form-control"
                      placeholder='Enter Numbers Of Gifts'
                      onChange={(e)=>setgiftscount(e.target.value)}

                    />
                  </div>
                  <div data-mdb-input-init="" className="d-flex justify-content-between form-outline my-2">
                    <label className="form-label col-4" htmlFor="form3Example2">
                      Amount
                    </label>
                    <input
                      type="password"
                      id="amount"
                      className="form-control"
                      placeholder='Enter Amount'
                      onChange={(e)=>setamount(e.target.value)}
                    />

                  </div>
                </div>
                <div data-mdb-input-init="" className=" d-flex justify-content-between form-outline mb-4 my-2">
                  <label className="form-label col-4" htmlFor="form3Example3">
                    Currency
                  </label>
                  <div >
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="currency"
                        id="currency"
                        Value="TRX"
                        checked
                        onChange={(e) => setcurrency(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="currency">
                        TRX
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="currency"
                        id="currency"
                        defaultValue="USDT"
                        Value="USDT"
                        onChange={(e) => setcurrency(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="currency">
                        USDT
                      </label>
                    </div>
                  </div>

                </div>
                <div data-mdb-input-init="" className=" d-flex justify-content-between form-outline mb-4 my-2">
                  <label className="form-label col-4" htmlFor="form3Example4">
                    Type
                  </label>

                  <div >
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        id="type"
                        value="Normal"
                        checked
                        onChange={(e) => settype(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="type">
                        TRX
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="type"
                        id="type"
                        defaultValue="Random"
                       value="Random"
                        onChange={(e) => settype(e.target.value)}
                      />
                      <label className="form-check-label" htmlFor="type">
                        USDT
                      </label>
                    </div>
                  </div>
                  
                </div>
                <div className='d-flex justify-content-between' style={{ fontSize: ".85rem", color: "#fff" }}>
                  <p>Balance: 0 USDT</p>
                  <p>Expenditure: 0 USDT</p>
                </div>
                <div className='d-flex justify-content-center'>

                  <button
                    type="submit"
                    data-mdb-button-init=""
                    data-mdb-ripple-init=""
                    className="btn btnss mb-4"
                  >
                    Submit
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Redemtionsetting