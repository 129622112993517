import React, { useEffect, useState } from 'react'
import Apiloader from './Loader/Apiloader';
import axios from 'axios';
import LoadingSpinner from './Loader/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { apiEndPointt } from './API/ApiPaths';
import { getWithExpiry } from './JS/JS';




const CurrencyExchange = () => {
    
    const [pageloader, setpageloader] = useState(true)
    const [exchange, setexchange] = useState("trxtousdt");
    const toggleexchange = () => { (exchange === "trxtousdt") ? setexchange("usdttotrx") : setexchange("trxtousdt") }

    const [deposit, setdeposit] = useState("trxtousdt");

    const [withdraw, setwithdraw] = useState("trxtousdt");


    const [depositamount, setdepositamount] = useState(0);

    const [withdrawamount, setwithdrawamount] = useState(0);



    const navigate = useNavigate();


    const convertUsdtToTrx = () => {
        // Convert usdtAmount from USDT to TRX
        let trxAmount = 1 / exchangeRatetrxtousdt;
        return trxAmount.toFixed(2); // Return TRX amount rounded to 2 decimal places
    };

    useEffect(() => {
        if ((exchange === "trxtousdt")) { setwithdrawamount((depositamount * exchangeRatetrxtousdt).toFixed(3)) }


        if ((exchange === "usdttotrx")) { setwithdrawamount((depositamount * (1 / exchangeRatetrxtousdt).toFixed(3)).toFixed(3)) }
    }, [depositamount, exchange])


    const [exchangeRatetrxtousdt, setExchangeRate] = useState(0);

    const [apiloader, setapiloader] = useState(false)

    const [usdtbalace, setusdtbalace] = useState()
    const [trxbalance, settrxbalance] = useState()


    const checkuser = async () => {
        setapiloader(true)


        let req = {
            from: "",
            to: "",
            amount: depositamount
        }



        let data = await axios.post(apiEndPointt.VERIFYUSER, { token: getWithExpiry("myuser") }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {
            // navigate("/login")
        }
        else {
            setusdtbalace(Number(data.data.userdata.usdt))
            settrxbalance(Number(data.data.userdata.trx))
            setapiloader(false)
        }
    }

const exchangecurrency = async () => {

    setapiloader(true);

    let rate = 0;
    let curr = "";
    if (exchange === "trxtousdt") {
        rate = exchangeRatetrxtousdt;
        curr = "USDT";
    } else if (exchange === "usdttotrx") {
        curr = "TRX";
        rate = 1 / exchangeRatetrxtousdt;
    }

    let data = {
        convertamount: depositamount,
        convertedamount: withdrawamount,
        curr: curr,
        rate: rate,
        token: getWithExpiry("myuser")
    };

    try {
        let response = await axios.post('https://tronapi.tronvid.site/exchange', data, {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        setapiloader(false);

        const unimodal = document.getElementById('unimodal');
        const unimsg = document.getElementById('unimsg');

        // Handle different statuses
        if (response.data.status === 'success') {
            unimsg.textContent = response.data.message;
        } else {
            unimsg.textContent = response.data.message;
        } 

        unimodal.style.display = 'block';
        setTimeout(() => {
            unimodal.style.display = 'none';
        }, 1500);

    } catch (error) {
        setapiloader(false);

        const unimodal = document.getElementById('unimodal');
        const unimsg = document.getElementById('unimsg');

        unimsg.textContent = error.response ? error.response.data.message : "An error occurred";
        unimodal.style.display = 'block';

        setTimeout(() => {
            unimodal.style.display = 'none';
        }, 1500);
    }

    setdepositamount(0);
    setwithdrawamount(0);
    checkuser();
};


    const fetchCryptoData = async () => {
        setapiloader(true)
        let data = await axios.post('https://estateproapp.site/backendapi/crpto-price-export/cryptoprice.php', { symbol: "TRXUSDT" }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {
            // console.error("Error fetching crypto data");
        } else {
            setExchangeRate(parseFloat(data.data.userdata[0][4]).toFixed(3));
        }
        setapiloader(false)

    };





    useEffect(() => {
        fetchCryptoData();
    }, [exchange]);


    useEffect(() => {
        checkuser();
    }, [])


    setTimeout(() => {
        setpageloader(false)
    }, 1000);




    return (
        <div>
            {/* {apiloader && <Apiloader />} */}
            {pageloader && <LoadingSpinner />}
            
            
                <div className='d-flex p-3 justify-content-between' >
                    <div className='bg-white d-flex align-items-center' style={{ width: "47%", height: "70px", borderRadius: "10px" }}>
                        <img className='m-3' style={{ height: "35px", width: "35px" }} src="/icon_mine_TRX.png" alt="" />
                        <div>
                            <p style={{ fontSize: "15px", fontWeight: "600" }} className='mb-0'>
                                {(trxbalance !== null ? (Number(trxbalance).toFixed(4)) : '0.0000')}
                            </p>
                            <p style={{ fontSize: "11px" }} className='mb-0'>TRX Balance</p>
                        </div>
                    </div>
                    <div className='bg-white d-flex align-items-center' style={{ width: "47%", height: "70px", borderRadius: "10px" }}>
                        <img className='m-3' style={{ height: "35px", width: "35px" }} src="/usdt-BK5BR48P.png" alt="" />
                        <div>
                            <p style={{ fontSize: "15px", fontWeight: "600" }} className='mb-0'>
                                {(usdtbalace !== null ? (Number(usdtbalace).toFixed(4)) : '0.0000')}
                            </p>
                            <p style={{ fontSize: "11px" }} className='mb-0'>USDT Balance</p>
                        </div>
                    </div>
                </div>
                
            <div className="m-2 p-3" style={{ backgroundColor: "#f1f5f9b8", borderRadius: "10px" }}>
                <div className="d-flex justify-content-around">
                    <div>
                        {(exchange === "trxtousdt") && <div className="d-flex">
                            <div className="d-flex justify-content-center ">
                                <img src="/trx-Ul0rqP_4.png" style={{ height: "30px", width: "30px" }} alt="" />
                            </div>
                            <div className="mx-2">
                                <p className='m-0 bold  currencyname ' style={{ fontSize: "14px" }}><span>TRX</span></p>
                                <p style={{ fontSize: "12px" }}>Transfer Quantity</p>
                            </div>
                        </div>}

                        {(exchange === "usdttotrx") && <div className="d-flex">
                            <div className="d-flex justify-content-center ">
                                <img src="https://cryptologos.cc/logos/tether-usdt-logo.png" style={{ height: "30px", width: "30px" }} alt="" />
                            </div>
                            <div className="mx-2">
                                <p className='m-0 bold  currencyname ' style={{ fontSize: "14px" }}><span>USDT</span></p>
                                <p style={{ fontSize: "12px" }}>Received Quantity</p>
                            </div>
                        </div>
                        }
                        
                        <div className="d-flex justify-content-center align-items-center inputs py-0" style={{ paddingRight: '0px' }}>
                        <input
                            type="number"
                            id="trxcurrency"
                            // onChange={(e)=>setPassword(e.target.value)}
                            className="form-control mx-auto mt-2"
                            placeholder='Enter quantity'
                            value={depositamount}
                            onChange={(e) => setdepositamount(e.target.value)}
                            style={{ borderRadius: "10px", border: "none", width: "90%", fontSize: "14px", height: "30px" }}
                        />
                        

                        
                        </div>
                    </div>
                    <img src="/exchange.png" style={{ height: "80px", width: "40px" }} onClick={toggleexchange} />
                    <div>
                        {(exchange === "trxtousdt") && <div className="d-flex">
                            <div className="d-flex justify-content-center ">
                                <img src="https://cryptologos.cc/logos/tether-usdt-logo.png" style={{ height: "30px", width: "30px" }} alt="" />
                            </div>
                            <div className="mx-2">
                                <p className='m-0 bold  currencyname ' style={{ fontSize: "14px" }}><span>USDT</span></p>
                                <p style={{ fontSize: "12px" }}>Received Quantity</p>
                            </div>
                        </div>}
                        {(exchange === "usdttotrx") && <div className="d-flex">
                            <div className="d-flex justify-content-center ">
                                <img src="/trx-Ul0rqP_4.png" style={{ height: "30px", width: "30px" }} alt="" />
                            </div>
                            <div className="mx-2">
                                <p className='m-0 bold  currencyname ' style={{ fontSize: "14px" }}><span>TRX</span></p>
                                <p style={{ fontSize: "12px" }}>Transfer Quantity</p>
                            </div>
                        </div>}
                        <input
                            type="number"
                            id="trxcurrency"
                            // onChange={(e)=>setPassword(e.target.value)}
                            className="form-control mx-auto mt-2"
                            placeholder='Received quantity'
                            disabled
                            value={withdrawamount}
                            style={{ borderRadius: "10px", border: "none", width: "90%", fontSize: "14px", height: "30px" }}
                        />
                    </div>
                </div>
                <p className='mt-3' style={{ fontSize: "12px" }}>Wallet Balance : {(exchange === "trxtousdt") ? (Number(trxbalance).toFixed(4) + " TRX") : (Number(usdtbalace).toFixed(4) + " USDT")}</p>
            </div>
            <div className="text-dark mx-2  d-flex justify-content-between align-items-center">
                <p style={{ fontSize: "14px", fontWeight: "600" }}>View Currency information</p>
                <p style={{ fontSize: "14px" }}>TRX/USDT</p>

            </div>
            <div className="m-2 p-3" style={{ fontSize: "12px", backgroundColor: "#f1f5f9b8", borderRadius: "10px" }}>
                <div className="text-dark  d-flex justify-content-between align-items-center">
                    <p >Exchange Rate</p>
                    {(exchange === "trxtousdt") && <p >1 TRX = {exchangeRatetrxtousdt} USDT</p>}
                    {(exchange === "usdttotrx") && <p >1 USDT = {(1 / exchangeRatetrxtousdt).toFixed(3)} TRX</p>}
                </div>
                <div className="text-dark  d-flex justify-content-between align-items-center">
                    <p >commission</p>
                    <p >{(exchange === "trxtousdt") ? 0 + " USDT" : 0 + " TRX"}</p>
                </div>
                <div className="text-dark  d-flex justify-content-between align-items-center">
                    <p >price impact</p>
                    <p >0%</p>
                </div>
                <div className="text-dark  d-flex justify-content-between align-items-center">
                    <p >minimum receiving amount</p>
                    <p >{(exchange === "trxtousdt") ? withdrawamount + " USDT" : withdrawamount + " TRX"} </p>
                </div>
            </div>
            <div className="d-flex justify-content-center mb-5">
                <button style={{ borderRadius: "10px", background: "linear-gradient(134deg, #1b434c, #f0ffff)", border: "none", fontSize: "15px", width: "100%" }} className='m-3 p-2 px-3 text-white' onClick={exchangecurrency}>Exchange</button>
            </div>
        </div>
    )
}

export default CurrencyExchange