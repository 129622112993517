import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ArrowLeft } from 'react-bootstrap-icons'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap'
import { getWithExpiry } from '../JS/JS'
import LoadingSpinner from '../Loader/LoadingSpinner'

const Gamerecordtransaction15 = () => {
    const location = useLocation()
    const navigate = useNavigate();
    const [pageloader, setpageloader] = useState(true)


    const [nav, setnav] = useState("close")
    const [selectedValue, setSelectedValue] = useState(15);

    const [showPopup, setShowPopup] = useState(false);

    const [result, setresult] = useState([]);
    const [selectedid, setselectedid] = useState();

    const togglePopup = (id) => {
        setselectedid(id)
        setShowPopup(!showPopup);
    };

    const fetchclosingrecords = async () => {
        setpageloader(true)

        let data = await axios.post('https://tronapi.tronvid.site/userethbethistory', { interval: selectedValue,token: getWithExpiry("myuser"), game_type: `eth${selectedValue}` }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data?.data?.message === "No records found") {
            setresult([])
        } else {
            setresult(data?.data?.btchistory)
        }
        setpageloader(false)

    }


    useEffect(() => {
        fetchclosingrecords();
    }, [selectedValue])


    const handleSelect = (eventKey) => {


        let selectedText;
        switch (eventKey) {
            case '1':
                selectedText = 1;
                console.log("========="); // Output: value
                break;
            case '2':
                selectedText = 5;
                break;
            case '3':
                selectedText = 15;
                break;
            default:
                selectedText = 'Select an option';
        }


        sessionStorage.setItem('selectedintervel', selectedText);

        // setSelectedValue(selectedText);
        fetchselectedinterval();

    };



    function myspotc(results) {
        if (results === "green") {
            return "Green";
        } else if (results === "red") {
            return "Red";
        } else if (results === "violet") {
            return "Violet";
        } else {
            return results;
        }
        // else {
        //     return (
        //         <div id="preslt" style={{ background: 'url("./result/eth8.png")', width: '26px', height: '26px' }}></div>
        //     );
        // }
    }
    const fetchselectedinterval = () => {
        const value = sessionStorage.getItem('selectedintervel');
        // setSelectedValue(value);
    }

    useEffect(() => {
        fetchselectedinterval()
    }, [])



    return (
        <div>
            {pageloader && <LoadingSpinner />}


            <div className="shadow-sm position-sticky sticky-top" style={{ backgroundColor: "white" }}>
                <div className='d-flex justify-content-between align-items-center  p-2'>
                    <div className="" style={{ width: "20%" }}>{(location.pathname === "/index") ? <img style={{ height: "30px", width: "30px" }} src="https://genusdt.net/static/img/logo.png" alt="" /> : <ArrowLeft onClick={() => navigate(`/eth${selectedValue}`)} />}</div>
                    {/* <div className=""></div> */}
                    <div className="text-center d-flex align-items-center justify-content-center" style={{ width: "60%" }}>

                        <span>Record</span>
                    </div>
                    <div className="navbutton d-flex justify-content-end" style={{ width: "20%" }}>
                        {/* {<DropdownButton
                            className='ms-auto '
                            as={ButtonGroup}
                            key={"info"}
                            id={`dropdown`}
                            onSelect={handleSelect}
                            // info={"info".toLowerCase()}
                            title={selectedValue + " min"}
                        >
                            <Dropdown.Item eventKey="1" >1 min</Dropdown.Item>
                            <Dropdown.Item eventKey="2">5 min</Dropdown.Item>
                            <Dropdown.Item eventKey="3">15 min</Dropdown.Item>
                        </DropdownButton>} */}


                        {/* {(location.pathname === "/withdraw") && <span onClick={() => navigate("/withdrawrecord")}><Calendar2Event className='mx-2' /></span>} */}
                        {/* {(location.pathname == "/depositpage" || location.pathname == "/deposittrx" || location.pathname == "/depositusdt") && <span onClick={() => navigate("/depositrecord")}><Calendar2Event className='mx-2' /></span>} */}
                    </div>
                </div>
            </div>



            <div className="d-flex justify-content-around gamerecordnav py-2 bg-light">
                <p className={`mb-0 px-3 `} onClick={() => navigate("/gamerecordclosings15")}>Closing</p>
                <p className={`mb-0 px-3 active text-white`} onClick={() => navigate("/gamerecordtransations15")}>Trasaction</p>
                <p className={`mb-0 px-3  `} onClick={() => navigate("/gamerecordstatics15")}>Statical</p>
            </div>



            {<div className='my-2'>






                {result?.map((data, index) => <div key={index}>
                    <div className="d-flex justify-content-between px-3" onClick={() => togglePopup(index)}>
                        <div>
                            <p className='mb-2' style={{ fontSize: "11px", color: "#999" }}>PD {data.timer_id}</p>
                            {(data?.status === "0") && <p className='text-center mb-0' style={{ width: "55px", fontSize: "15px", border: "2px solid #ff2d55", color: "#ff2d55", borderRadius: "5px" }}>LOSE</p>}
                            {(data?.status === "2") && <p className='text-center' style={{ width: "55px", fontSize: "15px", border: "2px solid #20c895", color: "#20c895", borderRadius: "5px" }}>WIN</p>}
                            {(data?.status === "1") && <p className='text-center' style={{ width: "70px", fontSize: "15px", border: "2px solid #e3e129", color: "#e3e129", borderRadius: "5px" }}>Pending</p>}
                        </div>
                        <div className='text-end'>
                            <p style={{ fontSize: "11px", color: "#999" }}>{data.update_at}</p>
                            <p className='mb-0 '>
                                {
                                    // myspotc(data.spot)

                                }

                                <img src="https://cdn2.iconfinder.com/data/icons/user-interface-46/256/menu_arrow_right-01-512.png" alt="" style={{ height: "25px", width: "25px" }} />
                            </p>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>)}
            </div>}





            <div className={`popup-container withdraw  ${showPopup && 'show'}`}>
                {<div className="popup-content px-0">
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px", color: "#999" }}>
                        <p>PD {result[selectedid]?.timer_id}</p>
                        <p >{result[selectedid]?.update_at}</p>
                    </div>
                    <hr className='my-1' />

                    <div className='py-2' style={{ fontSize: "12px", lineHeight: "2" }}>

                        <p className='mb-0 px-2'>
                            Transaction amount :
                            {result[selectedid]?.amount + " " + result[selectedid]?.curr}</p>
                        <hr className='my-1 ' />
                        <p className='mb-0 px-2'>Profit and loss results : {result[selectedid]?.payable_amount + " " + result[selectedid]?.curr}</p>
                        <hr className='my-1' />
                        <p className='mb-0 px-2'>Transaction option : </p>
                    </div>
                    {(result[selectedid]?.status === "2") &&

                        <div className=' mx-2 d-flex flex-column align-items-center text-center justify-content-center' style={{ color: "green", height: "35px", fontSize: "12px", width: "70px", borderRadius: "6px", border: "green 1px solid" }}>{myspotc(result[selectedid]?.spot)}
                            <p className='mb-0 ' style={{ lineHeight: "10px", width: "25px" }}>

                                +{result[selectedid]?.payable_amount}

                            </p>
                        </div>}
                    {
                        (result[selectedid]?.status === "0") && <div className=' mx-2 d-flex flex-column align-items-center text-center justify-content-center' style={{ color: "red", height: "35px", fontSize: "12px", width: "70px", borderRadius: "6px", border: "red 1px solid" }}>{myspotc(result[selectedid]?.spot)}
                            <p className='mb-0 ' style={{ lineHeight: "10px", width: "25px" }}>0</p>
                        </div>

                    }

                    {
                        (result[selectedid]?.status === "1") && <div className=' mx-2 d-flex flex-column align-items-center text-center justify-content-center' style={{ color: "yellow", height: "35px", fontSize: "12px", width: "70px", borderRadius: "6px", border: "red 1px solid" }}>{myspotc(result[selectedid]?.spot)}
                            <p className='mb-0 ' style={{ lineHeight: "10px", width: "25px" }}>0</p>
                        </div>

                    }
                    <hr className='my-1' />

                    <p className='text-center mb-0' style={{ fontSize: "14px", color: "#999" }} onClick={() => setShowPopup(!showPopup)}>Cancel</p>
                </div>}

            </div>
        </div >
    )
}

export default Gamerecordtransaction15