import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Nav from './components/Nav';
import Carouselbanner from './components/Carouselbanner';
import Home from './components/Home';
import Bottomnav from './components/Bottomnav';
import Raferalpage from './components/Raferalpage';
import Withdraw from './components/withdrawal/Withdraw';
import { Deposit } from './components/Deposit/Deposit';
import User from './components/User';
import crypto from './components/crypto';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Rebate from './components/Rebate/Rebate';
import Referallevesls from './components/Referallevesls';
import Redemtionsetting from './components/Redemption/Redemtionsetting';
import Login from './components/Login';
import Signup from './components/Signup';
import Protectedroutes from './components/Protectedroutes';
import Forgetpass from './components/Forgetpass';
import Withdrawrecord from './components/withdrawal/Withdrawrecord';
import Redemtionexchange from './components/Redemption/Redemtionexchange';
import Myteam from './components/Team/Myteam';
import Setup from './components/setup/Setup';
import Activityaward from './components/Activity/Activityaward';
import Activity from './components/Activity/Activity';
import Gamerecordclose from './components/gamerecords/Gamerecordclose';
import Gamerecordtransaction from './components/gamerecords/Gamerecordtransaction';
import Gamerecordstatics from './components/gamerecords/Gamerecordstatics';
import Deposittrx from './components/Deposit/Deposittrx';
import Depositusdt from './components/Deposit/Depositusdt';
import Depositrecord from './components/Deposit/Depositrecord';
import Withdrawalrecorddetails from './components/withdrawal/Withdrawalrecorddetails';
import Teamtoday from './components/Team/Teamtoday';
import Teamhistory from './components/Team/Teamhistory';
import Teamcommissionhistory from './components/Team/Teamcommissionhistory';
import Teamtodaycommission from './components/Team/Teamtodaycommission';
import Attendance from './components/Attendance/Attendance';
import Activityrebate from './components/Activity/Rebate';
import Tradepage15min from './components/Games/ETH/Tradepage15min';

import Buysel1min from './components/Games/updown/game1';


import Tradepage1min from './components/Games/ETH/Tradepage1min';
import Tradepage5min from './components/Games/ETH/Tradepage5min';
import Trade1min from './components/Games/BTC/Trade1min';
import Trade15min from './components/Games/BTC/Trade15min';
import Trade5min from './components/Games/BTC/Trade5min';
import Gamesection from './components/Games/gamesection';
import CurrencyExchange from './components/CurrencyExchange';
import UpdatePassword from './components/setup/UpdatePassword';
import Updatewithdrawaladdress from './components/setup/Updatewithdrawaladdress';
import Apiloader from './components/Loader/Apiloader';
import LoadingSpinner from './components/Loader/LoadingSpinner';
import Gamerecordclosingbtc from './components/gamerecords/Gamerecordclosingbtc';
import Gamerecordtransactionbtc from './components/gamerecords/Gamerecordtransactionbtc';
import Gamerecordstaticsbtc from './components/gamerecords/Gamerecordstaticsbtc';
import Updatewpass from './components/setup/Updatewpass';
import { getWithExpiry, setWithExpiry } from './components/JS/JS';
import { useEffect } from 'react';
import Alltransaction from './components/ALLTRANSACTION/Alltransaction';
import Gifthistory from './components/ALLTRANSACTION/Gifthistory';
import Activitydetail from './components/Activity/Activitydetail';
import Activitybannerpage from './components/Activity/Activitybannerpage';
import Promotion from './components/Promotion';
import Vip from './components/Vip';
import Gamerecordclose5 from './components/gamerecords/Gamerecordclose5';
import Gamerecordclose50 from './components/gamerecords/Gamerecordclose50';
import Gamerecordtransaction5 from './components/gamerecords/Gamerecordtransaction5';
import Gamerecordstatics5 from './components/gamerecords/Gamerecordstatics5';
import Gamerecordclosingbtc5 from './components/gamerecords/Gamerecordclosingbtc5';
import Gamerecordtransactionbtc5 from './components/gamerecords/Gamerecordtransactionbtc5';
import Gamerecordstaticsbtc5 from './components/gamerecords/Gamerecordstaticsbtc5';
import Gamerecordclose15 from './components/gamerecords/Gamerecordclose15';
import Gamerecordtransaction15 from './components/gamerecords/Gamerecordtransaction15';
import Gamerecordstatics15 from './components/gamerecords/Gamerecordstatics15';
import Gamerecordclosingbtc15 from './components/gamerecords/Gamerecordclosingbtc15';
import Gamerecordtransactionbtc15 from './components/gamerecords/Gamerecordtransactionbtc15';
import Gamerecordstaticsbtc15 from './components/gamerecords/Gamerecordstaticsbtc15';


console.warn = function () { }; // Disables all warnings

<link
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css"
  integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN"
  crossorigin="anonymous"
/>

function App() {


  // useEffect(() => {
  // Check if there is data in localStorage and if it's expired
  // const storedData = getWithExpiry('myuser');
  // if (storedData) {
  //   // setData(storedData);
  // } else {
  //   // Fetch data from API or create new data
  //   const newData = { message: 'Hello, world!' };
  //   setWithExpiry('myuser', newData, 3600 * 1000); // 1 hour expiry
  //   // setData(newData);
  // }
  // }, []);


  return (
    <>
      {/* <FinancialChartComponent/> */}
      <Router>
        <div className='' style={{ minHeight: "540px", marginBottom: "60px", background:"#f0ffff", }}>
          <Routes>

            <Route path="/index" element={<Protectedroutes><Nav /><Home /><Bottomnav /></Protectedroutes>} > </Route>

            <Route path="/" element={<Protectedroutes><Nav /><Home /><Bottomnav /></Protectedroutes>} > </Route>


            {/* trade pages */}
            <Route path="/gamesection" element={<Protectedroutes><Nav />< Gamesection /><Bottomnav /></Protectedroutes>} ></Route>

            <Route path="/eth1" element={<Protectedroutes> <Tradepage1min /> </Protectedroutes>} ></Route>
            <Route path="/eth5" element={<Protectedroutes> <Tradepage5min /> </Protectedroutes>} ></Route>
            <Route path="/eth15" element={<Protectedroutes> <Tradepage15min /> </Protectedroutes>} ></Route>

            <Route path="/Buysell1" element={<Protectedroutes><Nav /> <Buysel1min /> </Protectedroutes>} ></Route>


            <Route path="/btc1" element={<Protectedroutes> <Trade1min /> </Protectedroutes>} ></Route>
            <Route path="/btc5" element={<Protectedroutes> <Trade5min /> </Protectedroutes>} ></Route>
            <Route path="/btc15" element={<Protectedroutes> <Trade15min /> </Protectedroutes>} ></Route>

            {/* game record pages */}

            <Route path="/gamerecordclosings" element={<Protectedroutes>< Gamerecordclose /></Protectedroutes>} ></Route>
            <Route path="/gamerecordtransations" element={<Protectedroutes>< Gamerecordtransaction /></Protectedroutes>} ></Route>
            <Route path="/gamerecordstatics" element={<Protectedroutes>< Gamerecordstatics /></Protectedroutes>} ></Route>

            <Route path="/gamerecordclosingsbtc" element={<Protectedroutes>< Gamerecordclosingbtc /></Protectedroutes>} ></Route>
            <Route path="/gamerecordtransationsbtc" element={<Protectedroutes>< Gamerecordtransactionbtc /></Protectedroutes>} ></Route>
            <Route path="/gamerecordstaticsbtc" element={<Protectedroutes>< Gamerecordstaticsbtc /></Protectedroutes>} ></Route>


            // <Route path="/gamerecordclosings5" element={<Protectedroutes>< Gamerecordclose5 /></Protectedroutes>} ></Route>
           
            // <Route path="/gamerecordclosings115" element={<Protectedroutes>< Gamerecordclose5 /></Protectedroutes>} ></Route>
           
           
            // <Route path="/gamerecordtransations115" element={<Protectedroutes>< Gamerecordtransaction5 /></Protectedroutes>} ></Route>
            // <Route path="/gamerecordstatics115" element={<Protectedroutes>< Gamerecordstatics5 /></Protectedroutes>} ></Route>



 <Route path="/gamerecordclosings115" element={<Protectedroutes>< Gamerecordclose5 /></Protectedroutes>} ></Route>
            <Route path="/gamerecordtransations115" element={<Protectedroutes>< Gamerecordtransaction5 /></Protectedroutes>} ></Route>
            <Route path="/gamerecordstatics115" element={<Protectedroutes>< Gamerecordstatics5 /></Protectedroutes>} ></Route>F



            <Route path="/gamerecordclosingsbtc5" element={<Protectedroutes>< Gamerecordclosingbtc5 /></Protectedroutes>} ></Route>
            <Route path="/gamerecordtransationsbtc5" element={<Protectedroutes>< Gamerecordtransactionbtc5 /></Protectedroutes>} ></Route>
            <Route path="/gamerecordstaticsbtc5" element={<Protectedroutes>< Gamerecordstaticsbtc5 /></Protectedroutes>} ></Route>


            <Route path="/gamerecordclosings15" element={<Protectedroutes>< Gamerecordclose15 /></Protectedroutes>} ></Route>
            <Route path="/gamerecordtransations15" element={<Protectedroutes>< Gamerecordtransaction15 /></Protectedroutes>} ></Route>
            <Route path="/gamerecordstatics15" element={<Protectedroutes>< Gamerecordstatics15 /></Protectedroutes>} ></Route>

            <Route path="/gamerecordclosingsbtc15" element={<Protectedroutes>< Gamerecordclosingbtc15 /></Protectedroutes>} ></Route>
            <Route path="/gamerecordtransationsbtc15" element={<Protectedroutes>< Gamerecordtransactionbtc15 /></Protectedroutes>} ></Route>
            <Route path="/gamerecordstaticsbtc15" element={<Protectedroutes>< Gamerecordstaticsbtc15 /></Protectedroutes>} ></Route>



            {/* <Route path="/promotion" element={<Protectedroutes> <Nav /><Promotion /><Bottomnav /></Protectedroutes>} ></Route> */}
            {/* <Route path="/raferalpage" element={<Protectedroutes> <Nav /><Raferalpage /></Protectedroutes>} ></Route> */}
            <Route path="/promotion" element={<Protectedroutes> <Nav /><Raferalpage /><Bottomnav/></Protectedroutes>} ></Route>

            <Route path="/user" element={<Protectedroutes><Nav /> <User /><Bottomnav /></Protectedroutes>} ></Route>

            <Route path="/crypto" element={<Protectedroutes><Nav /> <crypto /><Bottomnav /></Protectedroutes>} ></Route>

            <Route path="/setup" element={<Protectedroutes> <Nav /> <Setup /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/rebate" element={<Protectedroutes> <Nav /><Rebate /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/referallevel" element={<Protectedroutes><Nav /> <Referallevesls /><Bottomnav /></Protectedroutes>} ></Route>
            {/* <Route path="/trade2" element={<Protectedroutes> <Tradepage2 /> </Protectedroutes>} ></Route> */}
            <Route path="/Login" element={<Login />} ></Route>

            <Route path="/signup" element={<Signup />} ></Route>



            <Route path="/forgetpass" element={<Forgetpass />} ></Route>
            {/* <Route path="/trades" element={< Trade />} ></Route> */}



            {/* Redemtion */}
            <Route path="/redemtionsystem" element={<Protectedroutes><Nav /> <Redemtionsetting /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/redemtionexchange" element={<Protectedroutes><Nav /> <Redemtionexchange /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/currencyexchange" element={<Protectedroutes><Nav /> <CurrencyExchange /><Bottomnav /></Protectedroutes>} ></Route>

            {/* team */}
            <Route path="/team" element={<Protectedroutes><Nav />< Myteam /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/teamtoday" element={<Protectedroutes><Nav />< Teamtoday /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/teamhistory" element={<Protectedroutes><Nav />< Teamhistory /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/teamcommissionhistory" element={<Protectedroutes><Nav />< Teamcommissionhistory /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/teamtodaycommission" element={<Protectedroutes><Nav />< Teamtodaycommission /><Bottomnav /></Protectedroutes>} ></Route>

            {/* Activity */}
            <Route path="/activityaward" element={<Protectedroutes><Nav /><Activityaward /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/activity" element={<Protectedroutes><Nav /><Activity /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/activityrebate" element={<Protectedroutes><Nav /><Activityrebate /><Bottomnav /></Protectedroutes>} ></Route>

            <Route path="/vip" element={<Protectedroutes><Vip /><Bottomnav /></Protectedroutes>} ></Route>

            <Route path="/activityrules" element={<Protectedroutes><Nav /><Activitydetail /><Bottomnav /></Protectedroutes>} ></Route>


            <Route path="/activitydetail" element={<Protectedroutes><Nav /><Activitybannerpage /><Bottomnav /></Protectedroutes>} ></Route>

            {/* deposit */}
            <Route path="/depositpage" element={<Protectedroutes> <Nav /><Deposit /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/deposittrx" element={<Protectedroutes> <Nav /><Deposittrx /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/depositusdt" element={<Protectedroutes> <Nav /><Depositusdt /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/depositrecord" element={<Protectedroutes> <Nav /><Depositrecord /><Bottomnav /></Protectedroutes>} ></Route>




            {/* withdrawal */}

            <Route path="/withdraw" element={<Protectedroutes> <Nav /><Withdraw /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/withdrawrecord" element={<Protectedroutes> <Nav /><Withdrawrecord /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/withdrawalrecorddetail" element={<Protectedroutes><Nav /> <Withdrawalrecorddetails /><Bottomnav /></Protectedroutes>} ></Route>

            <Route path="/gifthistory" element={<Protectedroutes> <Nav /><Gifthistory /><Bottomnav /></Protectedroutes>} ></Route>

            {/* attendance */}

            <Route path="/mining" element={<Protectedroutes><Nav />< Attendance /><Bottomnav /></Protectedroutes>} ></Route>

            {/* setup */}

            <Route path="/updatepass" element={<Protectedroutes><Nav />< UpdatePassword /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/updatewpass" element={<Protectedroutes><Nav />< Updatewpass /><Bottomnav /></Protectedroutes>} ></Route>
            <Route path="/updatewithdraw" element={<Protectedroutes><Nav />< Updatewithdrawaladdress /><Bottomnav /></Protectedroutes>} ></Route>



            <Route path="/apiloader" element={<LoadingSpinner />} ></Route>

            <Route path="/user/alltransaction" element={<Protectedroutes><Alltransaction /></Protectedroutes>} ></Route>

            <Route path="*" element={<Navigate to={"/index"} />}></Route>


          </Routes>
        </div>
      </Router>
    </>
    // <div className="App" >

    //   {/* <Carouselbanner/>
    //   <Home/> */}
    //   {/* <Raferalpage/> */}
    //   {/* <Withdraw/> */}
    //   {/* <Deposit/> */}
    //   <User/>

    // </div>
  );
}

export default App;
