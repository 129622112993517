// import env from "dotenv";

// env.config();
// const baseurl = process.env.NEXT_PUBLIC_SERVER_URL;
// const baseurl ="https://ecom-backend-z87b.onrender.com" ;
// const baseurl = "https://tronapi.tronvid.site/";
const baseurl = "https://tronapi.tronvid.site/";


export const apiEndPointt = {

    // cart apis////////////
    BASEURL: baseurl,
    LOGIN: baseurl + "login",
    SIGNUP: baseurl + "register",
    VERIFYUSER: baseurl + "checkuser",
    USERPROFILE: baseurl + "user/userprofile",
    UPDATEACCOUNTSTATUS: baseurl + "user/update",
    ADMIN_SIGNIN: baseurl + "user/adminlogin",
    USER_ALLTRANSACTION:baseurl+"/alltransactions",
    GIFT_HISTORY:baseurl+"/gifthistory"

}