// import React, { useState } from 'react';
// import { Network, TatumSDK } from "@tatumio/tatum";
// import LoadingSpinner from '../Loader/LoadingSpinner';
// import process from 'process';

// const crypto = ({ address }) => {
//   const [balance, setBalance] = useState("");
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");

//   const getBalance = async () => {
//     setLoading(true);
//     setError(null);
//     setBalance(null);

//     const tatum = await TatumSDK.init({
//       network: Network.ETHEREUM,
//       apiKey: {
//         v4: "t-66a0beb6a2f9a0001ca5561f-572efc634ee24442834fc631"
//       }
//     });

//     try {
//       const bal = await tatum.address.getBalance({
//         addresses: [address],
//         tokenTypes: ["native"],
//       });

//       if (!bal || !bal.data || !bal.data[0]) {
//         const err = bal.error && bal.error.message[0];
//         setError(err || "Unknown error");
//       } else {
//         setBalance(`${bal.data[0].balance} ${bal.data[0].asset}`);
//       }
//     } catch (e) {
//       setError(JSON.stringify(e));
//     }

//     tatum.destroy();
//     setLoading(false);
//   };

//   return (
//     <div className="crypto-balance-fetcher">
//       <button disabled={loading} onClick={getBalance}>GET BALANCE</button>
//       <div className="result">
//         {loading && <LoadingSpinner />}
//         {balance && <h2 id="balance">{balance}</h2>}
//         {error && <div id="error">{error}</div>}
//       </div>
//     </div>
//   );
// };

// export default crypto;
