// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.activity_rebate_cards {
    background: #151c26;
    border-radius: 5px;
    padding: .32rem;
    color: #fff;
    box-shadow: 0 .04rem .08rem .01333rem #00000029;
}

.activity_rebate_cards .realtimecount {
    border: 1px solid #fa2367;
    border-radius: 5px;
    text-align: center;
    width: 80px;
    font-size: 9px;
    color: #fa2367;
}

.activity_rebate_cards .upgradebox {
    /* margin-bottom: .2rem; */
    width: 80%;
    line-height: .4rem;
    padding: 6px;
    font-size: 10px;
    border-radius: .13333rem;
    color: #fa2367;
    position: relative;
    background: rgba(112, 112, 112, .2);
}
.activity_rebate_cards .btnss {
    width: 100%;
    text-align: center;
    background: #bcbfd5;
    border-radius: 5px;
    font-size: 13px;
    color: #fff;
    border: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Activity/activityrebate.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,+CAA+C;AACnD;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,kBAAkB;IAClB,WAAW;IACX,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,0BAA0B;IAC1B,UAAU;IACV,kBAAkB;IAClB,YAAY;IACZ,eAAe;IACf,wBAAwB;IACxB,cAAc;IACd,kBAAkB;IAClB,mCAAmC;AACvC;AACA;IACI,WAAW;IACX,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,eAAe;IACf,WAAW;IACX,YAAY;AAChB","sourcesContent":[".activity_rebate_cards {\r\n    background: #151c26;\r\n    border-radius: 5px;\r\n    padding: .32rem;\r\n    color: #fff;\r\n    box-shadow: 0 .04rem .08rem .01333rem #00000029;\r\n}\r\n\r\n.activity_rebate_cards .realtimecount {\r\n    border: 1px solid #fa2367;\r\n    border-radius: 5px;\r\n    text-align: center;\r\n    width: 80px;\r\n    font-size: 9px;\r\n    color: #fa2367;\r\n}\r\n\r\n.activity_rebate_cards .upgradebox {\r\n    /* margin-bottom: .2rem; */\r\n    width: 80%;\r\n    line-height: .4rem;\r\n    padding: 6px;\r\n    font-size: 10px;\r\n    border-radius: .13333rem;\r\n    color: #fa2367;\r\n    position: relative;\r\n    background: rgba(112, 112, 112, .2);\r\n}\r\n.activity_rebate_cards .btnss {\r\n    width: 100%;\r\n    text-align: center;\r\n    background: #bcbfd5;\r\n    border-radius: 5px;\r\n    font-size: 13px;\r\n    color: #fff;\r\n    border: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
