import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Activitybannerpage = () => {
    const location = useLocation();
    const [pageloader, setPageloader] = useState(true);
    const imgurl = location.state?.imgurl;
    let imgurl2 = '';

    useEffect(() => {
        const timer = setTimeout(() => {
            setPageloader(false);
        }, 500);

        return () => clearTimeout(timer);
    }, []);

    switch (imgurl) {
        case '/posters/1.png':
            imgurl2 = '/phoneposter/3.png';
            break;
        case '/posters/2.png':
            imgurl2 = '/phoneposter/2.png';
            break;
        case '/posters/3.png':
            imgurl2 = '/phoneposter/1.png';
            break;
        case '/posters/5.png':
            imgurl2 = '/phoneposter/4.png';
            break;
            
       
        default:
            imgurl2 = '';
    }

    return (
        <div style={{ backgroundColor:"#f0ffff", minHeight: "85vh" }}>
            {pageloader && <LoadingSpinner />}
            {imgurl && <img src={imgurl} alt="" style={{ width: "100%", height: "180px" }} />}
            <p
                className="text-center bolder"
                style={{
                    fontSize: "15px",
                    color: "#1b434c",
                    fontWeight: 800
                }}
            >
                
                Affilated Program BBC TRADING
                
            </p>

            <div className="p-3">
                {imgurl2 && <img src={imgurl2} alt="" style={{ height: "100%", width: "100%" }} />}
                <p className='bolder'>Check Out Our Site : <a href='https://m.bbcusdt.com/'>https://m.bbcusdt.com/</a></p>
            </div>
        </div>
    );
}

export default Activitybannerpage;
