import axios, { toFormData } from 'axios';
import { elements } from 'chart.js';
import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { ArrowLeft, Calendar, Calendar2, Link45deg } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../../JS/JS';
import LoadingSpinner from '../../Loader/LoadingSpinner'


// eslint-disable-next-line no-undef
const Tradepage1min = () => {

    const navigate = useNavigate();
    const [timenav, settimenav] = useState(1)

    const [pageloader, setpageloader] = useState(false)

    // const [trxresult, settrxresult] = useState()

    const [showPopup, setShowPopup] = useState(false);

    const [showPopupname, setShowPopupname] = useState("");


    const [betvalues, setbetvalues] = useState([])
    const [secondbetvalues, setsecondbetvalues] = useState([])

    const [currency, setcurrency] = useState("TRX")
    const [amount, setamount] = useState(0)
    const [totalbet, settotalbet] = useState(0)

    const [intervalId, setIntervalId] = useState();
    const [intervalResponse, setIntervalResponse] = useState();




    const [apiresponsestate, setapiresponsestate] = useState();
    const [usdtbalance, setusdtbalance] = useState();
    const [trxbalance, settrxbalance] = useState();
    const [periods, setperiods] = useState();
    const [resultNumb, setResultNumb] = useState();



    useEffect(() => {
        var tbet = amount * betvalues.length;
        settotalbet(parseFloat(tbet).toFixed(4));
    }, [amount, betvalues]);






    const checkuser = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token: getWithExpiry("myuser") }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.status === false) {
            navigate("/login")
        } else {
            setusdtbalance(parseFloat(data.data.userdata.usdt).toFixed(4))
            settrxbalance(parseFloat(data.data.userdata.trx).toFixed(4))
        }
        setpageloader(false)
    }


    const applybet = async () => {
        if (amount < 0.1) {
            handleClose()
            closeform()

            unimsg.textContent = "Bet can't be less then 10";
            unimodal.style.display = 'block';

            setTimeout(() => {
                unimodal.style.display = 'none';
            }, 1500);
        } else {
            setpageloader(true)
            handleClose()

            let formdata = {
                token: getWithExpiry("myuser"),
                select: betvalues,
                currency: currency,
                amount: amount,
                totalbet: amount * betvalues.length,
                time: 1
            }

            let data = await axios.post('https://tronapi.tronvid.site/bet_eth', formdata, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            closeform()
            // setapiresponsestate(data.data.message)
            // setresponsepopup("zoominresponse")

            const unimodal = document.getElementById('unimodal');
            const unimsg = document.getElementById('unimsg');

            unimsg.textContent = data.data.message;
            unimodal.style.display = 'block';

            setTimeout(() => {
                unimodal.style.display = 'none';
            }, 1500);


            checkuser();

            setpageloader(false)
        }
    }



    const togglePopup = (value) => {
        setShowPopupname(value)
        setShowPopup(!showPopup);
    };


    const clearform = () => {
        setbetvalues([]);
        setamount(10);
        setcurrency("TRX");
    }
    const closeform = () => {
        togglePopup();
        setbetvalues([]);
        setamount(10);
        setcurrency("TRX");
    }

    const handlebetclick1 = (value) => {
        // alert(value)
        // setbetvalues(prevItems => {
        //     if (prevItems.includes(value)) {
        //         return prevItems.filter(i => i !== value);
        //     } else {
        //         return [...prevItems, value];
        //     }
        // });
        setbetvalues([value])
        togglePopup("trx")
    }


    const handleamount = (value) => {

        setamount(Number(value))
    }


    const updateperiod = async () => {
        try {

            setpageloader(true)
            let response = await axios.post('https://tronapi.tronvid.site/getperiodeth', { interval: 1 }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            let data = response.data;

            if (response.status === 200 && data.status === 'success') { // Ensure the status check matches the API response


                var element1 = document.getElementById('lastissue');


                setperiods(data.currentperiod.uid);
                setResultNumb(data.lastissue.number);

                element1.innerHTML = data.lastissue.price;
                checkuser();
                setpageloader(false)

            }
        } catch (error) {
            console.error('Error updating period:', error);
        }
    }







    const startcount1 = async (intervalResponse) => {

        clearInterval(intervalId);


        const newIntervalId = setInterval(() => {
            const countDownDate = Date.parse(new Date()) / 1e3;
            const gametime = intervalResponse * 60;
            const now = new Date().getTime();
            const distance = gametime - countDownDate % gametime;

            const minutes = Math.floor(distance / 60);
            const seconds = distance % 60;

            const minutesString = ('0' + minutes).slice(-2);
            const secondsString = ('0' + Math.floor(seconds)).slice(-2);

            const secondsHTML = "<span class='timer'>" + secondsString[0] + "</span>" + "<span class='timer-e'>" + secondsString[1] + "</span>";
            const minutesHTML = "<span class='timer-s'>" + minutesString[0] + "</span>" + "<span class='timer'>" + minutesString[1] + "</span>";

            const timerDisplay = minutesHTML + "<span style='color: #b8a263 !important;'>:</span>" + secondsHTML;

            document.getElementById("timer-one").innerHTML = timerDisplay;

            if (distance == 58) {
                updateperiod();
                // checkuser();

            }

        }, 1000);

        setIntervalId(newIntervalId);
    };

    useEffect(() => {

        startcount1(1);
        changeInterval(1);
        updateperiod();

        return () => clearInterval();  // Clear interval on component unmount
    }, [intervalResponse]); // Re-run effect when intervalResponse changes


    // Array to store all interval IDs
    const intervalIds = [];

    // Save the original setInterval function
    const originalSetInterval = window.setInterval;

    // Override the setInterval function
    window.setInterval = function (callback, delay) {
        // Call the original setInterval function and get the interval ID
        const intervalId = originalSetInterval(callback, delay);
        // Store the interval ID in the array
        intervalIds.push(intervalId);
        // Return the interval ID
        return intervalId;
    };

    // Function to get all interval IDs
    function getAllIntervalIds() {
        return intervalIds;
    }

    // Function to clear all intervals
    function clearAllIntervals() {
        intervalIds.forEach(intervalId => clearInterval(intervalId));
        // Optionally, clear the intervalIds array after clearing all intervals
        intervalIds.length = 0;
    }

    // Example usage
    const intervalId1 = setInterval(() => { }, 1000);
    const intervalId2 = setInterval(() => { }, 2000);


    // Clear all intervals
    clearAllIntervals();
    // let intervalID;


    const handletimernav = (interval) => {
        settimenav(interval);

        setIntervalResponse(interval);
        navigate("/eth" + interval);

    }
    const handlerecordnavigation = () => {
        sessionStorage.setItem('selectedintervel', 1);
        navigate("/gamerecordclosings")

    }


    const [showModal, setShowModal] = useState(false);
    const [animateOut, setAnimateOut] = useState(false);

    const handleShow = () => {
        setAnimateOut(false);
        setShowModal(true);
    };

    const handleClose = () => {
        setAnimateOut(true);
        setTimeout(() => setShowModal(false), 300); // Match this duration with the animation duration
    };

    const [responsepopup, setresponsepopup] = useState("apiresponsepopup ")


    const [liveprice, setliveprice] = useState();


    const loadlivevalue = async () => {


        try {
            let data = await axios.get('https://tronapi.tronvid.site/ethprice ', {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (data.data.status === "success") {
                setliveprice(data.data.ethprice)

                setTimeout(() => {
                    loadlivevalue();
                }, 15000);
            }


        } catch (error) {
        }


    }

    useEffect(() => {
        checkuser()
        loadlivevalue();
    }, [])



    function formatValues(values) {
        return values.join(', ');
    }


    const [result, setresult] = useState([]);


    const fetchclosingrecords = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/getethhistory', { interval: 1, token: getWithExpiry("myuser"), game_type: `eth${1}` }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data?.data?.message === "No records found") {
            setresult([])
        } else {
            setresult(data?.data?.btchistory)
        }
        setpageloader(false)
    }


    useEffect(() => {
        fetchclosingrecords();
    }, [])

    return (
        <div style={{ minHeight: "80vh" }}>

            {pageloader && <LoadingSpinner />}

            <div className={`row justify-content-center align-items-center ${responsepopup} `} onClick={() => setresponsepopup("zoomoutresponse")} style={{ position: "absolute", width: "100%", height: "75%", zIndex: "1056" }}>
                <p className="  text-white px-2 mb-0 " style={{ background: "#000000b5", borderRadius: "5px", fontSize: "17px", fontWeight: "300", width: "auto", margin: "0" }}>{apiresponsestate}</p>
            </div>


            {/* ////////////////   trade confirm box */}
            <div className='d-none justify-content-center align-item-center' style={{ width: "100%" }} >
                <Modal
                    show={showModal}
                    onHide={handleClose}
                    centered
                    size="sm"
                    className={`d-flex justify-content-center ${animateOut ? 'modal-zoom-out' : 'modal-zoom-in'}`}
                    style={{ width: "100%" }}
                >
                    {/* <Modal.Header closeButton>
                        <Modal.Title>Modal title</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body >
                        <div className="d-flex justify-content-center text-center" style={{ width: "100%" }}>
                            <p className="mx-auto text-center" style={{ width: "200px" }}>
                                Spend {totalbet} {currency} to trade "{formatValues(betvalues)}"</p>
                        </div>

                        <div className="d-flex justify-content-between">
                            <p className='m-0 px-3 py-1' style={{ border: "1px solid #74dbab", borderRadius: "6px" }}
                                onClick={handleClose}> Cancel</p>
                            <p className='m-0 px-3 py-1 linear_gradiend_green text-white' style={{ border: "0px solid black", borderRadius: "6px" }} onClick={applybet}>Trade</p>
                        </div>

                    </Modal.Body>

                </Modal>
            </div>









            <div id="bgimgdiv">


                <div className="timenavbar">
                    <div className="d-flex justify-content-between align-items-center p-2" style={{ borderBottom: "2px solid white" }}>
                        <div className="d-flex align-items-center">

                            <ArrowLeft onClick={() => navigate("/gamesection")} />
                            <img src="/ethlogo.png" style={{ width: "25px" }} alt="" />
                            <p className="mb-0 mx-1">ETH/USDT</p>
                        </div>
                        <p className="mb-0 text-danger ">{liveprice}</p>
                    </div>
                    <div className="d-flex justify-content-around text-center align-items-center "
                        style={{ fontWeight: 600, backgroundSize: '100% 100%', backgroundRepeat: 'no-repeat', borderBottom: '2px solid #e4eee9', borderTop: '2px solid #e4eee9' }}>
                        <p className={`my-1 ${(timenav === 1) && "timerstyle"}`} onClick={() => handletimernav(1)}><span>1 min</span></p>
                        <p className={`my-1 ${(timenav === 5) && "timerstyle"}`} onClick={() => handletimernav(5)}><span>5 min</span></p>
                        <p className={`my-1 ${(timenav === 15) && "timerstyle"}`} onClick={() => handletimernav(15)}><span>15 min</span></p>
                    </div>
                </div>




                <div id="chartbg" className='my-1' style={{ height: "45vh" }}>
                    <div id="chart-container" >
                        <canvas id="cdskchart" />
                    </div>

                </div>

            </div>




            <div className={`popup-container withdraw ${showPopup && 'show'}`}>
                {(showPopupname === "trx") && <div className="popup-content">
                    <span style={{ fontSize: "11px" }} id="pdp"> PD{(periods)}</span>
                    <hr className='my-1' />
                    <button className="close-btn" onClick={togglePopup}>X</button>
                    {/* {children} */}
                    {/* <span style={{ fontSize: "13px" }}>choose:-</span> */}


                    {/* 
                    <div className="d-flex justify-content-around text-center">
                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(0) && "selectboxmulti1"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", borderLeft: "red 2px solid", borderTop: "red 2px solid", borderRight: "blue 2px solid", borderBottom: "blue 2px solid" }} onClick={() => handlebetclick1(0)} >0</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(1) && "selectboxgreen"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1(1)}>1</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(2) && "selectboxred"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1(2)}>2</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(3) && "selectboxgreen"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1(3)}>3</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(4) && "selectboxred"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1(4)}>4</div><span>9.5</span></div>
                    </div>

                    <div className="d-flex justify-content-around my-1 text-center">
                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(5) && "selectboxmulti2"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", borderLeft: "green 2px solid", borderTop: "green 2px solid", borderRight: "blue 2px solid", borderBottom: "blue 2px solid" }} onClick={() => handlebetclick1(5)}>5</div><span>9.5</span></div>
                       
                        <div style={{ fontSize: "12px" }}> <div className={`${betvalues.includes(6) && "selectboxred"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1(6)}>6</div><span>9.5</span></div>
                       
                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(7) && "selectboxgreen"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1(7)}>7</div><span>9.5</span></div>
                       
                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(8) && "selectboxred"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1(8)}>8</div><span>9.5</span></div>
                       
                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(9) && "selectboxgreen"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1(9)}>9</div><span>9.5</span></div>
                    </div>

                    <div className="d-flex justify-content-between my-1 py-2 text-center" style={{ fontSize: "10px", lineHeight: "1.5" }}>
                        <div className={`col-4 p-1 ${betvalues.includes("red") && "selectboxred"}`} style={{ width: "30%", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1("red")}>
                            <p className='mb-0'>RED</p>
                            <p className='mb-0'>1.95</p>
                        </div>
                        <div className={`col-4 p-1 ${betvalues.includes("purple") && "selectboxpurple"}`} style={{ width: "30%", borderRadius: "6px", border: "purple 2px solid" }} onClick={() => handlebetclick1("purple")}>
                            <p className='mb-0'>PURPLE</p>
                            <p className='mb-0'>4.87</p>
                        </div>
                        <div className={`col-4 p-1 ${betvalues.includes("green") && "selectboxgreen"}`} style={{ width: "30%", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1("green")}>
                            <p className='mb-0'>GREEN</p>
                            <p className='mb-0'>1.95</p>
                        </div>
                    </div> */}





                    {/* <hr className='my-1' /> */}
                    <div className="row">
                        <div className="col-md-1 col-4 tradefont">Currency :</div>

                        <div className="col-md-11 col-6  " style={{ fontSize: "12px" }}>
                            <div className='d-flex align-items-center'>

                                <div className="form-check form-check-inline">
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="TRXfield"
                                        value="TRX"
                                        defaultChecked
                                        onChange={(e) => setcurrency(e.target.value)}
                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio1">
                                        TRX
                                    </label>
                                </div>
                                <div className="form-check form-check-inline" style={{ fontSize: "12px" }}>
                                    <input
                                        className="form-check-input"
                                        type="radio"
                                        name="inlineRadioOptions"
                                        id="Usdtfield"
                                        value="USDT"
                                        onChange={(e) => setcurrency(e.target.value)}

                                    />
                                    <label className="form-check-label" htmlFor="inlineRadio2">
                                        USDT
                                    </label>
                                </div>
                            </div>
                        </div>
                        <hr className='mb-0' />
                        <div className="col-md-1 col-4 tradefont">Amount :</div>
                        <div className="col-md-11 col-6 tradefont d-flex align-items-center">
                            <input type="number" min="10" placeholder={"Enter Amount"} onChange={(e) => handleamount(e.target.value)} className='popupinput px-2' style={{ width: "100%", height: "20px" }} />
                        </div>
                        <hr className='mb-0' />
                        <div className="col-md-1 col-4 tradefont">Total Bet :</div>
                        <div className="col-md-11 col-6 tradefont">  {totalbet} {currency}</div>
                        <hr className='mb-0' />
                        <div className="col-md-1 col-4 tradefont">Total Balance :</div>
                        <div className="col-md-11 col-6 tradefont">{(currency === "TRX") ? (trxbalance + " " + currency) : (usdtbalance + " " + currency)}</div>
                        <hr className='mb-0' />






                        <p
                            type="submit"
                            className='text-center my-2 linear_gradiend_green'
                            style={{ width: "100%", borderRadius: "6px", fontSize: "12px", border: "none", padding: "5px 20px", fontWeight: "600", color: "black" }}
                            onClick={handleShow}
                        >
                            Confirm
                        </p>

                        <div className="d-flex justify-content-between">

                            <p
                                type="submit"
                                className='text-center mb-0'
                                style={{ width: "49%", border: "1px solid black", borderRadius: "6px", fontSize: "12px", padding: "5px 20px", fontWeight: "600", color: "black" }}
                                onClick={clearform}
                            >
                                Clean Up
                            </p>
                            <p
                                type="submit"
                                className='text-center mb-0'
                                style={{ width: "49%", border: "1px solid black", borderRadius: "6px", fontSize: "12px", padding: "5px 20px", fontWeight: "600", color: "black" }}
                                onClick={closeform}
                            >
                                Closure
                            </p>

                        </div>




                    </div>

                </div>}

            </div>

            {/* twilio */}

            <div className="bg-light " style={{}}>
                <div className="row px-3">




                    <div className=" tradefont">

                        <p>Last issue:</p>
                        <span id="lastissue" style={{ margin: '0px 20px' }}>

                        </span>
                    </div>
                    <hr className='my-1' />
                    <div className=" tradefont align-items-center" > <p>Result:</p>
                        <div id="preslt"

                            style={{
                                background: resultNumb ? `url(./result/eth${resultNumb}.png) ` : 'none',

                                width: '26px',
                                height: '26px',
                                margin: '0px 38px'
                            }}
                        >

                        </div>
                        <Calendar2 className="ms-auto" onClick={handlerecordnavigation} />


                    </div>
                    <hr className='my-1' />



                    <div className=" tradefont" style={{ justifyContent: 'space-between' }}>
                        <p style={{ display: 'flex', justifyContent: 'space-between', width: '230px' }}>Balance :

                            <div style={{ display: 'flex', flexDirection: 'column', fontSize: '9px', height: '90%', lineHeight: '0', alignItems: 'center', margin: '0px' }}>
                                <span id="balance" style={{ lineHeight: '0', height: '50%', display: 'flex', alignItems: 'center' }}>{trxbalance} TRX</span>
                                <span id="balanceu" style={{ lineHeight: '0', height: '50%', display: 'flex', alignItems: 'center' }}>{usdtbalance} USDT</span>
                            </div>

                            <span id="prd"><span>PD</span>{(periods)}</span>
                        </p>

                        <div>
                            <span id="timer-one"></span>
                        </div>



                    </div>

                    <hr className='my-1' />
                    <span style={{ fontSize: "13px" }}>choose:-</span>

                    <div className="d-flex justify-content-around text-center mt-2">
                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(0) && "selectboxmulti1"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", borderLeft: "red 2px solid", borderTop: "red 2px solid", borderRight: "blue 2px solid", borderBottom: "blue 2px solid" }} onClick={() => handlebetclick1(0)} >0</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(1) && "selectboxgreen"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1(1)}>1</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(2) && "selectboxred"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1(2)}>2</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(3) && "selectboxgreen"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1(3)}>3</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(4) && "selectboxred"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1(4)}>4</div><span>9.5</span></div>
                    </div>

                    <div className="d-flex justify-content-around my-1 text-center">
                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(5) && "selectboxmulti2"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", borderLeft: "green 2px solid", borderTop: "green 2px solid", borderRight: "blue 2px solid", borderBottom: "blue 2px solid" }} onClick={() => handlebetclick1(5)}>5</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}> <div className={`${betvalues.includes(6) && "selectboxred"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1(6)}>6</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(7) && "selectboxgreen"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1(7)}>7</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(8) && "selectboxred"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1(8)}>8</div><span>9.5</span></div>

                        <div style={{ fontSize: "12px" }}><div className={`${betvalues.includes(9) && "selectboxgreen"}`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1(9)}>9</div><span>9.5</span></div>
                    </div>

                    <div className="d-flex justify-content-between my-1 py-2 text-center" style={{ fontSize: "10px", lineHeight: "1.5" }}>
                        <div className={`col-4 p-1 ${betvalues.includes("red") && "selectboxred"}`} style={{ width: "30%", borderRadius: "6px", border: "red 2px solid" }} onClick={() => handlebetclick1("red")}>
                            <p className='mb-0'>RED</p>
                            <p className='mb-0'>1.95</p>
                        </div>
                        <div className={`col-4 p-1 ${betvalues.includes("purple") && "selectboxpurple"}`} style={{ width: "30%", borderRadius: "6px", border: "purple 2px solid" }} onClick={() => handlebetclick1("purple")}>
                            <p className='mb-0'>PURPLE</p>
                            <p className='mb-0'>4.87</p>
                        </div>
                        <div className={`col-4 p-1 ${betvalues.includes("green") && "selectboxgreen"}`} style={{ width: "30%", borderRadius: "6px", border: "green 2px solid" }} onClick={() => handlebetclick1("green")}>
                            <p className='mb-0'>GREEN</p>
                            <p className='mb-0'>1.95</p>
                        </div>
                    </div>






                    {/* <hr className='my-1' /> */}


                    {/* <div className="d-flex align-items-center py-2 bg-light" style={{ borderRadius: "10px 10px 0px 0px", position: "fixed", bottom: '0px' }}>
                        <button
                            type="submit"
                            className='m-2 linear_gradiend_green'
                            style={{ width: "80%", borderRadius: "6px", border: "none", padding: "5px 20px", fontWeight: "600", color: "black" }}
                            onClick={() => togglePopup("trx")}
                        >
                            Buy
                        </button>
                        <Calendar2 className="mx-2" onClick={handlerecordnavigation} />
                    </div> */}



                    <div className='container-fluid '>
                        <hr className='my-1' />
                        <div className="row text-center px-2 py-0 d-flex justify-content-between" style={{ fontSize: "11px", color: "#999999", textAlign: "center", fontWeight: "600" }}>
                            <div className="px-0 col-3">Period</div>
                            <div className="px-0 col-3">Last Issue</div>
                            <div className="px-0 col-4">Result</div>
                           
                        </div>
                        <hr className='my-1' />

                    </div>



                    {result?.slice(0, 8).map((data, index) => <div key={index}>
                        <div className='d-flex justify-content-between px-2'>
                            <div className='col-3 text-center d-flex align-items-center justify-content-center'>
                                <p style={{ fontSize: "11px", color: "#999" }}>{data.uid}</p>
                            </div>
                            <div className='col-3 text-center d-flex align-items-center justify-content-center'>

                                <p className='mb-0' style={{ fontSize: "13px", fontWeight: "600", color: "black" }}>{data.price}</p>
                            </div>
                            <div className='col-4 text-center  recordsss d-flex text-center justify-content-center'>

                                <div className="d-flex justify-content-end text-center me-2">
                                    {(data?.color === "big") && <div className=' d-flex align-items-center justify-content-center big' >Big</div>}
                                    {(data?.color === "small") && <div className=' mx-2 d-flex align-items-center justify-content-center small' >Small</div>}
                                    {(data?.color2 === "single") && <div className=' mx-2 d-flex align-items-center justify-content-center single' >Single</div>}


                                    {(data?.color2 === "double") && <div className=' mx-2 d-flex align-items-center justify-content-center double' >Double</div>}


                                    {(data?.number === "0") && <div className={`selectboxmulti1`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", borderLeft: "red 2px solid", borderTop: "red 2px solid", borderRight: "blue 2px solid", borderBottom: "blue 2px solid" }} >0</div>}

                                    {(data?.number === "1") && <div className={`selectboxgreen`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }}>1</div>}

                                    {(data?.number === "2") && <div className={`selectboxred`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }}>2</div>}

                                    {(data?.number === "3") && <div className={`selectboxgreen`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }}>3</div>}

                                    {(data?.number === "4") && <div className={`selectboxred`} style={{ fontSize: "12px", alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }}>4</div>}


                                    {(data?.number === "5") && <div className={`selectboxmulti2`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", borderLeft: "green 2px solid", borderTop: "green 2px solid", borderRight: "blue 2px solid", borderBottom: "blue 2px solid" }}>5</div>}

                                    {(data?.number === "6") && <div className={`selectboxred`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }}>6</div>}

                                    {(data?.number === "7") && <div className={`selectboxgreen`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }}>7</div>}

                                    {(data?.number === "8") && <div className={`selectboxred`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "red 2px solid" }}>8</div>}

                                    {(data?.number === "9") && <div className={`selectboxgreen`} style={{ alignContent: "center", height: "25px", width: "25px", borderRadius: "6px", border: "green 2px solid" }}>9</div>}

                                    {/* {<div className=' d-flex align-items-center justify-content-center' style={{ color: "green", height: "25px", fontSize: "10px", width: "50px", borderRadius: "5px", borderLeft: "red 2px solid", border: "green 2px solid", borderBottom: "blue 2px solid" }}>
                                    <span className='mb-0 ' style={{ lineHeight: "10px", width: "25px" }}> Big Single</span>
                                </div>} */}

                                </div>
                            </div>
                            
                        </div>
                        <hr className='my-2' />
                    </div>)}



                </div>

            </div>

        </div >
    )
}

export default Tradepage1min