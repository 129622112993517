import React from 'react'
import { WalletFill } from 'react-bootstrap-icons'

const Activityaward = () => {
    return (
        <div className="bg-dark">


            <div className="activityaward_banner d-flex align-items-center px-3" >
                <div className="text-white" style={{width:"60%",height:"150px"}}>
                    <h6 style={{fontSize:"19px"}}>Activity Award</h6>
                    <p className="mb-0" style={{fontSize:"12px"}}>Complete weekly/daily tasks to receive rich rewards</p>
                    <p className="mb-0" style={{fontSize:"12px"}}>Weekly rewards cannot be accumulated to the next week, and daily rewards cannot be accumulated to the next day.</p>
                </div>
            </div>


            <div className="px-3" style={{marginTop:"-50px"}}>
                <div className="activity_award_cards my-2 pb-3">
                    <div className="d-flex text-white  justify-content-between" style={{ fontSize: "14px" }}>
                        <p className='text-center mb-3 d-flex align-items-center justify-content-center' style={{ width: "40%", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px", background: "#00AA81", height: "30px" }}>Daily mission</p>
                        <p className='text-end pe-3 d-flex  align-items-center text-info'>Unfinished</p>
                    </div>
                    <p className='text-white px-3 mb-3' style={{ fontSize: "12px" }}> Lottery daily bonus  <span className="text-danger mx-1 bold" style={{ fontSize: "12px" }}>0/1000</span> </p>
                    <p className="bonus mx-3 mb-3">Claim your reward bonus 10 rs when you bet more than 1000 in lootery</p>
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px" }}>
                        <p className='text-white mb-3'>Award amount </p><p className='text-info mb-3'><WalletFill />RS 10.00</p>
                    </div>
                    <p className='d-flex justify-content-center mb-0 align-items-center  text-white bold mx-3 py-1' style={{ background: "linear-gradient(256deg, #345EF5 34.26%, #2595E8 72.77%)", borderRadius: "10px" }}>To Complete</p>
                </div>
                <div className="activity_award_cards my-2 pb-3">
                    <div className="d-flex text-white  justify-content-between" style={{ fontSize: "14px" }}>
                        <p className='text-center mb-3 d-flex align-items-center justify-content-center' style={{ width: "40%", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px", background: "#00AA81", height: "30px" }}>Daily mission</p>
                        <p className='text-end pe-3 d-flex  align-items-center text-info'>Unfinished</p>
                    </div>
                    <p className='text-white px-3 mb-3' style={{ fontSize: "12px" }}> Lottery daily bonus  <span className="text-danger mx-1 bold" style={{ fontSize: "12px" }}>0/1000</span> </p>
                    <p className="bonus mx-3 mb-3">Claim your reward bonus 10 rs when you bet more than 1000 in lootery</p>
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px" }}>
                        <p className='text-white mb-3'>Award amount </p><p className='text-info mb-3'><WalletFill />RS 10.00</p>
                    </div>
                    <p className='d-flex justify-content-center mb-0 align-items-center  text-white bold mx-3 py-1' style={{ background: "linear-gradient(256deg, #345EF5 34.26%, #2595E8 72.77%)", borderRadius: "10px" }}>To Complete</p>
                </div>
                <div className="activity_award_cards my-2 pb-3">
                    <div className="d-flex text-white  justify-content-between" style={{ fontSize: "14px" }}>
                        <p className='text-center mb-3 d-flex align-items-center justify-content-center' style={{ width: "40%", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px", background: "#00AA81", height: "30px" }}>Daily mission</p>
                        <p className='text-end pe-3 d-flex  align-items-center text-info'>Unfinished</p>
                    </div>
                    <p className='text-white px-3 mb-3' style={{ fontSize: "12px" }}> Lottery daily bonus  <span className="text-danger mx-1 bold" style={{ fontSize: "12px" }}>0/1000</span> </p>
                    <p className="bonus mx-3 mb-3">Claim your reward bonus 10 rs when you bet more than 1000 in lootery</p>
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px" }}>
                        <p className='text-white mb-3'>Award amount </p><p className='text-info mb-3'><WalletFill />RS 10.00</p>
                    </div>
                    <p className='d-flex justify-content-center mb-0 align-items-center  text-white bold mx-3 py-1' style={{ background: "linear-gradient(256deg, #345EF5 34.26%, #2595E8 72.77%)", borderRadius: "10px" }}>To Complete</p>
                </div>
                <div className="activity_award_cards my-2 pb-3">
                    <div className="d-flex text-white  justify-content-between" style={{ fontSize: "14px" }}>
                        <p className='text-center mb-3 d-flex align-items-center justify-content-center' style={{ width: "40%", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px", background: "#00AA81", height: "30px" }}>Daily mission</p>
                        <p className='text-end pe-3 d-flex  align-items-center text-info'>Unfinished</p>
                    </div>
                    <p className='text-white px-3 mb-3' style={{ fontSize: "12px" }}> Lottery daily bonus  <span className="text-danger mx-1 bold" style={{ fontSize: "12px" }}>0/1000</span> </p>
                    <p className="bonus mx-3 mb-3">Claim your reward bonus 10 rs when you bet more than 1000 in lootery</p>
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px" }}>
                        <p className='text-white mb-3'>Award amount </p><p className='text-info mb-3'><WalletFill />RS 10.00</p>
                    </div>
                    <p className='d-flex justify-content-center mb-0 align-items-center  text-white bold mx-3 py-1' style={{ background: "linear-gradient(256deg, #345EF5 34.26%, #2595E8 72.77%)", borderRadius: "10px" }}>To Complete</p>
                </div>
                <div className="activity_award_cards my-2 pb-3">
                    <div className="d-flex text-white  justify-content-between" style={{ fontSize: "14px" }}>
                        <p className='text-center mb-3 d-flex align-items-center justify-content-center' style={{ width: "40%", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px", background: "#00AA81", height: "30px" }}>Daily mission</p>
                        <p className='text-end pe-3 d-flex  align-items-center text-info'>Unfinished</p>
                    </div>
                    <p className='text-white px-3 mb-3' style={{ fontSize: "12px" }}> Lottery daily bonus  <span className="text-danger mx-1 bold" style={{ fontSize: "12px" }}>0/1000</span> </p>
                    <p className="bonus mx-3 mb-3">Claim your reward bonus 10 rs when you bet more than 1000 in lootery</p>
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px" }}>
                        <p className='text-white mb-3'>Award amount </p><p className='text-info mb-3'><WalletFill />RS 10.00</p>
                    </div>
                    <p className='d-flex justify-content-center mb-0 align-items-center  text-white bold mx-3 py-1' style={{ background: "linear-gradient(256deg, #345EF5 34.26%, #2595E8 72.77%)", borderRadius: "10px" }}>To Complete</p>
                </div>
                <div className="activity_award_cards my-2 pb-3">
                    <div className="d-flex text-white  justify-content-between" style={{ fontSize: "14px" }}>
                        <p className='text-center mb-3 d-flex align-items-center justify-content-center' style={{ width: "40%", borderTopLeftRadius: "10px", borderBottomRightRadius: "10px", background: "#00AA81", height: "30px" }}>Daily mission</p>
                        <p className='text-end pe-3 d-flex  align-items-center text-info'>Unfinished</p>
                    </div>
                    <p className='text-white px-3 mb-3' style={{ fontSize: "12px" }}> Lottery daily bonus  <span className="text-danger mx-1 bold" style={{ fontSize: "12px" }}>0/1000</span> </p>
                    <p className="bonus mx-3 mb-3">Claim your reward bonus 10 rs when you bet more than 1000 in lootery</p>
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px" }}>
                        <p className='text-white mb-3'>Award amount </p><p className='text-info mb-3'><WalletFill />RS 10.00</p>
                    </div>
                    <p className='d-flex justify-content-center mb-0 align-items-center  text-white bold mx-3 py-1' style={{ background: "linear-gradient(256deg, #345EF5 34.26%, #2595E8 72.77%)", borderRadius: "10px" }}>To Complete</p>
                </div>
            </div>

        </div>
    )
}

export default Activityaward