import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Copy, Dot } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Withdrawalrecorddetails = () => {


    const location = useLocation()
    const withdrawdata = location.state;

    const [pageloader, setpageloader] = useState(false)


    function copyText(id) {

        var referLinkElement = document.getElementById(id);

        // Create a temporary input element
        var tempInput = document.createElement("input");

        // Set the value of the temporary input to the text content of the referLinkElement

        tempInput.setAttribute("value", referLinkElement.textContent);

        // Append the temporary input element to the body
        document.body.appendChild(tempInput);

        // Select the text within the temporary input element

        tempInput.select();
        tempInput.setSelectionRange(0, 99999); // For mobile devices

        // Copy the selected text to the clipboard
        document.execCommand("copy");

        // Remove the temporary input element
        document.body.removeChild(tempInput);

        // Optionally, provide feedback to the user
        alert("Address copied to clipboard: " + referLinkElement.textContent);

    }

    return (
        <div>
            {pageloader && <LoadingSpinner />}
            {<div className='py-2' >
                <p className='text-center mb-0' style={{ fontSize: "30px", fontWeight: "600" }}>+{withdrawdata[0].amount} TRX</p>
                <hr className='my-3' />
                <div style={{ fontSize: "14px" }}>
                    <div className="px-3 d-flex justify-content-between">
                        <p className="mb-0">Type</p>
                        <p className="mb-0">Ordinary Withdrawal</p>
                    </div>
                    <hr className='my-3' />
                    <div className="px-3 d-flex justify-content-between">
                        <p className="mb-0">State</p>
                        {(withdrawdata[0].status === 0) && <p className="mb-0" style={{ fontSize: "15px", color: " #32b277" }}>
                            <Dot />Successful
                        </p>}
                        {(withdrawdata[0].status === 1) && <p className='mb-0 text-info' style={{ fontSize: "15px", color: " #32b277" }}>
                            <Dot />Successful
                        </p>}
                        {(withdrawdata[0].status === 2) && <p className="mb-0" style={{ fontSize: "15px", color: " red" }}>
                            <Dot />Failed
                        </p>}
                    </div>
                    <hr className='my-3' />
                    <div className="px-3 d-flex justify-content-between">
                        <p className="mb-0" style={{ width: "40%" }}>Withdwaral Address</p>
                        <div style={{ width: "60%" }} className="text-end">
                            <p className="mb-0" style={{ overflow: "hidden", color: "#999" }} id="Withdwaral">{withdrawdata[0].address}</p>

                            <p onClick={() => copyText("Withdwaral")} className="mb-0  text-center mt-1 ms-auto" style={{ borderRadius: "10px", border: "1px solid black", width: "70px" }}><Copy /> <span>copy</span></p>
                        </div>
                    </div>
                    <hr className='my-3' />
                    <div className="px-3 d-flex justify-content-between">
                        <p className="mb-0">Cash withdrawal network</p>
                        <p className="mb-0">{withdrawdata[0].payout}</p>
                    </div>
                    <hr className='my-3' />
                    <div className="px-3 d-flex justify-content-between">
                        <p className="mb-0">Commission</p>
                        <p className="mb-0"> 0 %</p>
                    </div>
                    <hr className='my-3' />
                    <div className="px-3 d-flex justify-content-between">
                        <p className="mb-0" style={{ width: "40%" }}>Blockchain transition ID</p>
                        <div style={{ width: "60%" }} className="text-end">
                            <p className="mb-0" id='Blockchain' style={{ overflow: "hidden", color: "#999" }}>{withdrawdata[0].payid}</p>
                            <p onClick={() => copyText("Blockchain")} className="mb-0 text-center mt-1 ms-auto" style={{ borderRadius: "10px", border: "1px solid black", width: "70px" }}><Copy /> <span>copy</span></p>

                        </div>
                    </div>
                    <hr className='my-3' />
                    <div className="px-3 d-flex justify-content-between">
                        <p className="mb-0">Time</p>
                        <p className="mb-0">{withdrawdata[0].createdate}</p>
                    </div>
                </div>
            </div>}
        </div>
    )
}

export default Withdrawalrecorddetails