import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Rebate = () => {
    const navigate = useNavigate();

    const [pageloader, setpageloader] = useState(false);

    const [lv1cmtamt, setLv1cmtamt] = useState();
    const [lv2cmtamt, setLv2cmtamt] = useState();
    const [lv3cmtamt, setLv3cmtamt] = useState();
    const [lv4cmtamt, setLv4cmtamt] = useState();
    const [lv1cmtamtu, setLv1cmtamtu] = useState();
    const [lv2cmtamtu, setLv2cmtamtu] = useState();
    const [lv3cmtamtu, setLv3cmtamtu] = useState();
    const [lv4cmtamtu, setLv4cmtamtu] = useState();

    const [lv1rebate, setLv1rebate] = useState();
    const [lv2rebate, setLv2rebate] = useState();
    const [lv3rebate, setLv3rebate] = useState();
    const [lv4rebate, setLv4rebate] = useState();
    const [lv1rebateu, setLv1rebateu] = useState();
    const [lv2rebateu, setLv2rebateu] = useState();
    const [lv3rebateu, setLv3rebateu] = useState();
    const [lv4rebateu, setLv4rebateu] = useState();

    const rebate = async () => {
        try {
            // setpageloader(true)
            let response = await axios.post('https://tronapi.tronvid.site/rebate',
                { token: getWithExpiry("myuser") }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

let data = response.data;

if (data) {
    const rebateData = data.rebateamount[0]; // Access the first item in the rebateamount array
    const cumulativeData = data.cumulativerebate;

    // Set individual rebate amounts with 4 decimal points, defaulting to 0 if invalid
    setLv1rebate(parseFloat(rebateData.level1 || 0).toFixed(4));
    setLv2rebate(parseFloat(rebateData.level2 || 0).toFixed(4));
    setLv3rebate(parseFloat(rebateData.level3 || 0).toFixed(4));
    setLv4rebate(parseFloat(rebateData.level4 || 0).toFixed(4));
    setLv1rebateu(parseFloat(rebateData.level1u || 0).toFixed(4));
    setLv2rebateu(parseFloat(rebateData.level2u || 0).toFixed(4));
    setLv3rebateu(parseFloat(rebateData.level3u || 0).toFixed(4));
    setLv4rebateu(parseFloat(rebateData.level4u || 0).toFixed(4));

    // Set cumulative rebate amounts with 4 decimal points, defaulting to 0 if invalid
    setLv1cmtamt(parseFloat(cumulativeData.lv1 || 0).toFixed(4));
    setLv2cmtamt(parseFloat(cumulativeData.lv2 || 0).toFixed(4));
    setLv3cmtamt(parseFloat(cumulativeData.lv3 || 0).toFixed(4));
    setLv4cmtamt(parseFloat(cumulativeData.lv4 || 0).toFixed(4));
    setLv1cmtamtu(parseFloat(cumulativeData.lv1u || 0).toFixed(4));
    setLv2cmtamtu(parseFloat(cumulativeData.lv2u || 0).toFixed(4));
    setLv3cmtamtu(parseFloat(cumulativeData.lv3u || 0).toFixed(4));
    setLv4cmtamtu(parseFloat(cumulativeData.lv4u || 0).toFixed(4));

    setpageloader(false);
}
 else {
                // navigate("/login");
            }
        } catch (error) {
            console.error('Error checking user:', error);
            // navigate("/login");
        }
    };

    const handleReceive = async (level, type) => {
        try {
            // setpageloader(true)

            let response = await axios.post('https://tronapi.tronvid.site/withdrawacmsn',
                { level, type, token: getWithExpiry("myuser") },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            if (response.data.status === 'success' || response.data.status === 'error') {
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');


                unimsg.textContent = response.data.message;
                unimodal.style.display = 'block';
                rebate();

                setTimeout(() => {
                    unimodal.style.display = 'none';
                }, 3000);
            setpageloader(false)
            }
        } catch (error) {
            console.error('Error making POST request', error);
        }
    };



    useEffect(() => {
        rebate();
    }, []); // Empty dependency array to run the effect only once

    return (
        <>
        {pageloader&&<LoadingSpinner/>}
            <div className="py-2" style={{ background: "linear-gradient(180deg, #1b434c, #837a7a)" }} >
                <div className="rebeatheadcontainer">
                    <h1 className='mb-4'>High rebate: 2.0%</h1>
                    <div className="pt-5 d-flex justify-content-around text-white px-3 mt-5">
                        <div className='align-items-center d-flex flex-column justify-content-center rebatediv1 ' style={{ width: "25%", marginTop: "2%" }}>
                            <img className='mb-2' src="./3rd.png" alt="" style={{ width: "80%", marginTop: "-40%" }} />
                            <p className='mb-0 level1head'> Level 3 </p>
                            <p className='mb-0 money'>{lv3cmtamt} TRX</p>
                            <p className='mb-0 money'>{lv3cmtamtu} USDT</p>
                            <p className='mb-0 commission'>Cumulative rebate</p>

                        </div>
                        <div className='align-items-center d-flex flex-column justify-content-center rebatediv1 ' style={{ width: "25%", marginTop: "-6%" }}>
                            <img className='mb-2' src="./2nd.png" alt="" style={{ width: "90%", marginTop: "-90%" }} />
                            <p className='mb-0 level2head'> Level 2 </p>
                            <p className='mb-0 money'>{lv2cmtamt} TRX</p>
                            <p className='mb-0 money'>{lv2cmtamtu} USDT</p>
                            <p className='mb-0 commission'>Cumulative rebate</p>

                        </div>
                        <div className='align-items-center d-flex flex-column justify-content-center rebatediv2' style={{ width: "25%", marginTop: "-8%" }}>
                            <img className='mb-2' src="./1st.png" alt="" style={{ width: "100%", marginTop: "-70%" }} />
                            <p className='mb-0 level1head'> Level 1 </p>
                            <p className='mb-0 money'>{lv1cmtamt} TRX</p>
                            <p className='mb-0 money'>{lv1cmtamtu} USDT</p>
                            <p className='mb-0 commission'>Cumulative rebate</p>

                        </div>
                        <div className='align-items-center d-flex flex-column justify-content-center rebatediv1' style={{ width: "25%", marginTop: "0%" }}>
                            <img className='' src="./4th.png" alt="" style={{ width: "80%", marginTop: "-50%" }} />
                            <p className='mb-0 level3head mt-3'> Level 4 </p>
                            <p className='mb-0 money'>{lv4cmtamt} TRX</p>
                            <p className='mb-0 money'>{lv4cmtamtu} USDT</p>
                            <p className='mb-0 commission'>Cumulative rebate</p>

                        </div>
                    </div>
                </div>
                <div className="rebatecontainer">
                    <div className="rebatecard mb-3">
                        <div className="cardhead">
                            <p className='mb-0'>Grade Lv1 (1.0%)</p>
                            <img src="/lvl1.png" alt="" />
                        </div>
                        <div className="cardbody">
                            <p className='mb-0'>Rebate Amount</p>
                            <div className="d-flex justify-content-around">
                                <div className='text-center'>
                                    <p className='amount'>{lv1rebate} TRX</p>
                                    {parseFloat(lv1rebate) >= 10 ? (
                                        <button className="btnss mb-0" style={{ background: "#3db87e", border: "none", color: "aliceblue" }}
                                            onClick={() => handleReceive("lv1", 'TRX')}>
                                            Receive
                                        </button>
                                    ) : (
                                        <p className='btnss mb-0'>Receive</p>
                                    )}
                                </div>
                                <div className='text-center'>
                                    <p className='amount'>{lv1rebateu} USDT  </p>
                                    {parseFloat(lv1rebateu) >= 10 ? (
                                        <button className="btnss mb-0" style={{ background: "#3db87e", border: "none", color: "aliceblue" }}
                                            onClick={() => handleReceive("lv1u", 'USDT')}>
                                            Receive
                                        </button>
                                    ) : (
                                        <p className='btnss mb-0'>Receive</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rebatecard mb-3">
                        <div className="cardhead">
                            <p className='mb-0'>Grade Lv2 (0.5%)</p>
                            <img src="/lvl2.png" alt="" />
                        </div>
                        <div className="cardbody">
                            <p className='mb-0'>Rebate Amount</p>
                            <div className="d-flex justify-content-around">
                                <div className='text-center'>
                                    <p className='amount'>{lv2rebate} TRX</p>
                                    {parseFloat(lv2rebate) >= 10 ? (
                                        <button className="btnss mb-0" style={{ background: "#3db87e", border: "none", color: "aliceblue" }}
                                            onClick={() => handleReceive("lv2", 'TRX')}>
                                            Receive
                                        </button>
                                    ) : (
                                        <p className='btnss mb-0'>Receive</p>
                                    )}
                                </div>
                                <div className='text-center'>
                                    <p className='amount'>{lv2rebateu} USDT  </p>
                                    {parseFloat(lv2rebateu) >= 10 ? (
                                        <button className="btnss mb-0" style={{ background: "#3db87e", border: "none", color: "aliceblue" }}
                                            onClick={() => handleReceive("lv2u", 'USDT')}>
                                            Receive
                                        </button>
                                    ) : (
                                        <p className='btnss mb-0'>Receive</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rebatecard mb-3">
                        <div className="cardhead">
                            <p className='mb-0'>Grade Lv3 (0.3%)</p>
                            <img src="/lvl3.png" alt="" />
                        </div>
                        <div className="cardbody">
                            <p className='mb-0'>Rebate Amount</p>
                            <div className="d-flex justify-content-around">
                                <div className='text-center'>
                                    <p className='amount'>{lv3rebate} TRX</p>
                                    {parseFloat(lv3rebate) >= 10 ? (
                                        <button className="btnss mb-0" style={{ background: "#3db87e", border: "none", color: "aliceblue" }}
                                            onClick={() => handleReceive("lv3", 'TRX')}>
                                            Receive
                                        </button>
                                    ) : (
                                        <p className='btnss mb-0'>Receive</p>
                                    )}
                                </div>
                                <div className='text-center'>
                                    <p className='amount'>{lv3rebateu} USDT  </p>
                                    {parseFloat(lv3rebateu) >= 10 ? (
                                        <button className="btnss mb-0" style={{ background: "#3db87e", border: "none", color: "aliceblue" }}
                                            onClick={() => handleReceive("lv3u", 'USDT')}>
                                            Receive
                                        </button>
                                    ) : (
                                        <p className='btnss mb-0'>Receive</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="rebatecard ">
                        <div className="cardhead">
                            <p className='mb-0'>Grade Lv4 (0.2%)</p>
                            <img src="/lvl4.png" alt="" />
                        </div>
                        <div className="cardbody">
                            <p className='mb-0'>Rebate Amount</p>
                            <div className="d-flex justify-content-around">
                                <div className='text-center'>
                                    <p className='amount'>{lv4rebate} TRX</p>
                                    {parseFloat(lv4rebate) >= 10 ? (
                                        <button className="btnss mb-0" style={{ background: "#3db87e", border: "none", color: "aliceblue" }}
                                            onClick={() => handleReceive("lv4", 'TRX')}>
                                            Receive
                                        </button>
                                    ) : (
                                        <p className='btnss mb-0'>Receive</p>
                                    )}
                                </div>
                                <div className='text-center'>
                                    <p className='amount'>{lv4rebateu} USDT  </p>
                                    {parseFloat(lv4rebateu) >= 10 ? (
                                        <button className="btnss mb-0" style={{ background: "#3db87e", border: "none", color: "aliceblue" }}
                                            onClick={() => handleReceive("lv4u", 'USDT')}>
                                            Receive
                                        </button>
                                    ) : (
                                        <p className='btnss mb-0'>Receive</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rebaterulecontainer p-3">
                    <h6>Rebate Rules</h6>
                    <p>* After the friend registers, each real transaction can earn a corresponding proportion of commission.</p>
                    <p>* The highest rebate ratio is 1.8%, settled in USDT/TRX, rebate after transaction, can be withdrawn to your trading account at any time.</p>
                    <p>* If abnormal behaviors such as malicious promotion or self-promotion are found, the platform has the right to freeze the promotion account or cancel the promotion qualification.</p>
                </div>
                <div className="invitefrnd">
                    <p className='mx-2 mb-0 text-center my-2 btnss'>Invite friend to join now</p>
                </div>
            </div>
        </>
    )
}

export default Rebate;
