import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Teamcommissionhistory = () => {
    const navigate = useNavigate();
    const location = useLocation()
    const [pageloader, setpageloader] = useState(true)
    setTimeout(() => {
        setpageloader(false)
    }, 500);

    return (
        <>
            {pageloader && <LoadingSpinner />}
            {<div className="historypagedata ">
                <div className=" cards p-2">
                    <p className='d-flex mb-0 justify-content-between'><span>Lv2</span><span>0.02TRX</span></p>
                    <p className='mb-0' >2024-06-19 15:14:24</p>
                </div>
                <hr className='my-1' />
                <div className=" cards p-2">
                    <p className='d-flex mb-0 justify-content-between'><span>Lv2</span><span>0.02TRX</span></p>
                    <p className='mb-0' >2024-06-19 15:14:24</p>
                </div>
                <hr className='my-1' />
                <div className=" cards p-2">
                    <p className='d-flex mb-0 justify-content-between'><span>Lv2</span><span>0.02TRX</span></p>
                    <p className='mb-0' >2024-06-19 15:14:24</p>
                </div>
                <hr className='my-1' />
                <div className=" cards p-2">
                    <p className='d-flex mb-0 justify-content-between'><span>Lv2</span><span>0.02TRX</span></p>
                    <p className='mb-0' >2024-06-19 15:14:24</p>
                </div>
                <hr className='my-1' />
                <div className=" cards p-2">
                    <p className='d-flex mb-0 justify-content-between'><span>Lv2</span><span>0.02TRX</span></p>
                    <p className='mb-0' >2024-06-19 15:14:24</p>
                </div>
                <hr className='my-1' />
            </div>}
        </>
    )
}

export default Teamcommissionhistory