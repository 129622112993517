import axios from 'axios';
import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import './referal.css';
import { getWithExpiry } from './JS/JS';
import { useNavigate } from 'react-router-dom';

const ReferralPage = () => {

  const navigate=useNavigate();
  const token = getWithExpiry("myuser");
  const [refercode, setRefercode] = useState("");
  const [referlinks, setReferlinks] = useState("");
  const [backgroundImage, setBackgroundImage] = useState("https://tronapi.tronvid.site/image/referbg.png");

  const refercodes = async () => {
    try {
      let response = await axios.post(
        'https://tronapi.tronvid.site/getmycode',
        { token },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      setRefercode(response.data.refercode);
     setReferlinks(`${window.location.origin}/signup?code=${response.data.refercode}`);
    } catch (error) {
      console.error("Error fetching referral code", error);
    }
  };

  useEffect(() => {
    refercodes();
  }, []);

  const linkInputRef = useRef(null);
  const copyLink = () => {
    return `${window.location.origin}/signup?code=${refercode}`;
  };

  const copyToClipboard = () => {
    const link = copyLink();
    if (linkInputRef.current) {
      linkInputRef.current.value = link;
      linkInputRef.current.select();
      document.execCommand('copy');

      const unimodal = document.getElementById('unimodal');
      const unimsg = document.getElementById('unimsg');

      unimsg.textContent = "Copied Successfully";
      unimodal.style.display = 'block';

      setTimeout(() => {
        unimodal.style.display = 'none';
      }, 1500);
    }
  };

  return (
    <div id="app" data-v-app="" style={{background:"#f0ffff"}} >
      <div
        data-v-3b74cce6=""
        className="promotionShare__container"
      >

        {/* <img src="/posters/5.png" alt="" style={{ width: '100%', height: '180px' }} /> */}

        {/* <p className="text-center bolder my-3" style={{ fontSize: '15px', color: 'rgb(40, 170, 109)', fontWeight: 800 }}>
          <span className="bolder text-success">$ </span>
          Affiliated Program TRX PLAY
          <span className="bolder text-success"> $</span>
        </p> */}
        <div
          data-v-3b74cce6=""
          className="swiper swiper-initialized swiper-horizontal my-swipe swiper-backface-hidden mt-2"
          style={{ position: 'relative', height: '400px' }}
        >
          <div className="swiper-wrapper" style={{ transitionDuration: '0ms' }}>
            <div
              data-v-3b74cce6=""
              className="swiper-slide swiper-slide-active d-flex justify-content-center align-items-center"
              style={{
                marginRight: '',
                overflow: 'hidden',
                borderRadius: '10px',
                width: '85%',
                height: '100%',
                textAlign: 'center',
                // backgroundImage: `url(/phoneposter/4.png)`,
                backgroundSize: '100% 100%'
              }}
            >



              <div style={{ padding: "5px", borderRadius: "5px", background: "white", marginTop: "50%" }}>

                <img
                  id="qrcode"
                  src={`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${referlinks}`}
                  alt=""
                  style={{ height: "130px", width: "130px" }}
                />

              </div>


              {/* <div
                data-v-3b74cce6=""
                className="promotionShare__container-swiper"
                id="share0"
                style={{
                  // position: 'relative', 
                  backgroundSize: '100% 100%',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'flex-center'
                }}
              >
                <div
                  data-v-3b74cce6=""
                  className="sContent justify-content-center align-item-center"
                  style={{
                    // flexDirection: 'column', 
                    // padding: '18px',
                    // width: '100px', 
                    // display: 'flex',
                    // paddingBottom: '0px' 
                  }}
                >
                 
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <div data-v-3b74cce6="" className="promotionShare__container-buttons">
          <Button
            variant="primary"
            data-v-3b74cce6=""
            className="share"
            onClick={copyToClipboard}
            style={{ background: "linear-gradient(134deg, rgb(27, 67, 76), rgb(240, 255, 255))", border: "none" }}
          >
            Copy INVITATION LINK
          </Button>
          {/* Hidden input for copying to clipboard */}
          <input ref={linkInputRef} type="text" style={{ position: 'absolute', left: '-9999px' }} readOnly />
        </div>


        <div className="d-flex justify-content-around text-center  py-3 mt-3" style={{ fontSize: "12px" }}>
          <div style={{ width: "25%" }} onClick={() => navigate("/redemtionexchange")}>
            <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
              <img src="/assets/newicons/icons8-gift-card-100.png" style={{ height: "30px", width: "30px" }} alt="" />
            </div>
            <p className='mb-0'>Gift Code</p>
          </div>
          <div style={{ width: "25%" }} onClick={() => navigate("/mining")}>
            <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
              <img src="/assets/newicons/icons8-bitcoin-minning-64.png" style={{ height: "30px", width: "30px" }} alt="" />
            </div>
            <p className='mb-0'>Mining</p>
          </div>
          <div style={{ width: "25%" }} onClick={() => window.location.href = "https://t.me/bbcusdt"}>
            <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
              <img src="/assets/newicons/icons8-telegram-64.png" style={{ height: "30px", width: "30px" }} alt="" />
            </div>
            <p className='mb-0'>Join</p>
          </div>
          <div style={{ width: "25%" }} onClick={() => window.location.href = "https://t.me/Bbc_trading_supports"}>
            <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
              <img src="/assets/newicons/icons8-customer-support-40.png" style={{ height: "30px", width: "30px" }} alt="" />
            </div>
            <p className='mb-0'>Support</p>
          </div>

        </div>
        <div className="d-flex justify-content-around text-center  py-3 mb-5 pb-5 " style={{ fontSize: "12px" }}>
          <div style={{ width: "25%" }} onClick={() => navigate("/user/alltransaction")}>
            <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
              <img src="/assets/newicons/icons8-activity-history-100.png" style={{ height: "30px", width: "30px" }} alt="" />
            </div>
            <p className='mb-0'>All Transaction</p>
          </div>
          <div style={{ width: "25%" }} onClick={() => navigate("/gifthistory")}>
            <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
              <img src="/assets/newicons/icons8-gift-100.png" style={{ height: "30px", width: "30px" }} alt="" />
            </div>
            <p className='mb-0'>Gift history</p>
          </div>
          <div style={{ width: "25%" }} onClick={() => navigate("/depositrecord")}>
            <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
              <img src="/assets/newicons/icons8-payment-history-48.png" style={{ height: "30px", width: "30px" }} alt="" />
            </div>
            <p className='mb-0'>Deposit History</p>
          </div>
          <div style={{ width: "25%" }} onClick={() => navigate("/withdrawrecord")}>
            <div className='mx-auto d-flex justify-content-center align-items-center' style={{ height: "40px", width: "40px", borderRadius: "10px", background: "rgb(243, 243, 243)" }}>
              <img src="/assets/newicons/icons8-cash-withdrawal-48.png" style={{ height: "30px", width: "30px" }} alt="" />
            </div>
            <p className='mb-0'>Withdrawal History</p>
          </div>

        </div>





      </div>
    </div>
  );
};

export default ReferralPage;
