import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

export const Deposit = () => {

    const navigate = useNavigate();
    const token = getWithExpiry("myuser")
    const [usdtbalance, setusdtbalance] = useState()
    const [trxbalance, settrxbalance] = useState()

    const [pageloader, setpageloader] = useState(false)




    const checkuser = async () => {
        setpageloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        settrxbalance(data.data.userdata.trx)
        setusdtbalance(data.data.userdata.usdt)
        setpageloader(false)
    }

    useEffect(() => {
        checkuser()
    }, [])



    return (

        <>
            {/* {(page === "deposit") && <p className='bg-dark text-white' onClick={() => setpage("option")}>Back</p>} */}
            <div className="" style={{background:"#f0ffff",}}>
                {pageloader && <LoadingSpinner />}
                {<div className=' py-4 mx-4'>
                    <div className="row deposit justify-content-center">
                        
                        <div className="col-md-4 m-2  " style={{ background: "linear-gradient(180deg, #1b434cd9, #bdd2d2)", borderRadius: "10px" }}>
                            <div className="d-flex p-2">
                                <p style={{ borderRadius: "10px", height: "40px", width: "40px" }} className=' d-flex justify-content-center align-items-center'><img src="./icon_mine_TRX.png" style={{ height: "30px", width: "30px" }} alt="" /></p>
                                <span className='m-2'>TRX TRON</span>
                            </div>
                            <div className='text-center'>
                                <p>Total Balance</p>
                                <p>{Number(trxbalance)?.toFixed(4)} TRX</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className='my-3 ' onClick={() => navigate("/deposittrx")}>Deposit Now</button>
                            </div>
                        </div>
                        
                        <div className="col-md-4 m-2  " style={{ background: "linear-gradient(180deg, #1b434cd9, #bdd2d2)", borderRadius: "10px" }}>
                            <div className="d-flex p-2">
                                <p style={{ borderRadius: "10px", height: "40px", width: "40px" }} className=' d-flex justify-content-center align-items-center'><img src="https://cryptologos.cc/logos/tether-usdt-logo.png" style={{ height: "30px", width: "30px" }} alt="" /></p>
                                <span className='m-2'>USDT.TRC20</span>
                            </div>
                            <div className='text-center'>
                                <p>Total Balance</p>
                                <p>{Number(usdtbalance)?.toFixed(4)} USDT</p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className='my-3 ' onClick={() => navigate("/depositusdt")}>Deposit Now</button>
                            </div>
                        </div>
                        <div className='text-danger py-3'>
                            <ul>
                                <li>TRX/USDT (Tether) - TRC-20 is accepted Cryptocurrency</li>
                                <li>Minimum Deposit Amount: 10 USDT/TRX</li>
                                <li>Deposits are processed every minute. Please allow for this interval when making your deposit.</li>
                                <li>Confirm that you are depositing USDT/TRX on the TRC-20 network. Deposits made on any other network may be lost and cannot be recovered.</li>
                                <li>The minimum deposit amount is 10 USDT/TRX. Deposits less than this amount will not be credited to your account.</li>
                                <li>Please be aware of any transaction fees charged by the TRC-20 network. Ensure your final deposit amount meets the minimum requirement after deducting any fees.</li>
                                <li>Always double-check the deposit address before initiating the transfer to avoid any potential loss of funds.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>}
            </div>
        </>

    )
}
