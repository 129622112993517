import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Myteam = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [teamnav, setteamnav] = useState("team");
    const [totalrevenueusdt, settotalrevenueusdt] = useState("");
    const [totalrevenuetrx, settotalrevenuetrx] = useState("");
    const [totalhavetrx, settotalhavetrx] = useState("");
    const [totalhaveusdt, settotalhaveusdt] = useState("");

    const [leavelnav, setleavelnav] = useState("lvl1");
    const [leavel1, setleavel1] = useState([]);
    const [leavel2, setleavel2] = useState([]);
    const [leavel3, setleavel3] = useState([]);
    const [leavel4, setleavel4] = useState([]);

    const [pageloader, setpageloader] = useState(false);

    const rebate = async () => {
        try {
            setpageloader(true);
            const response = await axios.post('https://tronapi.tronvid.site/rebate',
                { token: getWithExpiry("myuser") },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (data.status === "success") {
                const rebateData = data.rebateamount[0];
                const cumulativeData = data.cumulativerebate;

                const sumLevels = (data, keys) => keys.reduce((acc, key) => acc + parseFloat(data[key] || 0), 0);

                const totalrevenuetrx = sumLevels(cumulativeData, ['lv1', 'lv2', 'lv3', 'lv4']).toFixed(4);
                const totalrevenueusdt = sumLevels(cumulativeData, ['lv1u', 'lv2u', 'lv3u', 'lv4u']).toFixed(4);

                const totalhavetrx = sumLevels(rebateData, ['level1', 'level2', 'level3', 'level4']).toFixed(4);
                const totalhaveusdt = sumLevels(rebateData, ['level1u', 'level2u', 'level3u', 'level4u']).toFixed(4);

                settotalrevenueusdt(totalrevenueusdt);
                settotalrevenuetrx(totalrevenuetrx);
                settotalhaveusdt(totalhaveusdt);
                settotalhavetrx(totalhavetrx);
                setpageloader(false);
            }
        } catch (error) {
            console.error('Error checking user:', error);
        }
    };

    const fetchlevels = async () => {
        try {
            setpageloader(true);
            const token = getWithExpiry("myuser");
            let response = await axios.post('https://tronapi.tronvid.site/levelmember', { token }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status) {
                setleavel1(response.data.level1 || []);
                setleavel2(response.data.level2 || []);
                setleavel3(response.data.level3 || []);
                setleavel4(response.data.level4 || []);
            }
            setpageloader(false);
        } catch (error) {
            console.error('Error fetching levels:', error);
        }
    }

    useEffect(() => {
        fetchlevels();
        rebate();
    }, []);

    return (
        <div style={{background:"#f0ffff",}}>
            {pageloader && <LoadingSpinner />}
            {(teamnav !== "records") && <div className="d-flex justify-content-around align-items-center py-2 teamnav">
                <p className={`mb-0 ${(location.pathname === "/team") && ("active")}`} onClick={() => navigate("/team")}>Team</p>
                <p className={`mb-0 ${(location.pathname === "/teamtoday") && ("active")}`} onClick={() => navigate("/teamtoday")}>Today</p>
                <p className={`mb-0 ${(location.pathname === "/teamhistory") && ("active")}`} onClick={() => navigate("/teamhistory")}>History</p>
            </div>}

            {(teamnav === "team") && <div>
                <div className="containter-fluid teambanner" style={{ height: "120px",backgroundImage:"url(/assets/png/cardbg.png)",backgroundPosition:"center",backgroundRepeat:"no-repeat",backgroundSize:"inherit"  }}>
                    <div className="row text-center">
                        <div className="col-6">
                            <p className='mb-0' style={{ fontSize: "12px" }}>{totalrevenuetrx} TRX</p>
                            <p className='mb-0' style={{ fontSize: "12px" }}>{totalrevenueusdt} USDT</p>
                            <p className='mb-0' style={{ fontSize: "10px" }}>Total revenue</p>
                        </div>
                        <div className="col-6">
                            <p className='mb-0' style={{ fontSize: "12px" }}>{totalhavetrx} TRX</p>
                            <p className='mb-0' style={{ fontSize: "12px" }}>{totalhaveusdt} USDT</p>
                            <p className='mb-0' style={{ fontSize: "10px" }}>Undrawn balance</p>
                        </div>
                    </div>
                </div>

                <div className='d-flex justify-content-around  teamlvlnav' style={{background:"#f0ffff", borderRadiusT: "10px", height: "40px", marginTop: "-20px",borderBottom: '1px solid #c7e9d9' }}>
                    <p className={(leavelnav === "lvl1") && ("activelvl")} onClick={() => setleavelnav("lvl1")}>Lv1 </p>
                    <p className={(leavelnav === "lvl2") && ("activelvl")} onClick={() => setleavelnav("lvl2")}>Lv2 </p>
                    <p className={(leavelnav === "lvl3") && ("activelvl")} onClick={() => setleavelnav("lvl3")}>Lv3 </p>
                    <p className={(leavelnav === "lvl4") && ("activelvl")} onClick={() => setleavelnav("lvl4")}>Lv4 </p>
                </div>
                <div className="lvlrecord px-4 py-3">
                    {leavelnav === "lvl1" ? (
                        leavel1.length > 0 ? (
                            leavel1.map((item, index) => (
                                <div key={index}>
                                    <p className='mb-0'>UID:{item.id}</p>
                                    <p className='d-flex mb-0 justify-content-between'>
                                        <span>Phone NO : {item.mobile}</span>
                                        <span>{item.reg_date}</span>
                                    </p>
                                    <hr className='my-2' />
                                </div>
                            ))
                        ) : (
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
                            </div>
                        )
                    ) : null}
                    {leavelnav === "lvl2" ? (
                        leavel2.length > 0 ? (
                            leavel2.map((item, index) => (
                                <div key={index}>
                                    <p className='mb-0'>UID:{item.id}</p>
                                    <p className='d-flex mb-0 justify-content-between'>
                                        <span>Phone NO : {item.mobile}</span>
                                        <span>{item.reg_date}</span>
                                    </p>
                                    <hr className='my-2' />
                                </div>
                            ))
                        ) : (
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
                            </div>
                        )
                    ) : null}
                    {leavelnav === "lvl3" ? (
                        leavel3.length > 0 ? (
                            leavel3.map((item, index) => (
                                <div key={index}>
                                    <p className='mb-0'>UID:{item.id}</p>
                                    <p className='d-flex mb-0 justify-content-between'>
                                        <span>Phone NO : {item.mobile}</span>
                                        <span>{item.reg_date}</span>
                                    </p>
                                    <hr className='my-2' />
                                </div>
                            ))
                        ) : (
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
                            </div>
                        )
                    ) : null}
                    {leavelnav === "lvl4" ? (
                        leavel4.length > 0 ? (
                            leavel4.map((item, index) => (
                                <div key={index}>
                                    <p className='mb-0'>UID:{item.id}</p>
                                    <p className='d-flex mb-0 justify-content-between'>
                                        <span>Phone NO : {item.mobile}</span>
                                        <span>{item.reg_date}</span>
                                    </p>
                                    <hr className='my-2' />
                                </div>
                            ))
                        ) : (
                            <div style={{ width: "100%", textAlign: "center" }}>
                                <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
                            </div>
                        )
                    ) : null}
                </div>
            </div>}

            {(teamnav === "records") && <div className="historypagedata bg-white">
                {/* Render records here */}
            </div>}
        </div>
    )
}

export default Myteam;
