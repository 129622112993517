import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import { ArrowLeft } from 'react-bootstrap-icons';
import { useLocation, useNavigate } from 'react-router-dom'
import LoadingSpinner from '../Loader/LoadingSpinner';
import { getWithExpiry } from '../JS/JS';

const Gamerecordclosingbtc15 = () => {

    const navigate = useNavigate();

    const location = useLocation()

    const { statical_interval } = location.state || {};
    const [pageloader, setpageloader] = useState(true)

    const [nav, setnav] = useState("close")
    const [selectedValue, setSelectedValue] = useState(15);

    const [showPopup, setShowPopup] = useState(false);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };



    const [result, setresult] = useState([]);


    const fetchclosingrecords = async () => {
        setpageloader(true)

        let data = await axios.post('https://tronapi.tronvid.site/getbtchistory', { interval: selectedValue,token: getWithExpiry("myuser"), game_type: `btc${selectedValue}` }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data?.data?.message === "No records found") {
            setresult([])
        } else {
            setresult(data?.data?.btchistory)
        }
        setpageloader(false)

    }

    useEffect(() => {
        fetchclosingrecords();
    }, [selectedValue])



    const handleSelect = (eventKey) => {
        let selectedText;
        switch (eventKey) {
            case '1':
                selectedText = 1;
                break;
            case '2':
                selectedText = 5;
                break;
            case '3':
                selectedText = 15;
                break;
            default:
                selectedText = 'Select an option';
        }
        sessionStorage.setItem('selectedintervel', selectedText);

        // setSelectedValue(selectedText);
        fetchselectedinterval();

    };

    const fetchselectedinterval = () => {
        const value = sessionStorage.getItem('selectedintervel');
        // setSelectedValue(value);
    }

    useEffect(() => {
        fetchselectedinterval()
    }, [])

    return (
        <div>

{pageloader && <LoadingSpinner />}

            <div className="shadow-sm position-sticky sticky-top" style={{ backgroundColor: "white" }}>
                <div className='d-flex justify-content-between align-items-center  p-2'>
                    <div className="" style={{ width: "20%" }}>{(location.pathname === "/index") ? <img style={{ height: "30px", width: "30px" }} src="https://genusdt.net/static/img/logo.png" alt="" /> : <ArrowLeft onClick={() => navigate("/btc1")} />}</div>
                    {/* <div className=""></div> */}
                    <div className="text-center d-flex align-items-center justify-content-center" style={{ width: "60%" }}>

                        <span>Record</span>
                    </div>
                    <div className="navbutton d-flex justify-content-end" style={{ width: "20%" }}>
                        {/* {<DropdownButton
                            className='ms-auto '
                            as={ButtonGroup}
                            key={"info"}
                            id={`dropdown`}
                            onSelect={handleSelect}
                            // info={"info".toLowerCase()}
                            title={selectedValue + " min"}
                        >
                            <Dropdown.Item eventKey="1" >1 min</Dropdown.Item>
                            <Dropdown.Item eventKey="2">5 min</Dropdown.Item>
                            <Dropdown.Item eventKey="3">15 min</Dropdown.Item>
                        </DropdownButton>} */}


                        {/* {(location.pathname === "/withdraw") && <span onClick={() => navigate("/withdrawrecord")}><Calendar2Event className='mx-2' /></span>} */}
                        {/* {(location.pathname == "/depositpage" || location.pathname == "/deposittrx" || location.pathname == "/depositusdt") && <span onClick={() => navigate("/depositrecord")}><Calendar2Event className='mx-2' /></span>} */}
                    </div>
                </div>
            </div>




            <div className="d-flex justify-content-around gamerecordnav py-2 bg-light">
                <p className={`mb-0 px-3  active text-white`} onClick={() => navigate("/gamerecordclosingsbtc15")}>Closing</p>
                <p className={`mb-0 px-3 `} onClick={() => navigate("/gamerecordtransationsbtc15")}>Trasaction</p>
                <p className={`mb-0 px-3 `} onClick={() => navigate("/gamerecordstaticsbtc15")}>Statical</p>
            </div>

            {(nav === "close") && <div className='my-2'>


                {result?.map((data, index) => <div key={index}>
                    <div className='d-flex justify-content-between px-2'>
                        <div>
                            <p style={{ fontSize: "11px", color: "#999" }}>{data.uid}</p>
                            <p className='mb-0' style={{ fontSize: "18px", fontWeight: "700", color: "black" }}>{data.price}</p>
                        </div>
                        <div>
                            <p className='text-end' style={{ fontSize: "11px", color: "#999" }}>{data.updated_at}</p>
                            <div className="d-flex text-center">
                                {(data?.color === "big") && <div className=' d-flex align-items-center justify-content-center big' >Big</div>}
                                {(data?.color === "small") && <div className=' mx-2 d-flex align-items-center justify-content-center small' >Small</div>}
                                {(data?.color2 === "single") && <div className=' mx-2 d-flex align-items-center justify-content-center single' >Single</div>}
                                {(data?.color2 === "double") && <div className=' mx-2 d-flex align-items-center justify-content-center double' >Double</div>}


                                {(data?.totalcolor === "smalldouble") &&
                                    <div
                                        style={{
                                            width: '45px',
                                            marginRight: '5px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '25px',
                                            borderRadius: '5px',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: 'flex-end',
                                                borderRadius: '5px 5px 0 0',

                                                border: '2px solid #db3431',
                                                color: '#db3431',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '50%',
                                                fontSize: '8px',
                                                fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                                width: '100%',
                                                borderBottom: 'none',
                                            }}
                                        >
                                            SMALL
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: 'flex-start',
                                                borderRadius: '0 0 5px 5px',

                                                border: '2px solid #f69045',
                                                color: '#f69045',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '50%',
                                                width: '100%',
                                                fontSize: '8px',
                                                fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                                borderTop: 'none',
                                            }}
                                        >
                                            DOUBLE
                                        </div>
                                    </div>

                                }
                                {(data?.totalcolor === "smallsingle") &&
                                    <div
                                        style={{
                                            width: '45px',
                                            marginRight: '5px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '25px',
                                            borderRadius: '5px',
                                            boxSizing: 'border-box',


                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: 'flex-end',
                                                borderRadius: '5px 5px 0 0',

                                                border: '2px solid #db3431',
                                                color: '#db3431',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '50%',
                                                fontSize: '8px',
                                                fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                                width: '100%',
                                                borderBottom: 'none',
                                            }}
                                        >
                                            SMALL
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: 'flex-start',
                                                borderRadius: '0 0 5px 5px',

                                                border: '2px solid #3182e1',
                                                color: '#3182e1',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '50%',
                                                width: '100%',
                                                fontSize: '8px',
                                                fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                                borderTop: 'none',
                                            }}
                                        >
                                            SINGLE
                                        </div>
                                    </div>
                                }

                                {(data?.totalcolor === "bigsingle") &&
                                    <div
                                        style={{
                                            width: '45px',
                                            marginRight: '5px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '25px',
                                            borderRadius: '5px',
                                            boxSizing: 'border-box',
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: 'flex-end',
                                                borderRadius: '5px 5px 0 0',

                                                border: '2px solid #32b277',
                                                color: '#32b277',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '50%',
                                                fontSize: '8px',
                                                borderBottom: 'none',
                                                fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                                width: '100%',

                                            }}
                                        >
                                            BIG
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: 'flex-start',
                                                borderRadius: '0 0 5px 5px',

                                                border: '2px solid #3182e1',
                                                color: '#3182e1',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '50%',
                                                width: '100%',
                                                fontSize: '8px',
                                                fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                                borderTop: 'none',
                                            }}
                                        >
                                            SINGLE
                                        </div>
                                    </div>

                                }


                                {(data?.totalcolor === "bigdouble") &&
                                    <div
                                        style={{
                                            width: '45px',
                                            marginRight: '5px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '25px',
                                            borderRadius: '5px',
                                            boxSizing: 'border-box',
                                            borderbottom: 'none',
                                        }}
                                    >
                                        <div
                                            style={{
                                                alignSelf: 'flex-end',
                                                borderRadius: '5px 5px 0 0',

                                                border: '2px solid #32b277',
                                                color: '#32b277',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '50%',
                                                fontSize: '8px',
                                                fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                                width: '100%',
                                                borderBottom: 'none',
                                            }}
                                        >
                                            BIG
                                        </div>
                                        <div
                                            style={{
                                                alignSelf: 'flex-start',
                                                borderRadius: '0 0 5px 5px',

                                                border: '2px solid #f69045',
                                                color: '#f69045',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                height: '50%',
                                                width: '100%',
                                                fontSize: '8px',
                                                fontFamily: 'Microsoft YaHei, Arial, sans-serif',
                                                borderTop: 'none',
                                            }}
                                        >
                                            DOUBLE
                                        </div>
                                    </div>
                                }

                                                             
                            </div>
                        </div>
                    </div>
                    <hr className='my-2' />
                </div>)}

            </div>}


            <div className={`popup-container withdraw  ${showPopup && 'show'}`}>
                {<div className="popup-content px-0">
                    <div className="d-flex justify-content-between px-3" style={{ fontSize: "12px", color: "#999" }}>
                        <p>PD236876387638716</p>
                        <p >12-10-06 10:10:10</p>
                    </div>
                    <hr className='my-1' />

                    <div className='py-2 bg-light text-center' style={{ fontSize: "20px" }}> <span>5+6+10=21</span></div>

                    <div className='py-2' style={{ fontSize: "14px", lineHeight: "2" }}>

                        <p className='mb-0 px-2'>Transaction amount : 18 TRX</p>
                        <hr className='my-1 ' />
                        <p className='mb-0 px-2'>Profit and loss results : 18 TRX</p>
                        <hr className='my-1' />
                        <p className='mb-0 px-2'>Transaction option : </p>
                    </div>
                    {<div className=' mx-2 d-flex align-items-center text-center justify-content-center' style={{ color: "green", height: "30px", fontSize: "10px", width: "70px", borderRadius: "10px", borderLeft: "red 1px solid", border: "green 1px solid", borderBottom: "blue 1px solid" }}>
                        <span className='mb-0 ' style={{ lineHeight: "10px", width: "25px" }}> Big Single</span>
                    </div>}
                    <hr className='my-1' />
                    <p className='text-center mb-0' style={{ fontSize: "14px", color: "#999" }}>Cancel</p>
                </div>}

            </div>

        </div >
    )
}

export default Gamerecordclosingbtc15