import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { EyeSlash, Eye } from 'react-bootstrap-icons';
import { apiEndPointt } from './API/ApiPaths';
import { setWithExpiry } from './JS/JS';
import Apiloader from './Loader/Apiloader';
import LoadingSpinner from './Loader/LoadingSpinner';

const Login = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState(12345678900);
    const [password, setPassword] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [visible, setVisible] = useState(false);

    const [pageloader, setpageloader] = useState(false);

    const [passField, setPassField] = useState('password');

    const checkUser = async (token) => {
        try {
            setpageloader(true)
            const data = await axios.post(apiEndPointt.VERIFYUSER, { token }, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log(data.data.status)

            if (data.data.status === "success") {
                navigate("/index");
                navigate("/");
            }
            setpageloader(false)
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Error checking user. Please try again later.');
        }
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        setpageloader(true)
        if (!email && !mobile) {


            const unimodal = document.getElementById('unimodal');
            const unimsg = document.getElementById('unimsg');

            unimsg.textContent = "Please enter email or mobile number.";
            unimodal.style.display = 'block';

            setTimeout(() => {
                unimodal.style.display = 'none';
            }, 1500);
            setpageloader(false)

            return;
        }

        const requestData = {

            mobile: email || mobile, // Assuming 'email' field is used for mobile number
            password: password,

        };


        try {
            const response = await axios.post(apiEndPointt.LOGIN, requestData, {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.data.status === 'success') {
                setWithExpiry("myuser", response.data.token, 86400000)
                // localStorage.setItem("myuser", response.data.token);
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');

                unimsg.textContent = response.data.message;
                unimodal.style.display = 'block';

                setTimeout(() => {
                    unimodal.style.display = 'none';
                }, 1500);
                checkUser(response.data.token);
                setpageloader(false)

            } else {
                const unimodal = document.getElementById('unimodal');
                const unimsg = document.getElementById('unimsg');

                unimsg.textContent = response.data.message;
                unimodal.style.display = 'block';

                setTimeout(() => {
                    unimodal.style.display = 'none';
                }, 1500);
                setpageloader(false)
            }
        } catch (error) {
            console.error('Error:', error);
            setResponseMessage('Error logging in. Please try again later.');
        }
    };



    return (
        <div className='d-flex justify-content-centerLogin' style={{ marginBottom: "-60px", height: "100vh", maxHeight: "100vh", overflow: "hidden", background: "#f0ffff" }}>
            {pageloader && <LoadingSpinner />}
            <div className="col-lg-6" >



                <div class="logintopimgdiv">


                </div>
                <div class="logintopimgdivboxshadow px-3">







                    {/* <div className="">
                    <div className='bgmanneger'>

                        <div className="text-center py-2" style={{ fontWeight: "600", color: "white" }}>
                            <h6 className=' sliding-div show  '>Sign In</h6>
                            <p>Sign in to start using TRXPLAY</p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <img src="/logo/3.png" alt="" style={{ height: "50px", width: "50px" }} />
                        </div>

                    </div>
                    <div className="ocean">
                        <div className="wave"></div>
                        <div className="wave"></div>
                        <div className="wave"></div>
                    </div>
                    
                    

                </div> */}

                    {/* linear-gradient(180deg,transparent,azure 20%) */}

                    <div style={{color:"white",fontSize:"23px",fontWeight:"600",padding:"10px",position:"absolute",top:"50px"}}>
                        <p>Hello !</p>
                        <p>Welcome To BBCUSDT</p>
                    </div>
                    <div className="card " style={{ border: "none", borderRadius: "15px", height: "100%", background: "#f0ffff", overflow: "" }}>
                        <div className="d-flex" style={{ background: "#a8b2b2", color: "white" }}>
                            <div className="text-center formactive p-2" style={{ width: "50%", fontSize: "18px", fontWeight: "500" }}>Login</div>
                            <div className="text-center p-2" style={{ width: "50%", fontSize: "18px", fontWeight: "500" }} onClick={() => navigate('/signup')} >Sign Up</div>
                        </div>
                        <div className="card-body px-4 px-md-5 ">
                            <form onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="form-outline my-2">
                                        <label className='mb-2' htmlFor="email">Email</label>
                                        <input
                                            type="text"
                                            id="email"
                                            className="form-control"
                                            placeholder='Enter Your Email'
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            style={{background:"#daf0f5",borderRadius:"50px",border:"none"}}
                                        />
                                    </div>
                                    <div className="form-outline my-2">
                                        <label className='mb-2' htmlFor="password">Password</label>
                                        <div style={{ position: "relative" }}>
                                            <input
                                                type={passField}
                                                id="password"
                                                className="form-control"
                                                placeholder='Enter password'
                                                value={password}
                                                onChange={(e) => setPassword(e.target.value)}
                                            style={{background:"#daf0f5",borderRadius:"50px",border:"none"}}

                                            />
                                            <div style={{ position: "absolute", right: "10px", top: "5px" }}>
                                                {passField === 'password' ? (
                                                    <EyeSlash onClick={() => setPassField("text")} />
                                                ) : (
                                                    <Eye onClick={() => setPassField("password")} />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <p className='text-end'>
                                        <span className='forgettext text-info' onClick={() => navigate("/forgetpass")}>Forget Your Password?</span>
                                    </p>
                                    <div style={{ height: "40px" }} className='d-flex pt-0 linear_gradiend_green justify-content-center'>
                                        <button
                                            style={{ margin: "0px", height: "40px" }}
                                            type="submit"
                                            className="loginbtn"
                                        >
                                            Sign In
                                        </button>
                                    </div>
                                    <div className='text-center py-3'>
                                        <p style={{ color: " #958d9e " }}><span>Don't have an account?</span></p>
                                        <p onClick={() => navigate("/signup")} className='text-center fw-bold text-dark'>
                                            SIGNUP
                                        </p>
                                    </div>
                                </div>
                            </form>
                            {responseMessage && (
                                <div className="alert alert-info mt-3">
                                    {responseMessage}
                                </div>
                            )}
                        </div>
                    </div>
                </div>



                {/* <div className="ocean1">
                    <div className="wave"></div>
                    <div className="wave"></div>
                    <div className="wave"></div>
                </div>
                <div className="bgmanneger1">
                </div> */}
            </div>


        </div >
    );
};

export default Login;
