import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Teamtoday = () => {

    const location = useLocation()
    const navigate = useNavigate();
    const [teamnav, setteamnav] = useState("team")

    const [leavelnav, setleavelnav] = useState("lvl1")
    const [deposit, setdeposit] = useState()
    const [depositu, setdepositu] = useState()
    const [firstdeposit, setfirstdeposit] = useState()
    const [firstdepositu, setfirstdepositu] = useState()
    const [withd, setwithd] = useState()
    const [withdu, setwithdu] = useState()
    const [totalbettrx, settotalbettrx] = useState()
    const [totalbetusdt, settotalbetusdt] = useState()
    const [pageloader, setpageloader] = useState()


    const todaydeposit = async () => {
        try {
            setpageloader(true)
            const response = await axios.post('https://tronapi.tronvid.site/todayteam',
                { token: getWithExpiry("myuser") },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (data.status === "success") {
                var level1 = parseInt(data.level1[0].totalrecharge, 10) || 0;
                var level2 = parseInt(data.level2[0].totalrecharge, 10) || 0;
                var level3 = parseInt(data.level3[0].totalrecharge, 10) || 0;
                var level4 = parseInt(data.level4[0].totalrecharge, 10) || 0;

                var deposit = level1 + level2 + level3 + level4;
                setdeposit(deposit)

                var level1u = parseInt(data.level1u[0].totalrecharge, 10) || 0;
                var level2u = parseInt(data.level2u[0].totalrecharge, 10) || 0;
                var level3u = parseInt(data.level3u[0].totalrecharge, 10) || 0;
                var level4u = parseInt(data.level4u[0].totalrecharge, 10) || 0;

                var depositu = level1u + level2u + level3u + level4u;

                setdepositu(depositu)

                setpageloader(false)
            }
        } catch (error) {
            console.error('Error checking user:', error);
        }
    };

    const todayfirstdeposit = async () => {
        try {
            setpageloader(true)
            const response = await axios.post('https://tronapi.tronvid.site/todayfirstdeposit',
                { token: getWithExpiry("myuser") },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (data.status === "success") {
                var level1 = parseInt(data.level1[0].totalrecharge, 10) || 0;
                var level2 = parseInt(data.level2[0].totalrecharge, 10) || 0;
                var level3 = parseInt(data.level3[0].totalrecharge, 10) || 0;
                var level4 = parseInt(data.level4[0].totalrecharge, 10) || 0;

                var deposit = level1 + level2 + level3 + level4;
                setfirstdeposit(deposit)

                var level1u = parseInt(data.level1u[0].totalrecharge, 10) || 0;
                var level2u = parseInt(data.level2u[0].totalrecharge, 10) || 0;
                var level3u = parseInt(data.level3u[0].totalrecharge, 10) || 0;
                var level4u = parseInt(data.level4u[0].totalrecharge, 10) || 0;

                var depositu = level1u + level2u + level3u + level4u;

                setfirstdepositu(depositu)

                setpageloader(false)
            }
        } catch (error) {
            console.error('Error checking user:', error);
        }
    };

    const todayteamwithd = async () => {
        try {
            setpageloader(true)
            const response = await axios.post('https://tronapi.tronvid.site/todayteamwithd',
                { token: getWithExpiry("myuser") },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (data.status === "success") {
                var level1 = parseInt(data.level1[0].totalrecharge, 10) || 0;
                var level2 = parseInt(data.level2[0].totalrecharge, 10) || 0;
                var level3 = parseInt(data.level3[0].totalrecharge, 10) || 0;
                var level4 = parseInt(data.level4[0].totalrecharge, 10) || 0;

                var deposit = level1 + level2 + level3 + level4;
                setwithd(deposit);

                var level1u = parseInt(data.level1u[0].totalrecharge, 10) || 0;
                var level2u = parseInt(data.level2u[0].totalrecharge, 10) || 0;
                var level3u = parseInt(data.level3u[0].totalrecharge, 10) || 0;
                var level4u = parseInt(data.level4u[0].totalrecharge, 10) || 0;

                var depositu = level1u + level2u + level3u + level4u;

                setwithdu(depositu);

                setpageloader(false)
            }
        } catch (error) {
            console.error('Error checking user:', error);
        }
    };

    const todayteambet = async () => {
        try {
            setpageloader(true)
            const response = await axios.post('https://tronapi.tronvid.site/todayteambet',
                { token: getWithExpiry("myuser") },
                { headers: { 'Content-Type': 'application/json' } }
            );

            const data = response.data;

            if (data.status === "success") {
                let totallv1usdt = data.level1.reduce((acc, item) => acc + parseFloat(item.moneyusdt), 0);
                let totallv1trx = data.level1.reduce((acc, item) => acc + parseFloat(item.moneytrx), 0);

                let totallv2usdt = data.level2.reduce((acc, item) => acc + parseFloat(item.moneyusdt), 0);
                let totallv2trx = data.level2.reduce((acc, item) => acc + parseFloat(item.moneytrx), 0);

                let totallv3usdt = data.level3.reduce((acc, item) => acc + parseFloat(item.moneyusdt), 0);
                let totallv3trx = data.level3.reduce((acc, item) => acc + parseFloat(item.moneytrx), 0);

                let totallv4usdt = data.level4.reduce((acc, item) => acc + parseFloat(item.moneyusdt), 0);
                let totallv4trx = data.level4.reduce((acc, item) => acc + parseFloat(item.moneytrx), 0);

                var totalbettrx = totallv1trx + totallv2trx + totallv3trx + totallv4trx;
                var totalbetusdt = totallv1usdt + totallv2usdt + totallv3usdt + totallv4usdt;

                settotalbetusdt(totalbetusdt)
                settotalbettrx(totalbettrx)

            }
            setpageloader(false)
        } catch (error) {
            console.error('Error checking user:', error);
        }
    };



    useEffect(() => {

        todayfirstdeposit();
        todaydeposit();
        todayteamwithd();
        todayteambet();
    }, [])

    return (
        <div style={{background:"#f0ffff",}}>
            {
                pageloader && <LoadingSpinner />
            }
            {(teamnav !== "records") && <div className="d-flex justify-content-around align-items-center py-2 teamnav">
                <p className={`mb-0 ${(location.pathname === "/team") && ("active")}`} onClick={() => navigate("/team")}>Team</p>
                <p className={`mb-0 ${(location.pathname === "/teamtoday") && ("active")}`} onClick={() => navigate("/teamtoday")}>Today</p>
                <p className={`mb-0 ${(location.pathname === "/teamhistory") && ("active")}`} onClick={() => navigate("/teamhistory")}>History</p>
            </div>}

            {<div className="today  py-3">

                <div className='mx-auto my-2 cards' >
                    <p className='mb-0'><span>Today's team recharge</span></p>
                    <p className='mb-0'><strong>{deposit} TRX/{depositu} USDT</strong></p>
                </div>
                <div className='mx-auto my-2 cards' >
                    <p className='mb-0'><span>Today's team withdrawal</span></p>
                    <p className='mb-0'><strong>{withd} TRX/{withd} USDT</strong></p>
                </div>
                <div className='mx-auto my-2 cards' >
                    <p className='mb-0'><span>Today's team first charge</span></p>
                    <p className='mb-0'><strong>{firstdeposit} TRX/{firstdepositu} USDT</strong></p>
                </div>
                <div className='mx-auto my-2 cards' >
                    <p className='mb-0'><span>Today's team transation</span></p>
                    <p className='mb-0'><strong>{totalbettrx} TRX/{totalbetusdt} USDT</strong></p>
                </div>

            {/* navigate("/withdrawalrecorddetail", { state: data }) */}

                <p className='btnss mx-auto my-3' onClick={() => navigate("/teamtodaycommission",{ state:{ lvl:0}})}>View today's team commission</p>
                <p className='btnss mx-auto my-3' onClick={() => navigate("/teamtodaycommission",{ state:{lvl: 1} })}>LV1 Trading Statistics</p>
                <p className='btnss mx-auto my-3' onClick={() => navigate("/teamtodaycommission",{ state: {lvl:2 }})}>LV2 Trading Statistics</p>
                <p className='btnss mx-auto my-3' onClick={() => navigate("/teamtodaycommission",{ state: {lvl:3} })}>LV3 Trading Statistics</p>
                <p className='btnss mx-auto my-3' onClick={() => navigate("/teamtodaycommission",{ state: {lvl:4} })}>LV4 Trading Statistics</p>
            </div>}



        </div>
    )
}

export default Teamtoday