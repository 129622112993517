import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

const Bottomnav = () => {
    const navigate = useNavigate();
    const location = useLocation()

    const [active, setactive] = useState(0)

    const handleclick = (value, navvalue) => {
        setactive(value)
        navigate(navvalue)
    }

    return (
        <div id='myDIV' className='d-flex justify-content-around text-center border-top' style={{ backgroundColor: "rgb(27, 67, 76)", position: "fixed",color:"white", height: "60px", bottom: "0", width: "100%",zIndex:"1" }}>
            <div className={`navbox ${((location.pathname === "/index"||location.pathname === "/") ? "active" : "")}`} onClick={() => handleclick(0, "/index")} >
                {/* <House /> */}
                <img style={{ height: "25px", width: "25px", marginBottom:"-8px"}} src='https://img.icons8.com/keek/100/home.png'/>

                <p>Home</p>
            </div>
            <div className={`navbox  ${(location.pathname === "/gamesection" ? "active" : "")}`} onClick={() => handleclick(1, "/gamesection")}>
                <img style={{ height: "25px", width: "25px", marginBottom:"-8px"}} src="/logo/3.png" alt="" />
                <p>Trade</p>
            </div>
            <div className={`navboxpromotion  ${(location.pathname === "/promotion" ? "active" : "")}`} onClick={() => handleclick(1, "/promotion")} >
                <img src='https://img.icons8.com/external-goofy-color-kerismaker/96/external-Campaign-marketing-goofy-color-kerismaker.png' alt="" />

                <p>Promotion</p>
            </div>
            {/* <div className={`navbox ${(active === 1 ? "active" : "")}`} onClick={() => handleclick(1, "/eth1")}>
                <img style={{ height: "25px", width: "25px" }} src="https://genusdt.net/static/img/logo.png" alt="" />
                <p>Trade2</p>
            </div> */}
            <div className={`navbox  ${(location.pathname === "/activity" ? "active" : "")}`} onClick={() => handleclick(2, "/activity")}>
            <img style={{ height: "25px", width: "25px", marginBottom:"-8px"}} src='https://img.icons8.com/emoji/48/money-with-wings-emoji.png' alt="" />

                <p>Activity</p>
            </div>
            <div className={`navbox  ${((location.pathname !== "/index" && location.pathname !== "/activity" && location.pathname !== "/gamesection"&&location.pathname!=="/promotion") ? "active" : "")}`} onClick={() => handleclick(4, "/user")}>
            <img style={{ height: "25px", width: "25px", marginBottom:"-8px"}} src='https://img.icons8.com/stickers/50/administrator-male.png' alt="" />

                <p>  Profile</p>
            </div>

        </div>
    )
}

export default Bottomnav;