import React from 'react'
import "./apiloader.css"

const Apiloader = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ position:"absolute", height: "100%", width: "100%", zIndex: "5" }}>
            <div className="loader"></div>
        </div>
    )
}

export default Apiloader