import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Dot } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Withdrawrecord = () => {
  const navigate = useNavigate();
  const token = getWithExpiry("myuser");
  const [withdrawalrecord, setwithdrawalrecord] = useState([]);
  const [pageloader, setpageloader] = useState(false);

  const withdrawrecord = async () => {
    setpageloader(true);
    try {
      let data = await axios.post('https://tronapi.tronvid.site/withdrawalhistory', { token }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setwithdrawalrecord(data.data.userdata || []);
    } catch (error) {
      console.error('Error fetching withdrawal history:', error);
      setwithdrawalrecord([]);
    } finally {
      setpageloader(false);
    }
  };

  const handlenavigate = (id) => {
    let data = withdrawalrecord?.filter((item) => item.id === id);
    navigate("/withdrawalrecorddetail", { state: data });
  };

  useEffect(() => {
    withdrawrecord();
  }, []);

  return (
    <div>
      {pageloader && <LoadingSpinner />}
      {withdrawalrecord.length === 0 ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <img src="/logo/nodata_11zon.png" alt="No data available" style={{ width: "50%", marginTop: "50px" }} />
        </div>
      ) : (
        withdrawalrecord.map((item) => (
          <div className='rechargecards' onClick={() => handlenavigate(item.id)} key={item.id}>
            <div className="">
              <p>Extract</p>
              <strong>{item.amount}({item.curr} )</strong>
              <p className='rechargecardtimer'>{item.createdate}</p>
            </div>
            <div className='d-flex align-items-center'>
              {item.status === 0 && (
                <p style={{ fontSize: "15px", color: "#32b277" }}>
                  <Dot />Successful
                </p>
              )}
              {item.status === 1 && (
                <p className='' style={{ fontSize: "15px", color: " #32b277" }}>
                  <Dot />Pending
                </p>
              )}
              {item.status === 2 && (
                <p style={{ fontSize: "15px", color: "red" }}>
                  <Dot />Failed
                </p>
              )}
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default Withdrawrecord;
