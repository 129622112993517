// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HTML: <div class="loader"></div> */
.pageloader {
    width: 35px !important;
    --b: 2px;
    aspect-ratio: 1;
    border-radius: 50%;
    padding: 1px;
    background: conic-gradient(#1b434c, #f7f7f7 58%, #668c95);
    -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 350deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));
    mask-composite: intersect;
    animation: l4 1s infinite steps(10);
}

/* .pageloadercontainer {
  position: relative;
  width: 100px !important;
  animation: zoom-in-zoom-out 3s ease infinite;
} */

@keyframes l4 {
  to {
    transform: rotate(1turn)
  }
}



.zoom-in-out-box {
  margin: 24px;
  width: 110px;
  border-radius: 50%;
  position: absolute;
  animation: zoom-in-zoom-out 3s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.5, 1.5);
  }

  100% {
    transform: scale(1, 1);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/pageloader.css"],"names":[],"mappings":"AAAA,qCAAqC;AACrC;IACI,sBAAsB;IACtB,QAAQ;IACR,eAAe;IACf,kBAAkB;IAClB,YAAY;IACZ,yDAAyD;IACzD,uLAAuL;IACvL,yBAAyB;IACzB,mCAAmC;AACvC;;AAEA;;;;GAIG;;AAEH;EACE;IACE;EACF;AACF;;;;AAIA;EACE,YAAY;EACZ,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,4CAA4C;AAC9C;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["/* HTML: <div class=\"loader\"></div> */\n.pageloader {\n    width: 35px !important;\n    --b: 2px;\n    aspect-ratio: 1;\n    border-radius: 50%;\n    padding: 1px;\n    background: conic-gradient(#1b434c, #f7f7f7 58%, #668c95);\n    -webkit-mask: repeating-conic-gradient(#0000 0deg, #000 350deg 20deg, #0000 21deg 36deg), radial-gradient(farthest-side, #0000 calc(100% - var(--b) - 1px), #000 calc(100% - var(--b)));\n    mask-composite: intersect;\n    animation: l4 1s infinite steps(10);\n}\n\n/* .pageloadercontainer {\n  position: relative;\n  width: 100px !important;\n  animation: zoom-in-zoom-out 3s ease infinite;\n} */\n\n@keyframes l4 {\n  to {\n    transform: rotate(1turn)\n  }\n}\n\n\n\n.zoom-in-out-box {\n  margin: 24px;\n  width: 110px;\n  border-radius: 50%;\n  position: absolute;\n  animation: zoom-in-zoom-out 3s ease infinite;\n}\n\n@keyframes zoom-in-zoom-out {\n  0% {\n    transform: scale(1, 1);\n  }\n\n  50% {\n    transform: scale(1.5, 1.5);\n  }\n\n  100% {\n    transform: scale(1, 1);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
