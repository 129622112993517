import React, { useState } from 'react'
import { Coin, GiftFill } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Loader/LoadingSpinner';
import LazyLoad from 'react-lazyload';

const Activity = () => {
    const navigate = useNavigate();

    const [pageloader, setpageloader] = useState(true)

    setTimeout(() => {
        setpageloader(false)
    }, 500);

    return (
        <LazyLoad>
            <div className=' py-2' style={{ minHeight: "600px",background:"#f0ffff" }}>
                {pageloader && <LoadingSpinner />}

                <div className="activity_card m-3" >
                    <div className="activitysection_cardhead ">
                        <p className="ps-2 mb-0" style={{ fontSize: "15px", color: "#1b434c", fontWeight: '800' }}>Winning Streak</p>
                    </div>
                    <div
                        className="d-flex justify-content-around"
                        onClick={() => navigate("/activitydetail", { state: { imgurl: "/posters/1.png" } })}
                        style={{
                            background: "url(/posters/1.png)",
                            backgroundSize: "100% 100%",
                            height: "135px"
                        }}
                    >
                    </div>
                </div>

                <div className="activity_card m-3" >
                    <div className="activitysection_cardhead ">
                        <p className="ps-2 mb-0" style={{ fontSize: "15px", color: "#1b434c", fontWeight: '800' }}>First Deposit Bonus</p>
                    </div>
                    <div
                        className="d-flex justify-content-around"
                        onClick={() => navigate("/activitydetail", { state: { imgurl: "/posters/2.png" } })}
                        style={{
                            background: "url(/posters/2.png)",
                            backgroundSize: "100% 100%",
                            height: "135px"
                        }}
                    >
                    </div>
                </div>

                <div className="activity_card m-3" >
                    <div className="activitysection_cardhead ">
                        <p className="ps-2 mb-0" style={{ fontSize: "15px", color: "#1b434c", fontWeight: '800' }}>Creative Video Reward</p>
                    </div>
                    <div
                        className="d-flex justify-content-around"
                        onClick={() => navigate("/activitydetail", { state: { imgurl: "/posters/3.png" } })}
                        style={{
                            background: "url(/posters/3.png)",
                            backgroundSize: "100% 100%",
                            height: "135px"
                        }}
                    >
                    </div>
                </div>


                <div className="activity_card m-3" >
                    <div className="activitysection_cardhead ">
                        <p className="ps-2 mb-0" style={{ fontSize: "15px", color: "#1b434c", fontWeight: '800' }}>Daily Mining Reward</p>
                    </div>
                    <div
                        className="d-flex justify-content-around"
                        onClick={() => navigate("/activitydetail", { state: { imgurl: "/posters/4.png" } })}
                        style={{
                            background: "url(/posters/4.png)",
                            backgroundSize: "100% 100%",
                            height: "135px"
                        }}
                    >
                    </div>
                </div>


                <div className="activity_card m-3" >
                    <div className="activitysection_cardhead ">
                        <p className="ps-2 mb-0" style={{ fontSize: "15px", color: "#1b434c", fontWeight: '800' }}>Invite And Earn</p>
                    </div>
                    <div
                        className="d-flex justify-content-around"
                        onClick={() => navigate("/activitydetail", { state: { imgurl: "/posters/5.png" } })}
                        style={{
                            background: "url(/posters/5.png)",
                            backgroundSize: "100% 100%",
                            height: "135px"
                        }}
                    >
                    </div>
                </div>


            </div>
        </LazyLoad>
    )
}

export default Activity


