import React from 'react'
import { ArrowRight, Wallet2 } from 'react-bootstrap-icons'
import "./activityrebate.css"
const Activityrebate = () => {
    return (
        <div className='bg-dark text-white p-3'>
            <div className='p-2 activity_rebate_cards'>
                <p style={{ fontSize: "12px" }}>All Total Betting Rebate</p>
                <p className='realtimecount p-1'>Real-time count</p>
                <p style={{ fontSize: "15px" }}><Wallet2 className='mx-2' style={{ color: "#f7bf4f" }} />24.00</p>
                <p className='upgradebox mb-2'>Upgrade VIP level to increase rebate rate</p>
                <div className="row">
                    <div className='col-6'>
                        <p className='mb-2' style={{ fontSize: "8px", color: "#666666" }}>Today Rebate</p>
                        <p className='mb-2' style={{ fontSize: "15px", color: "#ffffff" }}>0</p>
                    </div>
                    <div className='col-6'>
                        <p className='mb-2' style={{ fontSize: "8px", color: "#666666" }}>Total Rebate</p>
                        <p className='mb-2' style={{ fontSize: "15px", color: "#ffffff" }}>14.69</p>
                    </div>
                </div>
                <p style={{ fontSize: "12px", color: "#888888" }}>Automatic code washing at 01:00:00 every morning</p>
                <button className='btnss'>One-click Rebate</button>
            </div>
            <p className='my-2 d-flex justify-content-center align-items-center' style={{ fontSize: "10px", color: "#fa2367" }}> Learn the rules <ArrowRight /></p>

            <hr className='mt-0' />

            <h6 style={{ fontSize: "13px", color: "white" }}>Rebate History</h6>

            <div className='activity_rebate_cards my-2 p-2'>
                <div className="d-flex justify-content-between">
                    <div>
                        <h6 className='mb-1' style={{ fontSize: "12px" }}>Lottery</h6>
                        <p className='mb-0' style={{ fontSize: "9px", color: "#888888" }}>2024-06-02 01:00:01</p>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p className='mb-0' style={{ fontSize: "11px", color: "#40c592" }}>Completed</p>
                    </div>
                </div>
                <hr className='my-2' />
                <div style={{ fontSize: "10px" }}>

                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Betting Rebate</p>
                        <p className='mb-2'>316</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Rebate Rate</p>
                        <p className='mb-2' style={{ color: "#fa2367" }}>0.3%</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Rebate amount</p>
                        <p className='mb-2'>0.95</p >
                    </div>
                </div>
            </div >
            <div className='activity_rebate_cards my-2 p-2'>
                <div className="d-flex justify-content-between">
                    <div>
                        <h6 className='mb-1' style={{ fontSize: "12px" }}>Slots</h6>
                        <p className='mb-0' style={{ fontSize: "9px", color: "#888888" }}>2024-06-02 01:00:01</p>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p className='mb-0' style={{ fontSize: "11px", color: "#40c592" }}>Completed</p>
                    </div>
                </div>
                <hr className='my-2' />
                <div style={{ fontSize: "10px" }}>

                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Betting Rebate</p>
                        <p className='mb-2'>316</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Rebate Rate</p>
                        <p className='mb-2' style={{ color: "#fa2367" }}>0.3%</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Rebate amount</p>
                        <p className='mb-2'>0.95</p >
                    </div>
                </div>
            </div >
            <div className='activity_rebate_cards my-2 p-2'>
                <div className="d-flex justify-content-between">
                    <div>
                        <h6 className='mb-1' style={{ fontSize: "12px" }}>Lottery</h6>
                        <p className='mb-0' style={{ fontSize: "9px", color: "#888888" }}>2024-06-02 01:00:01</p>
                    </div>
                    <div className='d-flex justify-content-center align-items-center'>
                        <p className='mb-0' style={{ fontSize: "11px", color: "#40c592" }}>Completed</p>
                    </div>
                </div>
                <hr className='my-2' />
                <div style={{ fontSize: "10px" }}>

                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Betting Rebate</p>
                        <p className='mb-2'>316</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Rebate Rate</p>
                        <p className='mb-2' style={{ color: "#fa2367" }}>0.3%</p>
                    </div>
                    <div className="d-flex justify-content-between">
                        <p className='mb-2'>Rebate amount</p>
                        <p className='mb-2'>0.95</p >
                    </div>
                </div>
            </div >
        </div >
    )
}

export default Activityrebate