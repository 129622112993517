import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Apiloader from '../Loader/Apiloader';
import { getWithExpiry } from '../JS/JS';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Gamesection = () => {
    const navigate = useNavigate();

    const [apiloader, setapiloader] = useState(false)

    const [usdt, setusdt] = useState()
    const [trx, settrx] = useState()
    const checkuser = async () => {
        setapiloader(true)
        let data = await axios.post('https://tronapi.tronvid.site/checkuser', { token: getWithExpiry("myuser") }, {
            headers: {
                'Content-Type': 'application/json',
            },
        });
        if (data.data.status === "error") {

            navigate("/login")
        }
        else {
            setusdt(Number(data.data.userdata.usdt))
            settrx(Number(data.data.userdata.trx))
            // setvisible(true)

        }

        setapiloader(false)
    }

    useEffect(() => {
        checkuser();
    }, [])





    const [cryptoData, setCryptoData] = useState([]);

    const [pageloader, setpageloader] = useState(false);


    useEffect(() => {
const fetchCryptoData = async () => {
    setpageloader(true); // Show loader while data is being fetched
    const symbols = ["ATOMUSDT", "TONUSDT", "ADAUSDT", "AVAXUSDT", "UNIUSDT", "DAIUSDT", "PEPEUSDT"];

    try {
        // Create an array of promises for all symbols
        const promises = symbols.map(async (symbol) => {
            try {
                // Make a POST request for each symbol
                const response = await axios.post(
                    'https://tronapi.tronvid.site/chart/cryptoprice.php',
                    { symbol }, // Send symbol as the request payload
                    { headers: { 'Content-Type': 'application/json' } }
                );

                // Extract price from response and return formatted data
                if (response.data && response.data.status === 'success') {
                    return {
                        symbol: symbol.replace("USDT", ""), // Remove "USDT" from symbol
                        price: parseFloat(response.data.price).toFixed(2), // Format price to 2 decimal places
                    };
                } else {
                    throw new Error(`No price data found for ${symbol}`);
                }
            } catch (err) {
                console.error(`Error fetching data for ${symbol}:`, err.message);
                return { symbol: symbol.replace("USDT", ""), price: "N/A" }; // Return fallback data on error
            }
        });

        // Wait for all promises to resolve
        const data = await Promise.all(promises);

        // Update state with fetched data
        setCryptoData(data);
    } catch (error) {
        console.error('Error fetching cryptocurrency data:', error.message);
    } finally {
        setpageloader(false); // Hide loader after processing
    }
};


        fetchCryptoData();
    }, []);

    
  const img_circle = { height: "50px", width: "50px", borderRadius: "50%", background: "#d6cccc4a" }
  const img_circle_img = { height: "30px", width: "30px" }
  const team_card = { borderRadius: "10px", background: "#d6cccc4a", width: "45%", padding: "10px" }
  const team_card1 = {  width: "50%" }
  const team_card3 = { borderRadius: "10px", background: "#d6cccc4a", width: "100%", padding: "10px" }
  const team_card2 = { borderRadius: "10px", width: "40%", height: "150px" }

  const team_card_img = { height: "50px", width: "50px" }



    return (
        <div className='bg-light' style={{ minHeight: "600px",background:"#f0ffff" }}>
            {apiloader && <LoadingSpinner />}


            <div className='d-flex p-3 justify-content-between'>
                <div className='bg-white d-flex align-items-center' style={{ width: "47%", height: "70px", borderRadius: "10px" }}>
                    <img className='m-3' style={{ height: "35px", width: "35px" }} src="/icon_mine_TRX.png" alt="" />
                    <div>
                        <p style={{ fontSize: "15px", fontWeight: "600" }} className='mb-0'>{trx?.toFixed(4)}</p>
                        <p style={{ fontSize: "11px" }} className='mb-0'>TRX Balance</p>
                    </div>
                </div>
                <div className='bg-white d-flex align-items-center' style={{ width: "47%", height: "70px", borderRadius: "10px" }}>
                    <img className='m-3' style={{ height: "35px", width: "35px" }} src="/usdt-BK5BR48P.png" alt="" />
                    <div>
                        <p style={{ fontSize: "15px", fontWeight: "600" }} className='mb-0'>{usdt?.toFixed(4)}</p>
                        <p style={{ fontSize: "11px" }} className='mb-0'>USDT Balance</p>
                    </div>
                </div>
            </div>
            <div className="px-3">

                {/* <h6><span><img src="/Bitcoin-Logo.png" alt="" style={{ height: "20px", width: "30px" }} /> BTC</span></h6>
                <div className="d-flex justify-content-around py-1 ">

                    <div className='btcimglg1 my-2' onClick={() => navigate("/btc1")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                        <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/btc1.png" alt="" />
                    </div>
                    <div className='btcimglg5 my-2' onClick={() => navigate("/btc5")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                        <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/btc5.png" alt="" />
                    </div>
                    <div className='btcimglg15 my-2' onClick={() => navigate("/btc15")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                        <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/btc15.png" alt="" />
                    </div>
                </div>
                <h6 className='mt-2'><img src="/Bitcoin-Logo.png" alt="" style={{ height: "20px", width: "30px" }} />ETH</h6>
                <div className="d-flex justify-content-around py-1 ">


                    <div className='ethimglg1 my-2' onClick={() => navigate("/eth1")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                        <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/eth1.png" alt="" />
                    </div>
                    <div className='ethimglg5 my-2' onClick={() => navigate("/eth5")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                        <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/eth5.png" alt="" />
                    </div>
                    <div className='ethimglg15 my-2' onClick={() => navigate("/eth15")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                        <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/eth15.png" alt="" />
                    </div>
                </div> */}

                {/* <div className="gamesec_card mb-3" >
                    <div className="gamesection_cardhead p-1">
                        <h6>
                            <span style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center'
                            }}>
                                <img src="/Bitcoin-Logo.png" alt="" style={{ width: '40px' }} /> BTC
                            </span>

                        </h6>
                    </div>
                    <div className="d-flex justify-content-around py-1 ">

                        <div className='btcimglg1 my-2' onClick={() => navigate("/btc1")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                            <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/btc1.png" alt="" />
                        </div>
                        <div className='btcimglg5 my-2' onClick={() => navigate("/btc5")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                            <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/btc5.png" alt="" />
                        </div>
                        <div className='btcimglg15 my-2' onClick={() => navigate("/btc15")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                            <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/btc15.png" alt="" />
                        </div>
                    </div>
                </div>
                <div className="gamesec_card mb-3">
                    <div className="gamesection_cardhead p-1">
                        <h6><span style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center'
                        }}><img src="/ethlogo.png" alt="" style={{ width: "22px", marginRight: "10px" }} /> ETH</span></h6>
                    </div>
                    <div className="d-flex justify-content-around py-1 ">


                        <div className='ethimglg1 my-2' onClick={() => navigate("/eth1")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                            <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/eth1.png" alt="" />
                        </div>
                        <div className='ethimglg5 my-2' onClick={() => navigate("/eth5")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                            <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/eth5.png" alt="" />
                        </div>
                        <div className='ethimglg15 my-2' onClick={() => navigate("/eth15")} style={{ height: "130px", width: "32%", borderRadius: "10px" }}>
                            <img style={{ height: "100%", width: "100%", borderRadius: "10px" }} src="/eth15.png" alt="" />
                        </div>
                    </div>
                </div> */}


                <h2 className='ms-3' style={{ fontSize: "20px", fontWeight: "700", marginBottom: "10px" }}>Top Trade data</h2>
                <div className='d-flex mb-3 justify-content-around'>

                    <div style={team_card2} className='img1234 d-flex flex-column justify-content-end ' onClick={() => navigate("/btc1")}>
                        <p className='mb-0 py-1 text-center' style={{
                            fontSize: "15px", fontWeight: "700 !important", width: "100%",
                            background: "rgb(0 0 0 / 80%)", color: "white"
                        }}>BTC Game</p>
                    </div>
                    <div style={team_card2} className='img12345 d-flex flex-column justify-content-end' onClick={() => navigate("/eth1")}>
                        <p className='mb-0 py-1 text-center' style={{
                            fontSize: "15px", fontWeight: "700 !important", width: "100%",
                            background: "rgb(0 0 0 / 80%)", color: "white"
                        }}>ETH Game</p>
                    </div>

                </div>


                <div className='hometableicons container-fluid my-3'>
                    <div className="row">
                        <div className="col-6 header">Name</div>
                        <div className="col-6 text-end header">Latest price (USDT)</div>
                        <hr className='mt-2 mb-3' />
                    </div>
                    {cryptoData.map((crypto, index) => (
                        <div className="row" key={index}>
                            <div className="col-6 currencyname">
                                <div style={styles.currencyItem}>
                                    <img style={styles.img} src={`https://lcw.nyc3.cdn.digitaloceanspaces.com/production/currencies/32/${crypto.symbol.toLowerCase()}.png`} alt={crypto.symbol} />
                                    {crypto.symbol}
                                    {crypto.percentage}
                                </div>
                            </div>
                            <div className="col-6 text-end currencyvalue">{crypto.price || 'Loading...'} <span style={styles.table_value_percent}>+10.5%</span></div>

                            <hr className='my-3' />
                        </div>
                    ))}
                </div>


            </div>
        </div>
    )
}

const styles = {
    currencyItem: {
        display: 'flex',
        flexDirection: 'row',
        width: '50px',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    img: {
        marginRight: '10px',  // Changed to camelCase and added quotes around value
    },
    table_value_percent:
    {
        background: " #68e384",
        padding: "5px 10px",
        borderRadius: "3px",
        fontSize: "12px",
        color: "white"
    }
};

export default Gamesection